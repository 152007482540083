import { useState } from 'react';
import Icon from '../../components/body/icon';
import SelectReact from '../../components/body/select';
import { Routes, Route, useParams, Navigate } from 'react-router-dom';
import Navbar from '../../components/header/navbar';
import NavbarLink from '../../components/header/navbar/navbarLink';
import Container from '../../components/body/container';
import { useContext } from 'react';
import { GlobalContext } from '../../context/Global';
import { decrypt } from '../../_assets/js/global';

// PÁGINAS
import Lista from './Lista';
import Input from '../../components/body/form/input';

export default function FechamentoCaixa() {
  const params = useParams();
  const paramsUrl = params['*'].substring(params['*'].indexOf('/') + 1);
  const paramsUrlCount = (paramsUrl.match(/\//g) ? paramsUrl.match(/\//g).length : 0);
  var tokenUrl, tokenUrlAux;

  if (paramsUrlCount > 0) {
    tokenUrlAux = params['*'].substring(params['*'].indexOf('/') + 1, params['*'].lastIndexOf('/'));
    tokenUrl = tokenUrlAux.substring(0, tokenUrlAux.indexOf('/'));
  } else {
    tokenUrl = params['*'].substring(params['*'].indexOf('/') + 1);
  }

  var decryptedReturn = JSON.parse(decrypt(tokenUrl));

  window.token = tokenUrl;
  window.rs_id_emp = decryptedReturn.id_emp;
  window.rs_id_usr = decryptedReturn.id_usr;
  window.rs_id_lja = decryptedReturn.id_lja;
  window.rs_name_usr = decryptedReturn.name_usr;
  window.rs_id_apl = decryptedReturn.id_apl;
  window.rs_sistema_id = decryptedReturn.sistema_id;
  window.rs_permission_apl = decryptedReturn.pus;
  window.rs_id_module = decryptedReturn.id_module;

  // GLOBAL CONTEXT
   const { filters } = useContext(GlobalContext);

  const [loja, setLoja] = useState(2);
  const [conta, setConta] = useState(1);
  const [periodo, setPeriodo] = useState(1);
  const [hide, setHide] = useState(true);

  // ESTADOS (ÍCONES E FILTROS)
  const [icons, setIcons] = useState(null);
  //const [filters, setFilters] = useState(null);

  //
  const [filterMonth, setFilterMonth] = useState('');
  const [periodStart, setPeriodStart] = useState('');
  const [periodEnd, setPeriodEnd] = useState('');

  // DEFINE ÍCONES NAVBAR
  const handleSetIcons = (e) => {
    setIcons(e);
  }

  // DEFINE FILTROS NAVBAR
  // const handleSetFilters = (e) => {
  //   setFilters(e);
  // }

  //FILTRO MÊS
  const handleMonth = (e) => {
    setFilterMonth(e)
    setPeriodEnd('');
    setPeriodStart('');
  }


  return (
    <>
      <Navbar
        // filters={filters}
        filters={
          <>
            {/* <SelectReact
              value={loja}
              onChange={(e) => setLoja(e.value)}
              options={[
                { value: 1, label: "Geral" }, { value: 2, label: "Loja" }
              ]}
            />
            <SelectReact
              value={conta}
              onChange={(e) => setConta(e.value)}
              options={[
                { value: 1, label: "Conta" }, { value: 2, label: "Nenhuma opção" }
              ]}
            /> */}
            <SelectReact
              value={periodo}
              onChange={(e) => setPeriodo(e.value)}
              options={[
                { value: 1, label: "Mês" }, { value: 2, label: "Período" }
              ]}
            />
            {(
              periodo == 1 ?
                <Input
                  type="date"
                  format="mm/yyyy"
                  name="filter_date"
                  required={false}
                  value={filterMonth}
                  onChange={(e) => handleMonth(e)}
                />
                :
                <Input
                  type="period"
                  name="filter_period"
                  required={false}
                  valueStart={periodStart}
                  valueEnd={periodEnd}
                  onChangeStart={(e) => (setPeriodStart(e), setFilterMonth(''))}
                  onChangeEnd={(e) => setPeriodEnd(e)}
                />
            )}
          </>
        }
        icons={<>
          {/* <Icon type="excel" /> */}
          <Icon type="print" />
          {/* <Icon type="plus" />*/}
          <Icon type="expand" expanded={!hide} onClick={() => setHide(!hide)} />
        </>}
      >
        <NavbarLink link={'lista/' + window.token} name="Lista" icon="analytics" active={true} />

        {((window.rs_permission_apl === 'master') && (!window.rs_id_lja || window.rs_id_lja == 0) ?
          <NavbarLink link={'gerenciador/' + window.token} name="Gerenciador" icon="cog" />
          : '')}
      </Navbar>

      <Container>
        <Routes>
          <Route path="/:token" index element={<Navigate to={'lista/' + window.token} replace />} />
          <Route path="lista/:token" element={<Lista hide={hide} filterMonth={filterMonth} periodStart={periodStart} periodEnd={periodEnd} />} />
        </Routes>
      </Container>
    </>
  );
}
