import { useEffect, useState } from "react";
import axios from "axios";
import Gerenciador from "../../../../components/body/gerenciador";
import Icon from "../../../../components/body/icon";
import Table from "../../../../components/body/table";
import Tbody from "../../../../components/body/table/tbody";
import Td from "../../../../components/body/table/tbody/td";
import Tr from "../../../../components/body/table/tr";
import Input from "../../../../components/body/form/input";
import Switch from "../../../../components/body/switch";
import Cadastro from "./cadastro";


export default function Categorias({ id_loja }) {

    //ESTADOS
    const [categoria, setCategoria] = useState([]);
    const [search, setSearch] = useState('');
    const [inativos, setInativos] = useState('');
    const [editarCategoria, setEditarCategoria] = useState('');
    const [selected, setSelected] = useState('');

    //EDITAR CATEGORIA
    const [tipo, setTipo] = useState('');
    const [ambiente, setAmbiente] = useState('');
    const [minima, setMinima] = useState('');
    const [maxima, setMaxima] = useState('');
    const [momento, setMomento] = useState('');
    const [umidade, setUmidade] = useState('');
    const [umidadeMin, setUmidadeMin] = useState('');
    const [umidadeMax, setUmidadeMax] = useState('');
    const [temperaturaMin, setTemperaturaMin] = useState('');
    const [temperaturaMax, setTemperaturaMax] = useState('');
    const [foto, setFoto] = useState(1);
    const [observacao, setObservacao] = useState(2);
    const [show, setShow] = useState(false)

    //ABRIR CADASTRO/EDITOR
    const handleSelected = (id, tipo, ambiente, minima, maxima, umidade, umidadeMin,
        umidadeMax, temperaturaMin, temperaturaMax, foto, observacao) => (
        setShow(true),
        setSelected(id),
        setTipo(tipo),
        setAmbiente(ambiente),
        setMinima(1),
        setMaxima(1),
        setMomento(1),
        setTemperaturaMin("20°C"),
        setTemperaturaMax("30°C"),
        setMinima(minima),
        setMaxima(maxima),
        setUmidade(umidade),
        setUmidadeMin(umidadeMin),
        setUmidadeMax(umidadeMax),
        setTemperaturaMin(temperaturaMin),
        setTemperaturaMax(temperaturaMax),
        setFoto(foto),
        setObservacao(observacao)
    );
    
    //GET INFO
    function getCategorias() {
        axios.get(window.host + '/systems/controle-temperatura/api/gerenciador.php?token=' + window.token, {
            params: {
                do: 'getControleTemperatura',
                controle_tipo_id: id_loja

            }
        }).then((response) => { setCategoria(response.data) })
    }

    useEffect(() => { getCategorias() }, []);

    const callback = (res) => (
        setShow(res),
        getCategorias());

    return (
        <>
            <Gerenciador
                id="categoria-melhorias"
                title="Categorias"
                autoScroll={true}
                icon={<Icon type="new" onClick={() => handleSelected()} />}
                search={<Input placeholder="Pesquisar" value={search} onChange={(e) => setSearch(e.target.value)} />}
                switch={
                    <Input
                        type="checkbox"
                        id="check_inativos"
                        label="Mostrar Inativos"
                        inverse={true}
                        onChange={(e) => (setInativos(e.target.checked))}
                    />
                }
            >
                <Table fullHeight={true} >
                    <Tbody>
                        {
                            categoria &&
                            categoria.map((item) => {
                                let aux
                                // if (item.subcategoria == 1) {
                                //     aux = "Ambiente"
                                // }
                                // if (item.subcategoria == 2) {
                                //     aux = "Termolábil"
                                // }
                                return (
                                    <Tr
                                        key={item.id}
                                    >
                                        <Td title={`${item.nome} (${aux})`}>{item.nome}
                                            {/* {<span style={{ color: "#ddd" }}>{` (${aux})`}</span>} */}
                                        </Td>
                                        <Td
                                            align="end"
                                        >
                                            <Icon
                                                type="edit"
                                                classname={selected == item.id ? "text-primary" : "text-secondary"}
                                                onClick={() => handleSelected(
                                                    item.id,
                                                    item.controle_tipo_id,
                                                    item.nome,
                                                    item.temperatura_minima,
                                                    item.temperatura_maxima,
                                                    item.umidade,
                                                    item.umidade_minima_valor,
                                                    item.umidade_maxima_valor,
                                                    item.temperatura_minima_valor,
                                                    item.temperatura_maxima_valor,
                                                    item.foto_termometro,
                                                    item.observacao
                                                )}
                                            />
                                            <Switch
                                                checked={(item.ativo == 1 ? true : false)}
                                            />
                                        </Td>
                                    </Tr>
                                )
                            })
                        }
                    </Tbody>
                </Table>
            </Gerenciador>
            {
                show &&
                <Cadastro
                    id={selected}
                    callback={callback}
                    tipo_aux={tipo}
                    ambiente_aux={ambiente}
                    minima_aux={minima}
                    maxima_aux={maxima}
                    umidade_aux={umidade}
                    umidadeMin_aux={umidadeMin}
                    umidadeMax_aux={umidadeMax}
                    temperaturaMin_aux={temperaturaMin}
                    temperaturaMax_aux={temperaturaMax}
                    foto_aux={foto}
                    observacao_aux={observacao}
                    id_loja={id_loja}
                />
            }

        </>
    )
}