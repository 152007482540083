import { useContext, useEffect, useState } from "react";
import { cd } from "../../../_assets/js/global";
import Container from "../../../components/body/container";
import Table from "../../../components/body/table";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import { GlobalContext } from "../../../context/Global";

export default function RelatorioJobs(props) {
    // GLOBAL CONTEXT
    const { handleSetFilter } = useContext(GlobalContext);

    //LISTA ITENS
    const [itens, setItens] = useState([]);
    // VALUE FILTROS
    const [colaborador, setColaborador] = useState([]);
    const [colaboradorSup, setColaboradorSup] = useState([]);
    const [regional, setRegional] = useState([]);
    const [macro, setMacro] = useState();
    const [rede, setRede] = useState();
    const [bandeira, setBandeira] = useState();
    const [pdv, setPdv] = useState();
    const [tipoPdv, setTipoPdv] = useState();
    const [codPdv, setCodPdv] = useState();
    const [perfilPdv, setPerfilPdv] = useState();
    const [canalPdv, setCanalPdv] = useState();
    const [endereço, setEndereco] = useState();
    const [centroComercial, setCentroComercial] = useState();
    const [estado, setEstado] = useState();
    const [cidade, setCidade] = useState();
    const [cnpj, setCnpj] = useState();
    const [dataVisitaInicio, setDataVisitaInicio] = useState();
    const [dataVisitaFim, setDataVisitaFim] = useState();
    const [periocidade, setPeriocidade] = useState();
    const [fora, setFora] = useState();
    const [razao, setRazao] = useState();
    const [status, setStatus] = useState();
    const [aprovouVia, setAprovouVia] = useState();
    const [quemAuditou, setQuemAuditou] = useState();
    const [jusAuditada, setJusAuditada] = useState();
    const [justificativa, setJustificativa] = useState();
    const [quemJustificou, setQuemJustificou] = useState();
    const [justificadoVia, setJustificadoVia] = useState();
    const [tipoCheckIn, setTipoCheckIn] = useState();
    const [primeiroCheckManual, setPrimeiroCheckManual] = useState();
    const [ultimoCheckManual, setUltimoCheckManual] = useState();
    const [checkInAuto, setCheckInAuto] = useState();
    const [checkOutAuto, setCheckOutAuto] = useState();
    const [entradaPRG, setEntradaPRG] = useState();
    const [saidaPRG, setSaidaPRG] = useState();
    const [check, setCheck] = useState();
    const [totalPendente, setTotalPendente] = useState();
    const [totalRespondida, setTotalRespondida] = useState();
    const [totalPrevista, setTotalPrevista] = useState();
    const [todasRepondidas, setTodasRespondidas] = useState();
    const [totalFotos, setTotalFotos] = useState();
    const [tempoExecutado, setTempoExecutado] = useState();
    const [tempoAutomatico, setTempoAutomatico] = useState();
    const [tempoPlanejado, setTempoPlanejado] = useState();
    const [saldo, setSaldo] = useState();
    const [atrasoManual, setAtrasoManual] = useState();
    const [atrasoAutmoatico, setAtrasoAutomatico] = useState();
    const [razaoTarefa, setRazaoTarefa] = useState();

    //LISTA ITENS
    const handleSetItems = (e) => {
        setItens(e)
    }

    //LISTA FILTRO GENÉRICA TARGET -- teste 
    const handleTarget = (setState) => (e) => {
        setState(e.target.value)
    }
    //LISTA FILTRO GENÉRICA VALUE -- teste 
    const handleValue = (setState) => (e) => {
        setState(e.value)
    }
    //LISTA FILTRO GENÉRICA EVENT -- teste 
    const handleEvent = (setState) => (e) => {
        setState(e)
    }

    // HABILITA O LOADING DOS CARDS PARA QUANDO VOLTAR PRA TELA DO CALENDÁRIO
    useEffect(() => {
        handleSetFilter(true);
    }, []);

    // MANDA OS FILTROS PRO COMPONENTE PAI
    useEffect(() => {
        if (props?.icons) {
            props.icons('');
        }

        if (props?.filters) {
            props.filters('');
        }
    }, []);

    return (
        <Table
            id="jobs-2"
            api={window.host + "/systems/integration-react/api/list.php?do=get_list&token=" + window.token}
            params={
                {
                    // filter_protocol: protocolo,
                    filter_protocol: 10,
                    // filter_status: status,
                    filter_type: '5',
                    filter_date_start: (dataVisitaInicio ? cd(dataVisitaInicio) : ''),
                    filter_date_end: (dataVisitaFim ? cd(dataVisitaFim) : ''),
                    // filter_date_start_execution: (finalizacaoInicio ? cd(finalizacaoInicio) : ''),
                    // filter_date_end_execution: (finalizacaoFim ? cd(finalizacaoFim) : ''),
                    // filter_system: sistemas,
                    // filter_frequency: frequencias,
                    // filter_category: categorias,
                    // filter_subcategory: subcategorias,
                    // filter_description: descricao,
                    // filter_title: titulo,
                    // filter_protocol_title_parent: grupo,
                    // filter_enterprise: empreendimento,
                    // filter_client: cliente,
                    filter_id_user: colaborador,
                    filter_id_store: pdv,
                    // filter_id_user_cad: usuario_cad,
                    // filter_id_store_cad: loja_cad,
                    // filter_days: aberto,
                    filter_hour_limit: ultimoCheckManual,
                    // filter_id_phase: fases,
                    // filter_avaliation: avaliacao,
                    // filter_id_module: modulo,
                    // filter_status_supervisor: statusSupervisor,
                    // filter_id_apl: integration,
                    type: 'report',
                    limit: 50
                }
            }
            onLoad={handleSetItems}
        >
            <Thead>
                <Tr>
                    <Th
                        id="usuario"
                        name="usuario"
                        api={{
                            url: (window.host + "/api/sql.php?do=select&component=usuario&token=" + window.token),
                            params: {
                                limit: 50
                            }
                        }}
                        onChange={handleEvent(setColaborador)}
                    >
                        Colaborador
                    </Th>
                    <Th
                        id="colaborador_superior"
                        name="colaborador_superior"
                        onChange={handleTarget(setColaboradorSup)}
                    >
                        Colaborador Superior
                    </Th>
                    <Th
                        id="regional"
                        name="regional"
                        onChange={handleTarget(setRegional)}
                    >
                        Regional
                    </Th>
                    <Th
                        id="macro"
                        name="macro"
                        onChange={handleTarget(setMacro)}
                    >
                        Macrorregional
                    </Th>
                    <Th
                        id="rede"
                        name="rede"
                        onChange={handleTarget(setRede)}
                    >
                        Rede
                    </Th>
                    <Th
                        id="bandeira"
                        name="bandeira"
                        onChange={handleTarget(setBandeira)}
                    >
                        Bandeira
                    </Th>
                    <Th
                        id="pvd"
                        name="pvd"
                        api={{
                            url: window.host + "/api/sql.php?do=select&component=loja&filial=true&token=" + window.token,
                            params: {
                                limit: 50
                            }
                        }}
                        onChange={handleEvent(setPdv)}
                    >
                        Ponto de Venda
                    </Th>
                    <Th
                        id="tipo_pdv"
                        name="tipo_pdv"
                        onChange={handleTarget(setTipoPdv)}
                    >
                        Tipo PDV
                    </Th>
                    <Th
                        id="cod_pdv"
                        name="cod_pdv"
                        onChange={handleTarget(setCodPdv)}
                    >
                        Código do PDV
                    </Th>
                    <Th
                        id="perfil_pdv"
                        name="perfil_pdv"
                        onChange={handleTarget(setPerfilPdv)}
                    >
                        Perfil do PDV
                    </Th>
                    <Th
                        id="canal_pdv"
                        name="canal_pdv"
                        onChange={handleTarget(setCanalPdv)}
                    >
                        Canal do PDV
                    </Th>
                    <Th
                        id="endereco"
                        name="endereco"
                        onChange={handleTarget(setEndereco)}
                    >
                        Endereço
                    </Th>
                    <Th
                        id="centro_comercial"
                        name="centro_comercial"
                        onChange={handleTarget(setCentroComercial)}
                    >
                        Centro Comercial
                    </Th>
                    <Th
                        id="estado"
                        name="estado"
                        onChange={handleTarget(setEstado)}
                    >
                        Estado
                    </Th>
                    <Th
                        id="cidade"
                        name="cidade"
                        onChange={handleTarget(setCidade)}
                    >
                        Cidade
                    </Th>
                    <Th
                        id="cnpj_pdv"
                        name="cnpj_pdv"
                        onChange={handleTarget(setCnpj)}
                    >
                        CNPJ do PDV
                    </Th>

                    <Th
                        id="data_visita"
                        name="data_visita"
                        type="date"
                        start={{ value: dataVisitaInicio, onChange: handleEvent(setDataVisitaInicio) }}
                        end={{ value: dataVisitaFim, onChange: handleEvent(setDataVisitaFim) }}
                    >
                        Data da Visita
                    </Th>
                    <Th
                        id="periocidade"
                        name="periocidade"
                        onChange={handleTarget(setPeriocidade)}
                    >
                        Periocidade do Roteiro
                    </Th>
                    <Th
                        id="fora_roteiro"
                        name="fora_roteiro"
                        onChange={handleTarget(setFora)}
                    >
                        Fora do Roteiro
                    </Th>
                    <Th
                        id="razao_n_visitou"
                        name="razao_n_visitou"
                        onChange={handleTarget(setRazao)}
                    >
                        Razão Não Visitou
                    </Th>
                    <Th
                        id="status"
                        name="status"
                        onChange={handleTarget(setStatus)}
                    >
                        Status
                    </Th>
                    <Th
                        id="aprovou_via"
                        name="aprovou_via"
                        onChange={handleTarget(setAprovouVia)}
                    >
                        Aprovou via
                    </Th>
                    <Th
                        id="quem_auditou"
                        name="quem_auditou"
                        onChange={handleTarget(setQuemAuditou)}
                    >
                        Quem auditou
                    </Th>
                    <Th
                        id="jus_auditada"
                        name="jus_auditada"
                        onChange={handleTarget(setJusAuditada)}
                    >
                        Justificativa Auditada em
                    </Th>
                    <Th
                        id="justificativa"
                        name="justificativa"
                        onChange={handleTarget(setJustificativa)}
                    >
                        Justificativa
                    </Th>
                    <Th
                        id="quem_jus"
                        name="quem_jus"
                        onChange={handleTarget(setQuemJustificou)}
                    >
                        Quem Justificou
                    </Th>
                    <Th
                        id="jus_via"
                        name="jus_via"
                        onChange={handleTarget(setJustificadoVia)}
                    >
                        Justificado via
                    </Th>
                    <Th
                        id="tipo_check_in"
                        name="tipo_check_in"
                        onChange={handleTarget(setTipoCheckIn)}
                    >
                        Tipo de Check-in
                    </Th>
                    <Th
                        id="check_in_manual"
                        name="check_in_manual"
                        onChange={handleTarget(setPrimeiroCheckManual)}
                    >
                        Primeiro Check-in Manual
                    </Th>
                    <Th
                        id="ultimo_check_in_manual"
                        name="ultimo_check_in_manual"
                        api={window.host + "/systems/integration-react/api/registry.php?do=get_hour_limit&token=" + window.token}
                        onChange={handleEvent(setUltimoCheckManual)}
                    >
                        Último Checkout Manual
                    </Th>
                    <Th
                        id="check_in_auto"
                        name="check_in_auto"
                        onChange={handleTarget(setCheckInAuto)}
                    >
                        Check-in Automático
                    </Th>
                    <Th
                        id="check_out_auto"
                        name="check_out_auto"
                        onChange={handleTarget(setCheckOutAuto)}
                    >
                        Check-Out Automático
                    </Th>
                    <Th
                        id="entrada_programada"
                        name="entrada_programada"
                        onChange={handleTarget(setEntradaPRG)}
                    >
                        Entrada Programada
                    </Th>
                    <Th
                        id="saida_programada"
                        name="saida_programada"
                        onChange={handleTarget(setSaidaPRG)}
                    >
                        Saída Programada
                    </Th>
                    <Th
                        id="check_"
                        name="check_"
                        onChange={handleTarget(setCheck)}
                    >
                        Check-out Automático ?
                    </Th>
                    <Th
                        id="total_pesquisa_pendentes"
                        name="total_pesquisa_pendentes"
                        onChange={handleTarget(setTotalPendente)}
                    >
                        Total de Pesquisas Pendentes
                    </Th>
                    <Th
                        id="total_pesquisa_respondidas"
                        name="total_pesquisa_respondidas"
                        onChange={handleTarget(setTotalRespondida)}
                    >
                        Total de Pesquisas Respondidas
                    </Th>
                    <Th
                        id="total_pesquisa_previstas"
                        name="total_pesquisa_previstas"
                        onChange={handleTarget(setTotalPrevista)}
                    >
                        Total de Pesquisas Previstas
                    </Th>
                    <Th
                        id="todas_respondidas"
                        name="todas_respondidas"
                        onChange={handleTarget(setTodasRespondidas)}
                    >
                        Todas as Pesquisas Respondidas ?
                    </Th>
                    <Th
                        id="total_fotos"
                        name="total_fotos"
                        onChange={handleTarget(setTotalFotos)}
                    >
                        Total de Fotos
                    </Th>
                    <Th
                        id="tempo_executado"
                        name="tempo_executado"
                        onChange={handleEvent(setTempoExecutado)}
                    >
                        Tempo em Loja Executado
                    </Th>
                    <Th
                        id="tempo_automatico"
                        name="tempo_automatico"
                        onChange={handleEvent(setTempoAutomatico)}
                    >
                        Tempo em Loja Automático
                    </Th>
                    <Th
                        id="tempo_planejado"
                        name="tempo_planejado"
                        onChange={handleEvent(setTempoPlanejado)}
                    >
                        Tempo em Loja Planejado
                    </Th>
                    <Th
                        id="saldo"
                        name="saldo"
                        onChange={handleTarget(setSaldo)}
                    >
                        Saldo
                    </Th>
                    <Th
                        id="tempo_atraso_manual"
                        name="tempo_atraso_manual"
                        onChange={handleEvent(setAtrasoManual)}
                    >
                        Tempo de Atraso Manual
                    </Th>
                    <Th
                        id="tempo_atraso_automatico"
                        name="tempo_atraso_automatico"
                        onChange={handleEvent(setAtrasoAutomatico)}
                    >
                        Tempo de Atraso Automático
                    </Th>
                    <Th
                        id="razao_n_realizou"
                        name="razao_n_realizou"
                        onChange={handleTarget(setRazaoTarefa)}
                    >
                        Razão não Realizou a Tarefa
                    </Th>
                </Tr>
            </Thead>
            <Tbody>
                {(itens.length > 0 ?
                    itens.map((item, i) => {
                        return (
                            <Tr key={item.id_job_status}>
                                <Td>
                                    {item.usuario}
                                </Td>
                                <Td>
                                    Isabel Menezes
                                </Td>
                                <Td>
                                    FRANCISCO
                                </Td>
                                <Td>
                                    Macro Regional Francisco
                                </Td>
                                <Td>
                                    D1000
                                </Td>
                                <Td>
                                    {item.empreendimento}
                                </Td>
                                <Td>
                                    {item.loja}
                                </Td>
                                <Td>
                                    Loja de Shopping
                                </Td>
                                <Td align="center" >
                                    {item.lote}
                                </Td>
                                <Td>
                                    PREMIUM
                                </Td>
                                <Td>
                                    Varejo
                                </Td>
                                <Td>
                                    Avenida das Américas
                                </Td>
                                <Td align="center">
                                    -
                                </Td>
                                <Td>
                                    Distrito Federal
                                </Td>
                                <Td>
                                    Brasília
                                </Td>
                                <Td>
                                    {item.id_job_status}
                                </Td>
                                <Td>
                                    {item.data_formatada}
                                </Td>
                                <Td>
                                    Agendado Web
                                </Td>
                                <Td align="center" >
                                    Não
                                </Td>
                                <Td>
                                    Outro
                                </Td>
                                <Td>
                                    Pendente
                                </Td>
                                <Td></Td>
                                <Td></Td>
                                <Td></Td>
                                <Td></Td>
                                <Td></Td>
                                <Td></Td>

                                <Td>
                                    Manual
                                </Td>
                                <Td align="center" >
                                    08:50
                                </Td>
                                <Td align="center" >
                                    {item.hora_limite_formatada}
                                </Td>
                                <Td align="center" >
                                    09:00
                                </Td>
                                <Td align="center" >
                                    19:00
                                </Td>
                                <Td></Td>
                                <Td></Td>
                                <Td align="center" >
                                    Não
                                </Td>
                                <Td align="center" >
                                    0
                                </Td>
                                <Td align="center" >
                                    0
                                </Td>
                                <Td align="center" >
                                    0
                                </Td>
                                <Td align="center" >
                                    Não
                                </Td>
                                <Td align="center" >
                                    0
                                </Td>
                                <Td align="center" >
                                    01:00:00
                                </Td>
                                <Td align="center" >
                                    01:00:00
                                </Td>
                                <Td align="center" >
                                    00:00:00
                                </Td>
                                <Td>

                                </Td>
                                <Td align="center" >
                                    00:00:00
                                </Td>
                                <Td align="center" >
                                    00:00:00
                                </Td>
                                <Td>

                                </Td>
                            </Tr>
                        )
                    })
                    : <></>
                )}
            </Tbody>
        </Table>
    )
}