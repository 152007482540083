import { useState, useEffect } from "react";
import axios from "axios";
import Input from "../../../components/body/form/input";
import Gerenciador from "../../../components/body/gerenciador";
import Row from "../../../components/body/row";
import Switch from "../../../components/body/switch";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Tr from "../../../components/body/table/tr";
import Categorias from "./Categoria";
import Icon from "../../../components/body/icon";
import Form from "../../../components/body/form";
import Button from "../../../components/body/button";
import toast from "react-hot-toast";


export default function GerenciadorMelhorias({ icons, filters }) {

    //ESTADOS LOJAS
    const [lojas, setLojas] = useState([]);
    const [selected, setSelected] = useState('');
    const [search, setSearch] = useState('');
    const [inativos, setInativos] = useState('');
    //ESTADOS TIPO
    const [tipos, setTipos] = useState([]);
    const [searchTipos, setSearchTipos] = useState('');
    const [edit, setEdit] = useState(false);
    const [inativosTipos, setInativosTipos] = useState('')
    //EDITAR TIPOS
    const [idTipo, setIdTipos] = useState('');
    const [nomeTipo, setNomeTipo] = useState('');

    //ESTADOS MUDAR TELA
    const [screen, setScreen] = useState(false);
    const handleScreen = _ => setScreen(!screen);

    //GET LOJAS
    function getLojas() {
        axios.get(window.host + '/api/sql.php?do=select&component=loja&filial=true&np=true&token=' + window.token)
            .then((response) => {
                setLojas(response.data)
            })
    }
    useEffect(() => { getLojas() }, [])


    //GET TIPOS
    function getTipos() {
        axios.get(window.host + '/systems/controle-temperatura/api/gerenciador.php?do=getControleTipo&token=' + window.token)
            .then((response) => {
                setTipos(response.data)
            })
    }
    useEffect(() => {
        getTipos()
    }, []);

    //ABRIR FECHAR EDITOR TIPOS
    const handleEdit = (id, nome) => {
        setEdit(true)
        setIdTipos(id)
        setNomeTipo(nome)
    }
    const callback_close = () => setEdit(false);

    //DATA INPUT API 
    const data = {
        id: idTipo,
        nome: nomeTipo,
        ativo: 1
    }

    function resetForm() {
        setIdTipos('');
        setNomeTipo('');
        getTipos();
        callback_close(false);
    }

    //ATIVAR DESATIVAR TIPOS
    function ativoTipos(id, ativo, nome) {
        let res
        if (ativo == 1) {
            res = 0
        } else {
            res = 1
        }
        axios({
            url: window.host + '/systems/controle-temperatura/api/gerenciador.php?do=setControleTipo&token=' + window.token,
            method: 'post',
            data: {
                nome: nome,
                id: id,
                ativo: res
            },
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(() => {
            toast(`Categoria ${res == 0 ? 'desativada' : 'ativada'} com sucesso`);
            getTipos();
        })

    }

    useEffect(() => {
        if (icons) {
            icons(<> </>)
        }
        if (filters) {
            filters(<></>)
        }
    }, []);

    return (
        <Row>
            {
                screen ?
                    <>
                        <Gerenciador
                            id="tipo-melhorias"
                            title="Tipos"
                            autoScroll={true}
                            search={<Input placeholder="Pesquisar" value={searchTipos} onChange={(e) => setSearchTipos(e.target.value)} />}
                            icon={
                                <>
                                    <Icon type="enter" title="Gerenciador lojas" onClick={handleScreen} />
                                    <Icon type="new" onClick={() => handleEdit('')} />
                                </>
                            }
                            switch={
                                <Input
                                    type="checkbox"
                                    id="check_inativos"
                                    label="Mostrar Inativos"
                                    inverse={true}
                                    onChange={(e) => (setInativosTipos(e.target.checked))}
                                />
                            }
                        >
                            <Table fullHeight={true} >
                                <Tbody>
                                    {(
                                        tipos &&
                                        tipos
                                            .filter((item) => {
                                                if (!searchTipos) return true;
                                                if (item.nome.toLowerCase().includes(searchTipos.toLowerCase())
                                                ) {
                                                    return true
                                                }
                                            }).map((item) => {
                                                return (
                                                    <Tr
                                                        key={item.id}
                                                    >
                                                        <Td
                                                            hide={item.ativo == 0 ?
                                                                (inativosTipos ? false : true)
                                                                : ''
                                                            }
                                                        >
                                                            {item.nome}
                                                        </Td>
                                                        <Td align="end"
                                                            hide={item.ativo == 0 ?
                                                                (inativosTipos ? false : true)
                                                                : ''
                                                            }
                                                        >
                                                            <Icon type="edit" onClick={() => handleEdit(item.id, item.nome)} />
                                                            <Switch
                                                                checked={(item.ativo == 1 ? true : false)}
                                                                onChange={() => ativoTipos(item.id, item.ativo, item.nome)}
                                                            />
                                                        </Td>
                                                    </Tr>
                                                )
                                            })
                                    )}

                                </Tbody>
                            </Table>

                        </Gerenciador>
                        {
                            edit &&
                            <Gerenciador
                                id="editar-tipo-melhorias"
                                title={idTipo ? "Editar" : "Novo"}
                                icon={<Icon type="reprovar" onClick={callback_close} />}
                            >
                                <Form
                                    api={window.host + '/systems/controle-temperatura/api/gerenciador.php?do=setControleTipo&token=' + window.token}
                                    data={data}
                                    callback={resetForm}
                                >
                                    <Input
                                        type="text"
                                        label="Nome"
                                        value={nomeTipo}
                                        onChange={(e) => setNomeTipo(e.target.value)}
                                    />
                                    <Button type="submit" >Enviar</Button>
                                </Form>
                            </Gerenciador>
                        }
                    </>
                    :
                    <>
                        <Gerenciador
                            id="lojas-melhorias"
                            title="Lojas"
                            autoScroll={true}
                            search={
                                <Input placeholder="Pesquisar" value={search} onChange={(e) => setSearch(e.target.value)} />
                            }
                            icon={<Icon type="cog" title="Gerenciador estrutural" onClick={handleScreen} />}
                        // switch={
                        //     <Input
                        //         type="checkbox"
                        //         id="check_inativos"
                        //         label="Mostrar Inativos"
                        //         inverse={true}
                        //         onChange={(e) => (setInativos(e.target.checked))}
                        //     />
                        // }
                        >
                            <Table fullHeight={true} >
                                <Tbody>
                                    {(
                                        lojas &&
                                        lojas
                                            .filter((item) => {
                                                if (!search) return true;
                                                if (item.nome.toLowerCase().includes(search.toLowerCase())
                                                ) {
                                                    return true
                                                }
                                            }).map((item) => {
                                                return (
                                                    <Tr
                                                        key={item.id}
                                                        cursor="pointer"
                                                        active={(selected == item.id ? true : false)}
                                                    >
                                                        <Td
                                                            onClick={() => setSelected(selected == item.id ? "" : item.id)}
                                                        >{item.nome}</Td>
                                                        {/* <Td
                                                        onClick={() => setSelected(selected == item.id ? "" : item.id)}
                                                    >{item.loja}</Td> */}
                                                        {/* <Td align="end" >
                                                        <Switch
                                                            checked={(item.ativo == 1 ? true : false)}
                                                            onChange
                                                        />
                                                    </Td> */}
                                                    </Tr>
                                                )
                                            })
                                    )}
                                </Tbody>
                            </Table>
                        </Gerenciador>
                        {(
                            selected && <Categorias id_loja={selected} />
                        )}
                    </>
            }

        </Row>
    )
}