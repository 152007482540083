import { useState, useEffect } from "react";
import Button from "../../../../../components/body/button";
import Form from "../../../../../components/body/form";
import Input from "../../../../../components/body/form/input";
import Gerenciador from "../../../../../components/body/gerenciador";
import Icon from "../../../../../components/body/icon";
import axios from "axios";

export default function NovaCategoria({ id_table, nome_table, callback, callback_close }) {

    //ESTADOS INPUT
    const [categoria, setCategoria] = useState('');


    //PEGAR INFO 
    // function getInfo() {
    //     axios.get(window.host + "/systems/checklist/api/gerenciador.php?do=get_perguntas&id=" + id_table + "&token=" + window.token)
    //         .then((response) => {

    //         })
    // }

    //ATUALIZAR INPUTS COM INFOS QUE VEM DE PROPS
    useEffect(() => {
        if (id_table) {
            setCategoria(nome_table);
        } else if (!id_table) {
            setCategoria('');
        }
    }, [id_table]);

    //INFORMAÇÕES QUE SERÃO ENVIADAS PARA A API
    const data = {
        id: id_table ? id_table : '',
        name: categoria,
    };

    //LIMPAR OS INPUTS AO ENVIAR O FORMULÁRIO
    function resetForm() {
        setCategoria('');
        callback(true);
        callback_close(false)
    };

    return (
        <Gerenciador
            id="editar_categoria"
            title={id_table ? "Editar" : "Novo"}
            icon={<Icon type="reprovar" title="Fechar" onClick={() => callback_close(false)} />}
        >
            <Form
                api={window.host+"/systems/integration-react/api/registry.php?do=set_category&token=" + window.token}
                data={data}
                callback={resetForm}
                toast={"Salvo com sucesso"}
            >
                <Input
                    type="text"
                    name="categoria"
                    label="Categoria"
                    value={categoria}
                    onChange={(e) => setCategoria(e.target.value)}
                />
                <Button type="submit"> Salvar </Button>
            </Form>
        </Gerenciador>
    )
}