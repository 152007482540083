import { useEffect, useState } from "react";
import axios from "axios";
import Item from "./Item";

export default function Cadastro({id, tipo, loja_id, relatorio_id, callback}){
    // ESTADOS
    const [items, setItems] = useState([]);
    const [values, setValues] = useState([]);

    // FUNÇÃO PARA BUSCAR ITENS DO MICROSSISTEMA
    function get_microssistema(){
        axios({
            method: 'get',
            url: window.host+'/systems/microssistemas-novo/api/novo.php?do=get_microssistema&token='+window.token,
            params: {             
                microssistema_id: id,
                relatorio_id: relatorio_id,
                tipo: tipo,
                loja_id: loja_id
            }
        }).then((response) => {
            setItems(response.data.itens);

            let values_aux = [];
            if(response.data.itens){
                response.data.itens.map((item, i) => {
                    values_aux.push({
                        id: item?.id,
                        value: item?.valor,
                        required: (item?.obrigatorio == 1 ? true : false),
                        validation: (item?.obrigatorio == 1 ? false : true)
                    })
                });   
                
                setValues(values_aux);
            }
        });
    }

    // CHAMA FUNÇÃO PARA BUSCAR ITENS DO MICROSSISTEMA
    useEffect(() => {
        get_microssistema();
    },[id]);

    // PEGA VALORES DOS ITENS
    const handleCallback = (e) => {
        setValues(values => [e, ...values.filter((elem) => elem.id !== e.id)]);
    }

    useEffect(() => {
        if(values.length > 0){
            let validation = true;

            values.map((item, i) => {
                if(!item.value && item.required){
                    validation = false;
                }
            });            

            callback({
                validation: validation,
                values: values
            }); 
        }
    },[values]);

    return (
        <div style={{marginBottom: 20}}>
            {(items.map((item, i) => {
                let type, mask;

                if(item?.componente_id == 1){ // ARQUIVO
                    type = 'file';
                }else if(item?.componente_id == 5){ // TEXTAREA
                    type = 'textarea';
                }else if(item?.componente_id == 7){ // SELECT PERSONALIZADO
                    type = 'select-personalizado';
                }else if(item?.componente_id == 8){ // DATA
                    type = 'date';
                }else if(item?.componente_id == 10){ // SELECT LOJA
                    type = 'select-loja';
                }else if(item?.componente_id == 11){ // SELECT FUNCIONÁRIO
                    type = 'select-funcionario';
                }else if(item?.componente_id == 12){ // SELECT USUÁRIO
                    type = 'select-usuario';
                }else if(item?.componente_id == 14){ // E-MAIL
                    type = 'mail';
                }else if(item?.componente_id == 15){ // CPF
                    type = 'text';
                    mask = '999.999.999-99';
                }else if(item?.componente_id == 30){ // CÂMERA
                    type = 'image';
                }else if(item?.componente_id == 31){ // TELEFONE
                    type = 'tel';
                    mask = '(99) 9999-99999';
                }else if(item?.componente_id == 32){ // CHECKBOX
                    type = 'checkbox-personalizado';
                }else{ // TEXT
                    type = 'text';
                    mask = '';
                }

                return(
                    <Item
                        key={'item_'+item?.id}
                        id={item?.id}
                        loja_id={loja_id}
                        type={type}
                        mask={mask}
                        label={item?.nome}
                        required={(item?.obrigatorio == 1 ? true : false)}
                        value={item?.valor}
                        callback={handleCallback}
                    />
                )
            }))}
        </div>
    )
}
