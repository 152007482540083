import { useContext, useState } from 'react';
import { Routes, Route, useParams, Navigate } from 'react-router-dom';
import Navbar from '../../components/header/navbar';
import NavbarLink from '../../components/header/navbar/navbarLink';
import { GlobalContext } from '../../context/Global';
import { JobsProvider } from "../../context/Jobs";
import { decrypt } from '../../_assets/js/global';
import Container from '../../components/body/container';

// PÁGINAS
import Main from './Main';
import Lista from './Lista/Jobs';
import Dashboard from './Dashboard';
import Graficos from './Graficos';
import RelatorioJobs from './Relatorio';
import Comunicados from './Comunicados';
import Gerenciador from './Gerenciador';
import ListaVisitas from './Lista/Visitas';
import RelatorioVisitas from './Lista/Visitas/lista';

export default function Jobs(props) {
  // CONTEXT JOBS
  const { disabledFilter } = useContext(GlobalContext);

  const params = useParams();

  var tokenUrl_aux = params['*'].split('/');
  var tokenUrl = tokenUrl_aux[tokenUrl_aux.length - 1];
  var decryptedReturn = JSON.parse(decrypt(tokenUrl));

  window.token = tokenUrl;
  window.rs_id_grupo = decryptedReturn.id_grupo;
  window.rs_id_emp = decryptedReturn.id_emp;
  window.rs_id_usr = decryptedReturn.id_usr;
  window.rs_id_lja = decryptedReturn.id_lja;
  window.rs_name_usr = decryptedReturn.name_usr;
  window.rs_id_apl = decryptedReturn.id_apl;
  window.rs_sistema_id = decryptedReturn.sistema_id;
  window.rs_permission_apl = decryptedReturn.pus;
  window.rs_id_module = decryptedReturn.id_module;

  if (props.chamados) {
    if (window.rs_sistema_id == 238) {
      window.link = 'chamados-empresa-react'; // CHAMADOS DE MANUTENÇÃO
    } else if (window.rs_sistema_id == 230) {
      window.link = 'chamados-react'; // CHAMADOS DO EMPREENDIMENTO
    }
  }

  if (props.fases) {
    window.link = 'fases-react';
  }

  if (props.visitas) {
    window.link = 'visitas-react';
  }

  if (!props.chamados && !props.fases && !props.visitas) {
    window.link = 'job-react';
  }

  // OPTIOSN STATUS
  window.optionsStatus = [
    { value: -2, label: 'Atrasado' },
    { value: -1, label: 'Em andamento' },
    { value: 3, label: 'Concluído com atraso' },
    { value: 2, label: 'Não tem' },
    { value: 1, label: 'Finalizado' },
    { value: 4, label: 'Adiado' }
  ];

  let view_aux;
  let title_aux;

  // DEFINE A VIEW DE ACORDO COM PERMISSÃO E SISTEMA
  if ((window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'leitura' || window.rs_permission_apl === 'master') && !props.chamados && !props.fases && !props.visitas) {
    if (props?.widget) {
      view_aux = 1;
    } else {
      view_aux = 2;
    }
  } else {
    view_aux = 1;
  }

  // DEFINE O TÍTULO DO LINK DE ACORDO COM SISTEMA
  if (props?.chamados) {
    title_aux = 'Chamados';
  } else if (props?.fases) {
    title_aux = 'Fases';
  } else if (props?.visitas) {
    title_aux = 'Visitas';
  } else {
    title_aux = 'Meus Jobs';
  }

  // ESTADOS (ÍCONES E FILTROS)
  const [icons, setIcons] = useState(null);
  const [filters, setFilters] = useState(null);

  // DEFINE ICONS NAVBAR
  const handleSetIcons = (e) => {
    setIcons(e);
  }

  // DEFINE FILTROS NAVBAR
  const handleSetFilters = (e) => {
    setFilters(e);
  }

  return (
    <>
      <JobsProvider
        chamados={props.chamados}
        fases={props.fases}
        visitas={props.visitas}
      >
        <Navbar
          icons={icons}
          filters={(filters ? filters : global.filters)}
          disabled={disabledFilter}
        >

          {((window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'leitura' || window.rs_permission_apl === 'master') && !props.chamados && !props.fases && !props.visitas ? // SE FOR NÍVEL SUPERVISOR OU MAIOR (MASTER)
            <NavbarLink link={'/systems/' + window.link + '/calendario/2/' + window.token} name="Lojas" icon="calendar" />
            : '')}

          <NavbarLink link={'/systems/' + window.link + '/calendario/1/' + window.token} name={title_aux} icon="history" />

          {/* {(
            props.visitas && !window.producao ?
              <NavbarLink link={'/systems/' + window.link + '/relatorio-visitas/' + window.token} name="Lista" icon="relatorio" />
              : <></>
          )} */}

          {(window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'leitura' || window.rs_permission_apl === 'master' ? // SE FOR NÍVEL SUPERVISOR OU MAIOR (MASTER)
            <>
              {(props.visitas ?
                <NavbarLink link={'/systems/' + window.link + '/relatorio2/' + window.token} name="Relatório" icon="relatorio" />
                :
                <NavbarLink link={'/systems/' + window.link + '/relatorio/' + window.token} name="Relatório" icon="relatorio" />
              )}
            </>
            : '')}

          {(window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'leitura' || window.rs_permission_apl === 'master' ? // SE FOR NÍVEL SUPERVISOR OU MAIOR (MASTER)
            <NavbarLink link={'/systems/' + window.link + '/dashboard/' + window.token} name="Dashboard" icon="analytics" />
            : '')}

          {(window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'leitura' || window.rs_permission_apl === 'master' ? // SE FOR NÍVEL SUPERVISOR OU MAIOR (MASTER)
            <NavbarLink link={'/systems/' + window.link + '/graficos/' + window.token} name="Gráficos" icon="chart" />
            : '')}

          {(!props.chamados && !props.fases && !props.visitas ?
            <NavbarLink link={'/systems/' + window.link + '/comunicados/' + window.token} name="Comunicados" icon="inbox" />
            : ''
          )}

          {(window.rs_permission_apl === 'master' ?
            <NavbarLink link={'/systems/' + window.link + '/gerenciador/' + window.token} name="Gerenciador" icon="cog" />
            : '')}
        </Navbar>

        <Container>
          <Routes>
            <Route path="/:token" index={true} element={<Navigate to={'calendario/' + view_aux + '/' + window.token} replace />} />
            <Route path="calendario/:view/:token" element={<Main chamados={props.chamados} fases={props.fases} visitas={props.visitas} view={':view'} icons={handleSetIcons} filters={handleSetFilters} />} />
            <Route path="calendario/:view/:store/:token" element={<Main chamados={props.chamados} fases={props.fases} visitas={props.visitas} view={':view'} icons={handleSetIcons} filters={handleSetFilters} />} />
            <Route path="calendario/:view/:store/:periodStart/:token" element={<Main chamados={props.chamados} fases={props.fases} visitas={props.visitas} view={':view'} icons={handleSetIcons} filters={handleSetFilters} />} />
            <Route path="calendario/:view/:store/:periodStart/:periodEnd/:token" element={<Main chamados={props.chamados} fases={props.fases} visitas={props.visitas} view={':view'} icons={handleSetIcons} filters={handleSetFilters} />} />
            <Route path="calendario/:view/:store/:periodStart/:periodEnd/:status/:token" element={<Main chamados={props.chamados} fases={props.fases} visitas={props.visitas} view={':view'} icons={handleSetIcons} filters={handleSetFilters} />} />
            <Route path="calendario/:view/:store/:periodStart/:periodEnd/:status/:category/:token" element={<Main chamados={props.chamados} fases={props.fases} visitas={props.visitas} view={':view'} icons={handleSetIcons} filters={handleSetFilters} />} />

            {(props.visitas ?
              (window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'leitura' || window.rs_permission_apl === 'master' ? // SE FOR NÍVEL SUPERVISOR OU MAIOR (MASTER)
                <Route path="relatorio2/:token" element={<ListaVisitas chamados={props.chamados} fases={props.fases} visitas={props.visitas} icons={handleSetIcons} filters={handleSetFilters} />} />
                : '')
              :
              (window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'leitura' || window.rs_permission_apl === 'master' ? // SE FOR NÍVEL SUPERVISOR OU MAIOR (MASTER)
                <Route path="relatorio/:token" element={<Lista chamados={props.chamados} fases={props.fases} visitas={props.visitas} icons={handleSetIcons} filters={handleSetFilters} />} />
                : '')
            )}

            {(window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'leitura' || window.rs_permission_apl === 'master' ? // SE FOR NÍVEL SUPERVISOR OU MAIOR (MASTER)
              <Route path="dashboard/:token" element={<Dashboard chamados={props.chamados} fases={props.fases} visitas={props.visitas} icons={handleSetIcons} filters={handleSetFilters} />} />
              : '')}

            {(!props.chamados && !props.fases && !props.visitas ?
              <Route path="comunicados/:token" element={<Comunicados icons={handleSetIcons} filters={handleSetFilters} />} />
              : ''
            )}

            {(window.rs_permission_apl === 'supervisor' || window.rs_permission_apl === 'leitura' || window.rs_permission_apl === 'master' ? // SE FOR NÍVEL SUPERVISOR OU MAIOR (MASTER)
              <Route path="graficos/:token" element={<Graficos chamados={props.chamados} fases={props.fases} visitas={props.visitas} icons={handleSetIcons} filters={handleSetFilters} />} />
              : '')}

            <Route path="relatorio/2/:token" element={<RelatorioJobs icons={handleSetIcons} filters={handleSetFilters} />} />

            {(window.rs_permission_apl === 'master' ?
              <Route path="gerenciador/:token" element={<Gerenciador chamados={props.chamados} fases={props.fases} visitas={props.visitas} id_emp={(window.rs_id_emp == 26 ? null : window.rs_id_emp)} integrated={false} filters={handleSetFilters} icons={handleSetIcons} />} />
              : '')}
            {/* {(
              props.visitas ?
                <Route path="relatorio-visitas/:token" element={<RelatorioVisitas icons={handleSetIcons} filters={handleSetFilters} />} />
                : <></>
            )} */}
          </Routes>
        </Container>
      </JobsProvider>
    </>
  );
}
