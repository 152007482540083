import { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import { cd, addDays, get_date } from "../../../../_assets/js/global";
import style from './editar.module.scss';

import Modal from "../../modal";
import ModalHeader from "../../modal/modalHeader";
import ModalTitle from "../../modal/modalHeader/modalTitle";
import ModalBody from "../../modal/modalBody";
import Icon from "../../icon";
import Form from "../../form";
import Textarea from "../../form/textarea";
import Button from "../../button";
import SelectReact from "../../select";
import Title from "../../title";
import InputContainer from "../../form/inputcontainer";
import Input from "../../form/input";
import Row from "../../row";
import Col from "../../col";
import Loader from "../../loader";
import PlanilhaExemplo from '../../../../_assets/img/planilha_exemplo.png';

import { GlobalContext } from "../../../../context/Global";
import { JobsContext } from "../../../../context/Jobs";
import CheckboxUser from "./checkboxUser";
import Card from "..";
import CheckboxGroup from "../../form/checkboxGroup";
import Microssistema from '../../../../pages/Microssistemas/Cadastro';
import Tippy from "@tippyjs/react";
import toast from "react-hot-toast";

export default function Editar(props) {

  // CONTEXT GLOBAL
  const { buttonState, filterModule, refreshCalendar, loadingCalendar } = useContext(GlobalContext);

  // CONTEXT JOBS
  const { optionsSystems, optionsEmpreendimentos, configuracoes } = useContext(JobsContext);

  // ESTADOS
  const [showModal, setShowModal] = useState(false);
  const [showModalAtalhos, setShowModalAtalhos] = useState(false);
  const [shortcutSelected, setShortcutSelected] = useState(false);

  // REF
  const leftCol = useRef(null);
  const importerRef = useRef(null);

  // ABRE MODAL AUTOMATICAMENTE SE TIVER PROPS SHOW
  useEffect(() => {
    if(configuracoes.length > 0){
      if(configuracoes[0].conf_atalho_categoria == 1){
        if (props.show) {
          handleShowModalAtalhos();
        }
      }else{
        if (props.show) {
          handleShowModal();
        }
      }
    }else{
      if (props.show) {
        handleShowModal();
      }
    }
  }, [props.show]);

  // DEFINE VALOR INICIAL DA FREQUÊNCIA
  const frequencyInitial = () => {
    if (props.frequency) {
      return props.frequency;
    } else {
      if (props.chamados || props.fases) {
        return global.frequencia.unico;
      } else if (props.visitas) {
        return global.frequencia.mensal;
      } else {
        return '';
      }
    }
  }

  // DEFINE VALOR INICIAL DA FREQUÊNCIA AUX
  const frequencyAuxInitial = () => {
    if (props.frequency_aux) {
      return props.frequency_aux;
    } else {
      return '';
    }
  }

  // DEFINE VALOR INICIAL DA DATA DE INÍCIO
  const dateStartInitial = () => {
    if (props?.dateStart) {
      return new Date(get_date('date_sql', props?.dateStart, 'date_add_day', 1));
    } else {
      return '';
    }
  }
  
  // DEFINE VALOR INICIAL DA DATA FINAL
  const dateEndInitial = () => {
    if (props?.dateEnd) {
      return new Date(get_date('date_sql', props?.dateEnd, 'date_add_day', 1));
    } else {
      if(props.visitas){
        return new Date(get_date('date_sql', get_date('last_date', window.currentYear+'-'+window.currentMonth+'-01', 'date_sql'), 'date')+' 00:00:00');
      }else{
        return '';
      }
    }
  }

  // DEFINE VALOR INICIAL DA HORA LIMITE
  const hourLimitInitial = () => {
    if(props.avulso){
      return '23:59:59';
    }else{
      return '';
    }
  }

  // DEFINE VALOR INICIAL DO MÓDULO
  const moduloChamadosInitial = () => {
    if (props?.modulo) {
      return props?.modulo;
    } else {
      if (props.chamados || props?.id_system == '224') {
        return filterModule;
      }
    }
  }

  // DEFINE VALOR INICIAL DO MÓDULO FASES
  const moduloFasesInitial = () => {
    if (props?.modulo) {
      return props?.modulo;
    } else {
      if (props.fases || props?.id_system == '225') {
        return filterModule;
      } else {
        return '';
      }
    }
  }

  // DEFINE VALOR INICIAL DOS SISTEMAS 
  const systemInitial = () => {
    if (props?.id_system) {
      return props?.id_system;
    } else {
      if (props.chamados) {
        return ['223', '224'];
      } else if (props.fases) {
        return ['223', '225'];
      } else if (props.visitas) {
        return ['223', '226'];
      } else if (props.comunicados) {
        return ['223', '229'];
      } else if (props.notificacoes) {
        return ['223', '231'];
      } else {
        return ['223'];
      }
    }
  }

  // DEFINE VALOR INICIAL DOS USUÁRIOS SELECIONADOS
  const userInitial = () => {
    if (props.id_usr) {
      return [{id: props.id_usr, date_start: dateStart, hour_limit: hourLimit}];
    } else {
      return [];
    }
  }

  // DEFINE VALOR INICIAL DAS LOJAS SELECIONADAS
  const storeInitial = () => {
    if (props.id_lja) {
      return [{id: props.id_lja, date_start: dateStart, hour_limit: hourLimit}];
    } else {
      return [];
    }
  }

  // DEFINE VALOR INICIAL DO TIPO (LOJA OU USUÁIRO)
  const tipoInitial = () => {
    if (props?.tipo) {
      return props?.tipo;
    } else {
      if (props.id_lja) {
        return 'loja';
      }

      if (props.id_usr) {
        return 'usuario';
      }

      if (props.chamados || props.visitas || props.fases) {
        return 'usuario';
      } else {
        if (props.id_lja) {
          return 'loja';
        }

        if (props.id_usr) {
          return 'usuario';
        } else {
          return 'loja';
        }
      }
    }
  }

  // DEFINE O SISTEMA DE INTEGRAÇÃO INICIAL 
  const idJobSystemInitial = () => {
    if (props?.id_job_system) {
      return props?.id_job_system;
    } else {
      return '';
    }
  }

  // DEFINE O TIPO DE SISTEMA DE INTEGRAÇÃO
  const idJobSystemTypeInitial = () => {
    if (props?.id_job_system_type) {
      return props?.id_job_system_type;
    } else {
      return '';
    }
  }

  // DEFINE A LOJA INICIAL DA INTEGRAÇÃO
  const idJobSystemType1Initial = () => {
    if (props?.id_job_system_type1) {
      return props?.id_job_system_type1;
    } else {
      return [];
    }
  }

  // DEFINE ALERT INICIAL DO BUTTON
  const alertInitial = () => {
    if (props?.button) {
      return props?.button?.title;
    } else {
      if (props.chamados || props.fases || props.visitas) {
        return '';
      } else {
        return 'É necessário selecionar alguma loja ou usuário antes de salvar 5';
      }
    }
  }

  // DEFINE VALIDATION INICIAL DO BUTTON
  const validationInitial = () => {
    if (props?.button) {
      return props?.button?.validation;
    } else {
      if (props.chamados || props.fases || props.visitas) {
        return true;
      } else {
        return false;
      }
    }
  }

  const [loaded, setLoaded] = useState(false);
  const [hideTipo, setHideTipo] = useState(false);
  const [tipoChamado, setTipoChamado] = useState(1);
  const [tipoFase, setTipoFase] = useState(1);
  const [optionsEmpreendimento, setOptionsEmpreendimento] = useState([]);
  const [optionsCliente, setOptionsCliente] = useState([]);
  const [optionsFrequency, setOptionsFrequency] = useState([]);
  const [optionsFrequencyAux, setOptionsFrequencyAux] = useState([]);
  const [optionsCategory, setOptionsCategory] = useState([]);
  const [optionsSubCategory, setOptionsSubCategory] = useState([]);
  const [optionsHourLimit, setOptionsHourLimit] = useState([]);
  const [optionsJobApi, setOptionsJobApi] = useState([]);
  const [optionsModuloChamados, setOptionsModuloChamados] = useState([]);
  const [optionsModuloFases, setOptionsModuloFases] = useState([]);
  const [optionsJobSystem, setOptionsJobSystem] = useState([]);
  const [optionsJobSystemType, setOptionsJobSystemType] = useState([]);
  const [optionsJobSystemType1, setOptionsJobSystemType1] = useState([]);
  const [optionsJobSystemAux, setOptionsJobSystemAux] = useState([]);
  const [optionsJobSystemAux2, setOptionsJobSystemAux2] = useState([]);
  const [optionsJobSystemAux3, setOptionsJobSystemAux3] = useState([]);
  const [optionsJobActionPlain, setOptionsJobActionPlain] = useState([]);
  const [checkboxs, setCheckboxs] = useState([]);
  const [filter, setFilter] = useState("");
  const [filterValue, setFilterValue] = useState(null);
  const [loadingUser, setLoadingUser] = useState(false);
  const [tipo, setTipo] = useState(tipoInitial);
  const [id, setId] = useState("");
  const [lote, setLote] = useState("");
  const [idFrequency, setIdFrequency] = useState(frequencyInitial);
  const [idFrequencyAux, setIdFrequencyAux] = useState(frequencyAuxInitial);
  const [dateStart, setDateStart] = useState(dateStartInitial);
  const [dateEnd, setDateEnd] = useState(dateEndInitial);
  const [idCategory, setIdCategory] = useState((props.category ? props.category : ''));
  const [idSubcategory, setIdSubcategory] = useState((props.subcategory ? props.subcategory : ''));
  const [idPriority, setIdPriority] = useState("");
  const [hourLimit, setHourLimit] = useState(hourLimitInitial);
  const [title, setTitle] = useState((props.job ? props.job : ''));
  const [description, setDescription] = useState((props.description ? props.description : ''));
  const [urlVideo, setUrlVideo] = useState("");
  const [idModuloChamado, setIdModuloChamado] = useState(moduloChamadosInitial);
  const [idModuloFase, setIdModuloFase] = useState(moduloFasesInitial);
  const [moduloChamadoVisible, setModuloChamadoVisible] = useState(false);
  const [idModulo, setIdModulo] = useState([(props.fases ? filterModule : '')]);
  const [idJobApi, setIdJobApi] = useState('');
  const [idJobSystem, setIdJobSystem] = useState(idJobSystemInitial);
  const [nomeIntegracao, setNomeIntegracao] = useState('');
  const [idJobSystemType, setIdJobSystemType] = useState(idJobSystemTypeInitial);
  const [idJobSystemType1, setIdJobSystemType1] = useState(idJobSystemType1Initial);
  const [idJobSystemType2, setIdJobSystemType2] = useState("");
  const [idJobSystemAux, setIdJobSystemAux] = useState(null);
  const [idJobSystemAux2, setIdJobSystemAux2] = useState(null);
  const [idJobSystemAux3, setIdJobSystemAux3] = useState(null);
  const [jobActionPlain, setJobActionPlain] = useState("");
  const [disableJob, setDisableJob] = useState('');
  const [idUsers, setIdUsers] = useState(userInitial);
  const [idStore, setIdStore] = useState(storeInitial);
  const [idGroup, setIdGroup] = useState((props.id_group ? props.id_group : ''));
  const [empreendimento, setEmpreendimento] = useState('');
  const [cliente, setCliente] = useState('');
  const [anexo, setAnexo] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [system, setSystem] = useState(systemInitial);
  const [ativo, setAtivo] = useState(true);
  const [urgent, setUrgent] = useState(false);
  const [risk, setRisk] = useState(false);
  const [agendar, setAgendar] = useState(false);
  const [comunicado, setComunicado] = useState(false);
  const [validation, setValidation] = useState(validationInitial);
  const [alert, setAlert] = useState(alertInitial);
  const [usuariosSelecionados, setUsuariosSelecionados] = useState([]);
  const [lojasSelecionadas, setLojasSelecionadas] = useState([]);
  const [cardsList, setCardsList] = useState([]);
  const [microssistema, setMicrossistema] = useState(null);
  const [microssistemaValidation, setMicrossistemaValidation] = useState(false);
  const [microssistemaValues, setMicrossistemaValues] = useState(null);
  const [atalhosCategoria, setAtalhosCategoria] = useState([]);
  const [atalhosSubcategoria, setAtalhosSubcategoria] = useState([]);
  const [loadImporter, setLoadImporter] = useState(false);
  const [showModalPlanilha, setShowModalPlanilha] = useState(false);

  // VERIFICA SE TODOS OS CAMPOS OBRIGATÓRIOS DO MICROSSISTEMA FORAM PREENCHIDOS
  const handleMicrossistemaCallback = (e) => {
    setMicrossistemaValidation(e.validation);

    let data = [];

    if (e.values) {
      e.values.map((item, i) => {
        data.push({
          valor: item.value,
          opcao_id: '',
          secao_id: item.loja_id,
          loja_id_aux: '',
          item_id: item.id
        });
      });
    }

    setMicrossistemaValues({
      data: data,
      tipo: 'Loja',
      microssistema_id: microssistema,
      job_id: '',
      job_data: '',
      type_system: 'microssistemas'
    });
  }

  // SETAR ANEXO
  const handleSetAnexo = (response) => {
    setAnexo(response[0]);
  };

  // VERIFICA ANEXO E MANDA PRO CALLBACK
  useEffect(() => {
    if (props?.callback && props?.button) {
      props?.callback({
        file: (anexo.length > 0 ? true : false)
      });
    }
  }, [anexo]);

  // BOX DE USUÁRIOS
  function listUsers(tipo, clear) {
    if (!props.avulso) {
      setCheckboxs([]);
      setLoadingUser(true);
      if (!props?.id_job_system_type1) {
        setIdJobSystemType1([]);
      }
      setIdJobSystemType2([]);

      // DEFINE URL DE USUÁRIOS
      let url;
      let params;

      if (props?.chamados) {
        url = window.host + '/systems/integration-react/api/list.php?do=get_operator';
        params = {
          token: window.token,
          // empreendimento_id: (props?.id_emp ? props?.id_emp : ''),
          filter_id_module: filterModule,
          tipo_fase: 'Operação',
          filter_subtype: (tipo === 'loja' ? 'store' : 'user')
        }
      } else {
        url = window.host + '/api/sql.php?do=select&component=' + tipo;
        params = {
          token: window.token,
          np: true,
          filial: true,
          grupo_id: true,
          // empreendimento_id: (props?.id_emp ? props?.id_emp : ''),
          filter_id_module: filterModule
        }
      }

      axios({
        method: 'get',
        url: url,
        params: params
      }).then((response) => {
        setLoadingUser(false);

        if (response.data.length > 0) {
          setCheckboxs(response.data);
        }

        if (!clear) {
          if (props.id_usr) {
            setIdUsers([...idUsers, { id: props.id_usr, date_start: dateStart, hour_limit: hourLimit }]);
          }

          if (props.id_lja) {
            setIdStore([...idStore, { id: props.id_lja, date_start: dateStart, hour_limit: hourLimit }]);
          }
        }
      })
    }
  }

  // BUSCA SUBCATEGORIAS SE VIER SETADO ALGUMA CATEGORIA
  useEffect(() => {
    if(props?.category && showModal){
      changeCategory(props?.category);
    }
  },[props?.category, showModal]);

  // CARREGA LISTA DE EMPREENDIMENTOS
  useEffect(() => {
    if (optionsEmpreendimento.length == 0 && showModal) {
      axios.get(window.host + "/api/sql.php?do=select&component=empreendimento&np=true&filial=true&filter_id_module=" + filterModule + "&token=" + window.token).then((response) => {
        setOptionsEmpreendimento(response.data);
      })
    }
  }, [showModal]);

  const optionsDisableJob = [
    { value: 1, label: 'Finalizar' },
    { value: 2, label: 'Não tem' },
    { value: 3, label: 'Anexo' },
    { value: 4, label: 'Adiar' },
    { value: 5, label: 'Chat' },
    { value: 6, label: 'Notificações de WhatsApp' },
    { value: 7, label: "Aprovar" },
    { value: 8, label: "Reprovar" },
    { value: 9, label: "Reabrir" }
  ]

  // PRIORIDADES
  const optionsPriority = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" },
  ];

  // TIPOS TRADE
  const optionsTiposTrade = [
    { value: 1, label: 'Grupo' },
    { value: 2, label: 'Indústria/Produto' }
  ]

  // VALORES PARA ENVIO DO FORMULÁRIO
  const data = {
    files: [anexo],
    tipo: tipo,
    id: (props?.model?.edit === false ? undefined : id),
    lote: (props?.model?.edit === false ? undefined : lote),
    id_frequency: idFrequency,
    id_frequency_aux: idFrequencyAux,
    disable_job: disableJob,
    id_module: idModulo,
    id_category: idCategory,
    id_subcategory: idSubcategory,
    id_priority: idPriority,
    id_group: idGroup,
    job_action_plain: jobActionPlain,
    job_api: idJobApi,
    job_system_integration: idJobSystem,
    job_system_integration_type: idJobSystemType,
    job_system_integration_type_1: (idJobSystemType1 ? idJobSystemType1 : ''),
    job_system_integration_type_2: (idJobSystemType2 ? idJobSystemType2 : ''),
    url_video: urlVideo,
    hour_limit: (hourLimit ? hourLimit : '23:59:59'),
    description: description,
    date_start: dateStart ? cd(dateStart) : '',
    date_end: dateEnd ? cd(dateEnd) : '',
    title: title,
    id_user: (idUsers.length > 0 ? JSON.stringify(idUsers) : undefined),
    id_store: (idStore.length > 0 ? JSON.stringify(idStore) : undefined),
    enabled_systems: system,
    ativo: (ativo ? 1 : 0),
    urgent: (urgent ? 1 : 0),
    id_priority: (risk ? 7 : 0), // COM RISCO (7), SEM RISCO (0)
    id_user_with_hour: usuariosSelecionados,
    id_store_with_hour: lojasSelecionadas,
    id_client: props?.id_client ? props?.id_client : cliente,
    grupo: (empreendimento ? 1 : 0),
    tipo_fase: (props.tipo_fase ? props.tipo_fase : ''),
    empreendimento_id: (props?.id_emp ? props?.id_emp : ''),
    form_system_integration: (microssistemaValues ? JSON.stringify(microssistemaValues) : ''),
    model: ((props?.model && props.empty) || props?.model?.edit ? 1 : undefined),
    comunicado: comunicado
  };

  // ZERA O VALOR DA DATA DE INÍCIO E FIM SE MUDAR A FREQUÊNCIA
  useEffect(() => {
    if (loaded) {
      if (idFrequency == global.frequencia.unico) {
        setDateEnd(dateStart);
      }

      setIdFrequencyAux(''); // ZERA OPÇÃO DA FREQUÊNCIA SE ALTERAR ELA
    }
  }, [idFrequency])

  // VALIDAÇÃO FORM
  function validacaoForm() {
    let validation_aux = true;
    let toast_aux = false;

    // VERIFICAÇÕES SE FOR JOBS
    if (!system.includes('224') && !system.includes('225') && !system.includes('226') && !system.includes('229') && !system.includes('231')) {

      // SE NÃO TIVER NENHUMA LOJA OU USUÁRIO SELECIONADO
      if (idStore.length === 0 && idUsers.length === 0) {
        toast_aux = 'É necessário selecionar alguma loja ou usuário antes de salvar 1';
        validation_aux = false;
      }
    }

    // VERIFICAÇÕES SE FOR CHAMADOS
    if (system.includes('224')) {

      // SE A FREQUÊNCIA FOR DIFERENTE DE ÚNICA
      if (idFrequency != global.frequencia.unico) {
        toast_aux = 'Para que o card seja incluído em Chamados, Fases ou Comunicados, a frequência precisa ser única';
        validation_aux = false;
      }
    }

    // VERIFICAÇÕES SE FOR FASES
    if (system.includes('225')) {

      // SE A FREQUÊNCIA FOR DIFERENTE DE ÚNICA
      if (idFrequency != global.frequencia.unico) {
        toast_aux = 'Para que o card seja incluído em Chamados, Fases ou Comunicados, a frequência precisa ser única';
        validation_aux = false;
      }
    }

    // VERIFICAÇÕES SE FOR VISITAS
    if (system.includes('226')) {

      // SE NÃO TIVER NENHUMA LOJA OU USUÁRIO SELECIONADO
      if (idStore.length === 0 && idUsers.length === 0) {
        toast_aux = 'É necessário selecionar alguma loja ou usuário antes de salvar 2';
        validation_aux = false;
      }

      // SE A FREQUÊNCIA FOR DIFERENTE DE MENSAL E O CARD NÃO FOR AVULSO OU ESTIVER SENDO EDITADO
      if (idFrequency != global.frequencia.mensal && idFrequency != global.frequencia.unico) {
        toast_aux = 'Para que o card seja incluído em Visitas, a frequência precisa ser única ou mensal';
        validation_aux = false;
      }
    }

    // VERIFICAÇÕES SE FOR COMUNICADOS
    if(system.includes('229')){

      // SE A FREQUÊNCIA FOR DIFERENTE DE ÚNICA
      if (idFrequency != global.frequencia.unico) {
        toast_aux = 'Para que o card seja incluído em Comunicados, a frequência precisa ser única';
        validation_aux = false;
      }

      // SE ESTIVER SELECIONADO OS SISTEMAS CHAMADOS, FASES OU VISITAS
      if(system.includes('224') || system.includes('225') || system.includes('226')){
        toast_aux = 'Não é possível gerar um comunicado se o card estiver vinculado a Chamados, Fases ou Visitas';
        validation_aux = false;
      }

      // SE NÃO TIVER NENHUMA LOJA OU USUÁRIO SELECIONADO
      if(idStore.length === 0 && idUsers.length === 0){
        toast_aux = 'É necessário selecionar alguma usuário antes de salvar 3';
        validation_aux = false;
      }
    }

    // VERIFICAÇÕES SE FOR NOTIFICAÇÕES
    if(system.includes('231')){

      // SE A FREQUÊNCIA FOR DIFERENTE DE ÚNICA
      if (idFrequency != 4) {
        toast_aux = 'Para que o card seja incluído em Notificações, a frequência precisa ser única';
        validation_aux = false;
      }

      // SE ESTIVER SELECIONADO OS SISTEMAS CHAMADOS, FASES OU VISITAS
      if(system.includes('224') || system.includes('225') || system.includes('226')){
        toast_aux = 'Não é possível gerar uma Notificação se o card estiver vinculado a Chamados, Fases ou Visitas';
        validation_aux = false;
      }

      // SE NÃO TIVER NENHUMA LOJA OU USUÁRIO SELECIONADO
      if(idStore.length === 0 && idUsers.length === 0){
        toast_aux = 'É necessário selecionar alguma usuário antes de salvar 4';
        validation_aux = false;
      }
    }

    // DEFINE VALIDAÇÃO
    setAlert(toast_aux);
    setValidation(validation_aux);
  }

  // CHECA VALIDAÇÃO PELO COMPONENTE PAI
  useEffect(() => {
    if (props?.button) {
      setValidation(props?.button?.validation);
    }
  }, [props?.button?.validation]);

  useEffect(() => {
    validacaoForm();
  }, [showModal, idUsers, idStore, idFrequency, system, tipo]);

  // REMOVE OPÇÃO DE SELECIONAR LOJA CASO O SISTEMA "VISITAS" ESTEJA SELECIONADO
  useEffect(() => {
    if (system.includes('224') && props.empty) {
      setDateStart('');
    }
  }, [system]);

  // REMOVE OPÇÕES DE SELECIONAR LOJA OU USUÁRIO CASO O SISTEMA "FASES" ESTEJA SELECIONADO
  useEffect(() => {
    if (system.includes('225')) {
      if (loaded) {
        setIdStore([]);
        setIdUsers([]);
      }
    }
  }, [system]);

  // REMOVE MÓDULOS SELECIONADOS CASO O CHAMADOS OU FASES SEJAM DESATIVADOS
  useEffect(() => {
    if (!system.includes('224')) {
      setIdModuloChamado('');
    }

    if (!system.includes('225')) {
      setIdModuloFase('');
    }
  }, [system]);

  // REMOVE INTEGRAÇÃO DE SISTEMA CASO O SISTEMA "CHAMADOS" ESTEJA SELECIONADO
  useEffect(() => {
    if (system.includes('224')) {
      setIdJobSystem('');
      setIdJobSystemType('');
      setIdJobSystemType1([]);
      setIdJobSystemType2([]);
      setIdJobSystemAux('');
      setIdJobSystemAux2('');
      setIdJobSystemAux3('');
    }
  }, [system]);

  // FUNÇÕES AO ABRIR MODAL
  function handleShowModal(tipo=undefined) {
    setShowModal(true);

    if (props?.get_formOptions !== false) {
      axios.get(window.host + "/systems/integration-react/api/registry.php?do=get_formOptions&empreendimento_id=" + (props?.id_emp ? props?.id_emp : '') + "&lote=" + id + "&filter_id_module=" + filterModule + "&token=" + window.token).then((response) => {
        setOptionsFrequency(response.data.frequency);
        setOptionsFrequencyAux(response.data.frequency_aux);
        setOptionsCategory(response.data.category);
        // setOptionsSubCategory(response.data.subcategory);
        setOptionsHourLimit(response.data.hour_limit);
        setOptionsJobActionPlain(response.data.job_action_plain);
        setOptionsJobApi(response.data.job_api);
        setOptionsJobSystem(response.data.job_system);
        setOptionsModuloChamados(response.data.module_system_chamados);
        setOptionsModuloFases(response.data.module_system_fases);
        setOptionsJobSystemType1(response.data.store);
        setOptionsCliente(response.data.client);

        // PARAMETRO DO VISITAS PARA CRIAR VISITAS AVULSAS
        if (props?.avulso && response?.data?.hour_limit.length > 0) {
          setHourLimit(response.data.hour_limit[response.data.hour_limit.length - 1].value);
        }
      }, []);
    }

    // SE PASSAR O VALOR DIRETAMENTE NA FUNÇÃO
    if(tipo){
      setTimeout(() => {
        setTipo(tipo);
        listUsers(tipo);
      }, 500);
    }else{
      setTimeout(() => {
        if (props?.tipo) {
          setTipo(props?.tipo);
          listUsers(props?.tipo);
        } else {
          setTipo((props.chamados || props.visitas || props.fases ? 'usuario' : (props.id_lja ? 'loja' : (props.id_usr ? 'usuario' : 'loja'))), false);
          listUsers((props.chamados || props.visitas || props.fases ? 'usuario' : (props.id_lja ? 'loja' : (props.id_usr ? 'usuario' : 'loja'))), false);
        }
      }, 500);
    }

    setShortcutSelected(false);
  }

  // FUNÇÕES AO FECHAR MODAL
  function handleCloseModal(submit, internal) {
    setShowModal(false);
    setAnexo([]);
    setId("");
    setTipo("");
    setIdUsers([]);
    setIdStore([]);
    setIdFrequency(frequencyInitial);
    setIdFrequencyAux(frequencyAuxInitial);
    setDateStart(dateStartInitial);
    setDateEnd(dateEndInitial);
    setHourLimit(hourLimitInitial);
    setIdModuloChamado(moduloChamadosInitial);
    setIdModuloFase(moduloFasesInitial);
    setTitle((props.job ? props.job : ''));
    setDescription((props.description ? props.description : ''));
    setIdPriority("");
    setUrlVideo("");
    setIdJobApi("");
    setIdJobSystem(idJobSystemInitial);
    setIdJobSystemType1(idJobSystemType1Initial);
    setIdJobSystemType2([]);
    setJobActionPlain("");
    setSystem(systemInitial);
    setAtivo(true);
    setUrgent(false);
    setRisk(false);
    setAgendar(false);
    setDisableJob([]);
    setEmpreendimento('');
    setCliente('');
    setTipoChamado(1);
    setTipoFase(1);
    setHideTipo(false);
    setIdGroup((props.id_group ? props.id_group : ''));
    setComunicado(false);
      
    if(submit){
      if (submit) {
        setTimeout(() => {
          if (!props?.id_group) {
            // refreshCalendar(false); // FALSE PARA NÃO FAZER A ANIMAÇÃO DE LOADING
            // loadingCalendar();  

            if (props?.reload) {
              props?.reload();
            }
          }else{
            // SE TIVER ID GROUP MANDA O REFRESH CARD COM O ID DELE
            props?.refreshCard(props?.id_job_status_parent);
          }

          if (internal) { // SE O CARD CADASTRADO FOR INTERNO
            if (props.onCloseAux) {
              props?.onCloseAux(true);
            }
          } else {
            if (props.onCloseAux) {
              props?.onCloseAux(false);
            }
          }
        }, 500);
      }

      if (props.onClose) { // SE TIVER RETORNANDO ALGO NO CALLBACK
        props.onClose(false);
        if (!props?.id_group) {
          refreshCalendar();
          loadingCalendar();
        }
      }

      if (props?.id_group) { // SE ESTIVER CADASTRANDO UM JOB INTERNO RETORNA UM CALLBACK PARA RECARREGAR OS CARDS INTERNOS
        props?.callback(true);

        setTimeout(() => {
          props?.callback(false);
        }, 100);
      }
    }

    // RESETA SELECTS
    setSystem(systemInitial);
    setIdCategory((props.category ? props.category : ''));
    setIdSubcategory((props.subcategory ? props.subcategory : ''));
    setMicrossistema(null);
    setMicrossistemaValidation(false);
    setMicrossistemaValues(null);

    if (props.onClose) { // SE TIVER RETORNANDO ALGO NO CALLBACK
      props.onClose(false);
    }

    if (!submit) {
      setShowModal(false);
    }

    setLoaded(false);
  }

  // FUNÇÕES AO ABRIR MODAL DE ATALHOS
  function handleShowModalAtalhos() {
    setShowModalAtalhos(true);

    axios({
      method: 'get',
      url: window.host+'/systems/integration-react/api/registry.php?do=get_category&token='+window.token,
      params: {
        filter_has_shortcut: 1
      }
    }).then((response) => {
      if(response.data){
        setAtalhosCategoria(response.data);
      }
    });

    axios({
      method: 'get',
      url: window.host+'/systems/integration-react/api/registry.php?do=get_subcategory&token='+window.token,
      params: {
        filter_has_shortcut: 1
      }
    }).then((response) => {
      if(response.data){
        setAtalhosSubcategoria(response.data);
      }
    });
  }

  // FUNÇÕES AO FECHAR MODAL DE ATALHOS
  function handleCloseModalAtalhos(){
    setShowModalAtalhos(false);
  }

  // FUNÇÃO PARA TROCAR DE FASE APÓS CADASTRAR CARD INTERNO
  function handleResponse(e) {
    if (props.onCloseAux) {
      props?.onCloseAux(e[0].lote);
    }

    // if(props.autoFinish){
    //   props?.autoFinish(e[0].lote);
    // }
  }

  // RESETA VALOR DA DATA SE O USUÁRIO TROCAR O CHECKBOX DE AGENDAR
  useEffect(() => {
    if (props.chamados && agendar === 0) {
      setDateStart('');
    }
  }, [agendar]);

  function editarJob(id, lote) {
    setLoaded(false); // ESTADO PARA INFORMAR QUE AS INFORMAÇÕES AINDA NÃO FORAM CARREGADAS

    if (props.empty !== false && id) {
      setId(id);
      setLote(lote);
      axios.get(window.host + "/systems/integration-react/api/registry.php?do=get_formOptions&lote=" + lote + "&empreendimento_id=" + (props?.id_emp ? props?.id_emp : '') + "&filter_id_module=" + filterModule + "&token=" + window.token).then((response) => {
        setOptionsFrequency(response.data.frequency);
        setOptionsFrequencyAux(response.data.frequency_aux);
        setOptionsCategory(response.data.category);
        setOptionsSubCategory(response.data.subcategory);
        setOptionsHourLimit(response.data.hour_limit);
        setOptionsJobActionPlain(response.data.job_action_plain);
        setOptionsJobApi(response.data.job_api);
        setOptionsJobSystem(response.data.job_system);
        setOptionsModuloChamados(response.data.module_system_chamados);
        setOptionsModuloFases(response.data.module_system_fases);
        setOptionsJobSystemType1(response.data.store);
        setOptionsCliente(response.data.client);

        // ESCONDE BOTÃO DE TIPO SE TIVER USUÁRIO OU LOJA JÁ VINCULADO
        if (response?.data?.registry) {
          if (response?.data?.registry[0].id_usr || response?.data?.registry[0].id_lja) {
            setHideTipo(true);
          }
        }

        setIdFrequency(response.data.registry[0].mov_frequencia_cfg);
        setIdFrequencyAux(response.data.registry[0].mov_frequencia_aux);
        setDateStart(response.data.registry[0].mov_data ? new Date(response.data.registry[0].mov_data.replace(/-/g, "/")) : "");
        setDateEnd(response.data.registry[0].mov_data_fim ? new Date(response.data.registry[0].mov_data_fim.replace(/-/g, "/")) : "");
        setAtivo((response.data.registry[0].mov_ativo_cfg == 1 ? true : false));
        setUrgent((response.data.registry[0].mov_urgente == 1 ? true : false));
        setRisk((response.data.registry[0].mov_prioridade == 7 ? true : false));
        setSystem((response.data.registry[0].id_apl ? response.data.registry[0].id_apl.split(',') : ''));
        setHourLimit(response.data.registry[0].mov_hora);
        setIdGroup(response.data.registry[0].mov_id_grupo);

        // SETA ITENS DESABILITADOS
        if (response.data.registry[0].mov_desabilitar_cfg) {
          let desabilitar_aux = [];

          // NECESSÁRIO FAZER ESSE MAP ANTES PARA VERIFICAR SE O ID DO ITEM JÁ EXISTE, ALGUNS JOBS ENTRARAM ENVIANDO ID'S REPETIDOS
          response.data.registry[0].mov_desabilitar_cfg.split(',').map((disable, i) => {
            if (disable) {
              if (!desabilitar_aux.includes(Number(disable))) {
                desabilitar_aux.push(Number(disable));
              }
            }
          });

          setDisableJob(desabilitar_aux);
        }

        // SETA LOJAS OU USUÁRIOS SELECIONADOS
        if (response?.data?.registry[0].usuarios) {
          let usuarios = [];

          response.data.registry.map((user, i) => {
            usuarios.push({
              id: user.id_usr,
              date_start: (user.mov_data ? new Date(user.mov_data.replace(/-/g, "/")) : ""),
              hour_limit: (user.mov_hora ? user.mov_hora : hourLimit)
            })
          });

          setIdUsers(usuarios);
        }

        if (response?.data?.registry[0].lojas) {
          let lojas = [];

          response.data.registry.map((loja, i) => {
            lojas.push({
              id: loja.id_lja,
              date_start: (loja.mov_data ? new Date(loja.mov_data.replace(/-/g, "/")) : ""),
              hour_limit: (loja.mov_hora ? loja.mov_hora : hourLimit)
            })
          });

          setIdStore(lojas);
        }

        if (response.data.registry[0].mov_id_modulo) {
          if (response.data.module_system_chamados) {
            if (response?.data?.registry[0]?.mov_id_modulo.indexOf(',') > -1) { // VERIFICAR SE O MOV_ID_MODULO TEM VÍRGULA PARA FAZER O MAP
              response?.data?.registry[0]?.mov_id_modulo.split(',').map((value) => {
                if (response?.data.module_system_chamados[0]?.value == Number(value)) {
                  setIdModuloChamado(Number(value));
                }
              });
            } else { // CASO CONTRÁRIO, SETA O ÚNICO ID QUE TEM
              setIdModuloChamado(Number(response?.data?.registry[0]?.mov_id_modulo));
            }
          }

          if (response.data.module_system_fases) {
            if (response?.data?.registry[0]?.mov_id_modulo.indexOf(',') > -1) { // VERIFICAR SE O MOV_ID_MODULO TEM VÍRGULA PARA FAZER O MAP
              response?.data?.registry[0]?.mov_id_modulo.split(',').map((value) => {
                if (response?.data?.module_system_fases[0]?.value == Number(value)) {
                  setIdModuloFase(Number(value));
                }
              });
            } else { // CASO CONTRÁRIO, SETA O ÚNICO ID QUE TEM
              setIdModuloFase(Number(response?.data?.registry[0]?.mov_id_modulo));
            }
          }
        }

        setIdCategory(response.data.registry[0].mov_categoria_cfg);
        setIdSubcategory(response.data.registry[0].mov_subcategoria_cfg);
        setTitle(response.data.registry[0].mov_titulo);
        setDescription(response.data.registry[0].mov_des_anexo);
        setIdPriority(response.data.registry[0].mov_prioridade);
        setUrlVideo(response.data.registry[0].mov_url_video);
        setIdJobApi(response.data.registry[0].mov_job_api);
        setAnexo(response.data.registry[0].mov_anexos);
        setCliente(response?.data?.registry[0]?.mov_id_cliente);
        setIdJobSystem(response.data.registry[0].mov_plano_acao_cfg); // PRECISA AJUSTAR O BACK-END
        if (response.data.registry[0].mov_sistemas) {
          let system_job = response.data.registry[0].mov_plano_acao_cfg;
          let aux = JSON.parse(response.data.registry[0].mov_sistemas);
          let system_type = aux.job_system_integration_type;
          let system_type_1 = aux.job_system_integration_type_1;
          let system_type_2 = aux.job_system_integration_type_2;

          axios.get(window.host + "/systems/integration-react/api/registry.php?do=get_system_type&empreendimento_id=" + (props?.id_emp ? props?.id_emp : '') + "&system_job=" + system_job + "&filter_id_module=" + filterModule + "&token=" + window.token).then((response) => {
            setOptionsJobSystemType(response.data);

            if (system_job == '18') { // TRADE
              changeSystemAux(system_job, system_type);
              setOptionsJobSystemType(optionsTiposTrade);
              setIdJobSystemType(parseInt(system_type));
            } else {
              setIdJobSystemType(parseInt(system_type));
            }

            setIdJobSystemType1(system_type_1.split(','));
            setIdJobSystemType2(system_type_2.split(','));
          }, []);
        }

        if (response.data.registry[0].mov_categoria_cfg) {
          changeCategory(response.data.registry[0].mov_categoria_cfg);
        }

        if (response.data.registry[0].id_usr) {
          setTipo("usuario");
          listUsers("usuario", true);
        } else if (response.data.registry[0].id_lja) {
          setTipo("loja");
          listUsers("loja", true);
        }

        setTimeout(() => {
          setLoaded(true); // ESTADO PARA INFORMAR QUE AS INFORMAÇÕES FORAM CARREGADAS
        }, 1000);
      }, []);

      setShowModal(true);
    }
  }

  // FUNÇÃO PARA TROCA SELECT CATEGORIA
  function changeCategory(id) {
    axios.get(window.host + "/systems/integration-react/api/registry.php?do=get_subcategory&empreendimento_id=" + (props?.id_emp ? props?.id_emp : '') + "&filter_id_category=" + id + "&filter_id_module=" + filterModule + "&token=" + window.token)
      .then((response) => {
        setOptionsSubCategory(response.data);
      }, []);
  }

  // FUNÇÃO PARA TROCA SELECT SISTEMA
  function changeSystem(id) {
    if (!props.id_job_system_type1) {
      setIdJobSystemType1([]); // RESETA SELECT DE LOJA DO CHECKLIST
    }
    setIdJobSystemType2([]);
    setOptionsJobSystemType([]);

    if (id == 18) { // SISTEMA TRADE
      setOptionsJobSystemType(optionsTiposTrade);
    } else { // OUTROS SISTEMAS
      axios.get(window.host + "/systems/integration-react/api/registry.php?do=get_system_type&empreendimento_id=" + (props?.id_emp ? props?.id_emp : '') + "&system_job=" + id + "&filter_id_module=" + filterModule + "&token=" + window.token).then((response) => {
        setOptionsJobSystemType(response.data);
      }, []);
    }
  }

  // CORREÇÃO PROVISÓIA PARA CARDS DE VISITAS AVULSAS
  useEffect(() => {
    if (props?.avulso && showModal) {
      axios.get(window.host + "/systems/integration-react/api/registry.php?do=get_system_type&empreendimento_id=" + (props?.id_emp ? props?.id_emp : '') + "&system_job=1&filter_id_module=" + filterModule + "&token=" + window.token).then((response) => {
        setOptionsJobSystemType(response.data);
      }, []);
    }
  }, [props?.avulso, showModal]);

  // FUNÇÃO PARA TROCA SELECT SISTEMA AUX
  function changeSystemAux(system_job, id) {
    axios.get(window.host + "/systems/integration-react/api/registry.php?do=get_system_type&empreendimento_id=" + (props?.id_emp ? props?.id_emp : '') + "&system_job=" + system_job + "&system_job_aux=" + id + "&filter_id_module=" + filterModule + "&token=" + window.token).then((response) => {
      if (id == 1) {
        setOptionsJobSystemAux(response.data);
      } else if (id == 2) {
        setOptionsJobSystemAux2(response.data.r1);
        setOptionsJobSystemAux3(response.data.r2);
      }
      setIdJobSystemAux(response?.data[0]?.value);
    }, []);
  }

  // FUNÇÃO DE EXECUÇÃO DO RADIO
  function setRadios(e) {
    setTipo(e.target.value);

    setIdUsers([]);
    setIdStore([]);
    setUsuariosSelecionados([]);
    setLojasSelecionadas([]);

    listUsers(e.target.value, true);
  }

  // SETA ITENS DESABILITADOS
  const handleSetDisabledJob = (e) => {
    setDisableJob(e);
  }

  function handleCheck(e, date, hour) {
    if (e === undefined && (date || hour)) { // SE NÃO TIVER ALTERAÇÃO NO CHECKBOX DE USUÁRIOS SELECIONADOS SÓ TROCA A DATA
      if (tipo == 'usuario') {
        let usuarios = [];
        if (idUsers) {
          idUsers.map((user, i) => {
            usuarios.push({
              id: user.id,
              date_start: get_date('date_sql', cd(date), 'date'),
              hour_limit: (hour ? hour : hourLimit)
            })
          });
        }
        setIdUsers(usuarios);
      }

      if (tipo == 'loja') {
        let lojas = [];
        idStore.map((loja, i) => {
          lojas.push({
            id: loja.id,
            date_start: get_date('date_sql', cd(date), 'date'),
            hour_limit: (hour ? hour : hourLimit)
          })
        });
        setIdStore(lojas);
      }
    } else {
      if (system.includes('225') || system.includes('226')) { // SE TIVER CHAMADOS (224), FASES (225) OU VISITAS (226) SELECIONADOS SETA SÓ UM        
        if (tipo === "usuario") {
          setIdUsers([{ id: e.id, date_start: get_date('date_sql', e.date, 'datetime_sql'), hour_limit: e.hour }]);
        } else if (tipo === "loja") {
          setIdStore([{ id: e.id, date_start: get_date('date_sql', e.date, 'datetime_sql'), hour_limit: e.hour }]);
        }

        if(system.includes('226') && props?.subTipoCalendario === 'store'){
          setTitle(e.label);
        }
      } else {

        if (e.checked) {
          if (tipo === "usuario") {
            setIdUsers([...idUsers.filter((item) => item.id !== e.id), { id: e.id, date_start: get_date('date_sql', e.date, 'datetime_sql'), hour_limit: e.hour }]);
          } else if (tipo === "loja") {
            setIdStore([...idStore.filter((item) => item.id !== e.id), { id: e.id, date_start: get_date('date_sql', e.date, 'datetime_sql'), hour_limit: e.hour }]);
          }
        } else {
          setSelectAll(false);
          if (tipo === "usuario") {
            setIdUsers(idUsers.filter((item) => item.id !== e.id));
          } else if (tipo === "loja") {
            setIdStore(idStore.filter((item) => item.id !== e.id));
          }
        }
      }
    }

    if (idFrequency == global.frequencia.unico) { // SE A FREQUÊNCIA FOR ÚNICA, SETA A MESMA DATA NA DATA FINAL
      setDateEnd(date);
    }

    validacaoForm();
  }

  function handleCheckDate(e, date) {
    handleCheck(e, date, hourLimit);
  }

  function handleCheckHour(e, hour) {
    handleCheck(e, dateStart, hour);
  }

  // FUNÇÃO PARA JUNTAR ID MÓDULO CHAMADOS E FASES EM UM ARRAY
  useEffect(() => {
    let modulos = [];

    if((props.fases && !idModuloFase) || props.visitas){
      modulos.push(filterModule);
    }

    if (idModuloChamado) {
      if (!modulos.includes(idModuloChamado)) {
        modulos.push(idModuloChamado);
      }
    }
    if (idModuloFase) {
      if (!modulos.includes(idModuloFase)) {
        modulos.push(idModuloFase);
      }
    }

    setIdModulo(modulos);
  }, [idModuloChamado, idModuloFase]);

  // FUNÇÃO PARA SELECIONAR CHECKBOX SYSTEM TYPE 1 DE INTEGRAÇÃO (TRADE)
  function handleSetIdJobSystemType1(e) {
    if (e.target.checked) {
      setIdJobSystemType1([...idJobSystemType1, e.target.value]);
    } else {
      setIdJobSystemType1(idJobSystemType1.filter((item) => item !== e.target.value));
    }
  }

  // FUNÇÃO PARA SELECIONAR CHECKBOX SYSTEM TYPE 2 DE INTEGRAÇÃO (TRADE)
  function handleSetIdJobSystemType2(e) {
    if (e.target.checked) {
      setIdJobSystemType2([...idJobSystemType2, e.target.value]);
    } else {
      setIdJobSystemType2(idJobSystemType2.filter((item) => item !== e.target.value));
    }
  }

  // FUNÇÃO PARA SELECIONAR SISTEMAS QUE O JOB FARÁ PARTE
  function handleSystem(e) {
    if (e.target.checked) {
      setSystem([...system, e.target.value]);

      if (e.target.value == 226) { // SE SELECIONAR VISITAS, ZERA OS USUÁRIOS/LOJAS SELECIONADOS
        setIdUsers([]);
        setIdStore([]);
        listUsers(tipo, true);
      }

      if (e.target.value == 229 || e.target.value == 231) { // SE SELECIONAR COMUNICADOS OU NOTIFICAÇÕES, ZERA OS CAMPOS DE INTEGRAÇÃO E OPCIONAIS
        setUrlVideo('');
        setIdJobSystem('');
        setIdJobSystemType('');
        setIdJobSystemType1('');
        setIdJobSystemType2('');
        setIdJobApi('');
        setIdStore([]);
      }
    } else {
      setSystem(system.filter((item) => item !== e.target.value));
    }
  }

  function checkAll(e) {
    if (e.target.checked) {
      setIdUsers([]);
      setIdStore([]);

      if (tipo === "usuario") {
        setIdUsers(checkboxs.map((item) => {
          return { id: item.value, date_start: dateStart, hour_limit: hourLimit }
        }));
      } else if (tipo === "loja") {
        setIdStore(checkboxs.map((item) => {
          return { id: item.value, date_start: dateStart, hour_limit: hourLimit }
        }));
      }
      setAllChecked(true);
      setSelectAll(true);
    } else {
      setIdUsers([]);
      setIdStore([]);
      setAllChecked(false);
      setSelectAll(false);
    }
  }

  // TÍTULO MODAL
  var modalTitle;
  if (props.modalTitle) {
    modalTitle = props.modalTitle;
  } else {
    if (props.chamados) {
      modalTitle = (props.empty ? 'Novo' : 'Editar') + ' Chamado';
    } else if (props.fases) {
      modalTitle = (props.empty ? 'Nova' : 'Editar') + ' Fase';
    } else if (props.visitas) {
      modalTitle = (props.empty ? 'Nova' : 'Editar') + ' Visita';
    } else {
      modalTitle = (props.empty ? 'Novo' : 'Editar') + ' Job';
    }
  }

  // FUNÇÃO PARA DEFINIR TEXTO DO TOAST
  const handleToast = () => {
    if (props?.toast) {
      return props?.toast
    } else {
      if (props.empty) {
        return 'Card cadastrado com sucesso';
      } else {
        if (ativo) {
          if (props.plano) {
            return 'Plano de ação criado com sucesso';
          } else {
            return 'Card editado com sucesso';
          }
        } else {
          return 'Card desativado com sucesso';
        }
      }
    }
  }

  // FUNÇÕES AO TROCAR CATEGORIA
  function handleSetCategory(id){
    setIdCategory(id);
    changeCategory(id);
    
    if(props.chamados){
      setIdModuloChamado(filterModule);
    }
  }

  // FUNÇÕES AO TROCAR SUBCATEGORIA
  const handleSetSubcategory = (e) => {
    setIdSubcategory(e.value);

    if (props.chamados && e.id_ite_aux) { // SE ESTIVER EM CHAMADOS E RECEBER O VALOR "ID_ITE_AUX" SETA O MESMO COMO MÓDULO
      setIdModuloChamado(e.id_ite_aux);
      setModuloChamadoVisible(false);
    } else {
      if (window.rs_id_lja > 0) {
        setModuloChamadoVisible(true);
      }
    }

    if (e?.arquivo) { // SE RECEBER VALOR DO PAR_AUX
      let id = JSON.parse(e.arquivo).id;
      setMicrossistema(id);
    } else {
      setMicrossistema(null);
    }

    setMicrossistemaValidation(false);
    setMicrossistemaValues(null);
  }

  // AÇÕES AO CLICAR EM ALGUM ATALHO
  function handleClickShortcut(categoria_id, subcategoria_id, categoria_nome, subcategoria_nome, config){
    if(config && config != 1 && config != 0){
      let config_aux = JSON.parse(config)[0];
      
      if(config_aux?.category){
        handleSetCategory(config_aux?.category);
      }

      if(config_aux?.subcategory){
        setIdSubcategory(config_aux?.subcategory);
      }

      if(config_aux?.date_start){
        setDateStart(config_aux?.date_start ? new Date(cd(config_aux?.date_start)) : '');
      }

      if(config_aux?.description){
        setDescription(config_aux?.description);
      }

      if(config_aux?.disable_actions){
        setDisableJob(config_aux?.disable_actions);
      }

      if(config_aux?.file){
        setAnexo(config_aux?.file);
      }

      if(config_aux?.frequency){
        setIdFrequency(config_aux?.frequency);
      }

      if(config_aux?.hour_limit){
        setHourLimit(config_aux?.hour_limit);
      }

      if(config_aux?.system_job){
        setIdJobSystem(config_aux?.system_job);
        changeSystem(config_aux?.system_job);
      }

      if(config_aux?.job_system_type){
        setIdJobSystemType(config_aux?.job_system_type);    
      }

      if(config_aux?.title){
        setTitle(config_aux?.title);        
      }    
      
      if(config_aux?.frequency_aux){
        setIdFrequencyAux(config_aux?.frequency_aux);        
      }

      if(config_aux?.type){
        setTipo(config_aux?.type);
        listUsers(config_aux?.type);
      }

      setShowModalAtalhos(false);
      setShortcutSelected(config_aux?.type ? config_aux?.type : true);
    }else{      
      handleSetCategory(categoria_id);
      setIdSubcategory(subcategoria_id);
      setIdFrequency(global.frequencia.unico);
      setHourLimit('23:00:00');
      setTitle(categoria_nome + ' - ' + subcategoria_nome);
      setDateStart(new Date());
      setShowModalAtalhos(false);
      setShortcutSelected(true);
    }
  }

  useEffect(() => {
    if(shortcutSelected){
      setTimeout(() => {
        if(shortcutSelected === true){
          handleShowModal();
        }else{
          handleShowModal(shortcutSelected);
        }
      },200);
    }
  },[shortcutSelected]);
  

  // CLICK ÍCONE IMPORTADOR (ABRIR MODAL DE EXEMPLO)
  const handleSetImporter = () => {
    setShowModal(false);
    setTimeout(() => {
      setShowModalPlanilha(true);
    },100);
  }

  // CLICK BOTÃO IMPORTADOR (SELECIONAR ARQUIVO)
  const handleSetImporterFile = () => {
    if(importerRef){
      importerRef.current.click();
    }
  }

  // SETA VALOR PLANILHA DE LOJAS
  const handleSetLojas = (response) => {
    setIdStore([]);
    setLoadImporter(true);
    setShowModalPlanilha(false);
    setTimeout(() => {
      setShowModal(true);
    },100);
    
    axios({
      method: 'post',
      url: window.host+'/systems/integration-react/api/registry.php',
      params: {
        token: window.token,
        do: 'importCheckStore',
        type: 'store'        
      },
      data: {
        excel: response?.target?.files[0]
      },
      cache: false,
      processData: false,
      contentType: false,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then((response) => {
      setLoadImporter(false);
      
      if(response?.data?.data.length > 0){
        setIdStore(response?.data?.data.map((item) => {
          return { id: item?.id, date_start: dateStart, hour_limit: hourLimit }
        }));
      }else{
        toast('Nenhuma loja importada, verifique a planilha e tente novamente');
      }
    })
  }
  
  return (
    <>
      <Modal show={showModalPlanilha} onHide={() => setShowModalPlanilha(false)}>
        <ModalHeader>
          <ModalTitle>Importar Lojas</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className="d-flex">
            <div>
              <img src={PlanilhaExemplo} />
            </div>
            <div className="pt-4 pt-lg-0 ps-0 ps-lg-4">
              <p>
                A planilha enviada precisa seguir o mesmo padrão do exemplo ao lado, em uma única coluna, listar o número de todas as filiais que precisam ser importadas.
              </p>
              <Button
                onClick={handleSetImporterFile}
              >
                Selecionar planilha <Icon type="upload" className="ms-1 text-white" readonly={true} title={false} />
              </Button>

              <input
                ref={importerRef}
                type="file"
                className={'d-none'}
                accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}
                onChange={handleSetLojas}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal show={showModalAtalhos} onHide={() => handleCloseModalAtalhos(false)} large={true}>
        <ModalHeader>
          <ModalTitle>Atalhos</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div className={style.shortcut_container}>
            {(atalhosSubcategoria.length > 0 ?
              atalhosSubcategoria.map((item, i) => {
                if(item?.par_atalho){
                  let categoria_aux = atalhosCategoria.filter((elem) => elem.value == item?.aux1);
                  let title_aux;
                  let subtitle_aux;

                  // SE VIER TÍTULO E SUBTITULO DA CONFIGURAÇÃO SETA ELES, SE NÃO, SETA COMO CATEGORIA E SUBCATEGORIA POR PADRÃO
                  if(item?.par_atalho && item?.par_atalho != 1){
                    let config_aux;

                    // SE A SUB TEM CONFIGURAÇÃO DE ATALHO, PEGA A CONFIGURAÇÃO DELA, SE NÃO PEGA A CONFIGURAÇÃO DA CATEGORIA
                    if(item?.par_atalho){
                      config_aux = JSON.parse(item?.par_atalho)[0];
                    }else{
                      config_aux = JSON.parse(categoria_aux[0].ite_atalho)[0];
                    }
                    
                    title_aux = config_aux?.shortcut_title;
                    subtitle_aux = config_aux?.shortcut_subtitle;
                  }else{
                    title_aux = (categoria_aux[0]?.label ? categoria_aux[0]?.label : '');
                    subtitle_aux = item?.label;
                  }

                  return(
                    <div
                      key={'shortcut_'+item?.value}
                      className={style.shortcut}
                      onClick={() => handleClickShortcut(categoria_aux[0]?.value, item?.value, categoria_aux[0]?.label, item?.label, (item?.par_atalho ? item?.par_atalho : categoria_aux[0]?.ite_atalho))}
                    >
                      <Card       
                        title={title_aux}
                        subtitle={subtitle_aux}
                        size="smallest"
                        wrap={true}     
                        bold={true}      
                      />
                    </div>
                  )
                }
              })              
            :'')}
          </div>
        </ModalBody>
      </Modal>

      <Modal show={showModal} onHide={() => handleCloseModal(false)} large={(props?.avulso || props?.modalLarge === false || ((window.rs_id_lja > 0 && props.chamados && !global.message)) ? false : true)} id={props.id ? props.id : ''}>
        <ModalHeader>
          <ModalTitle>{modalTitle}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          {(window.isMobile && !props.visitas && !props?.avulso && props?.tipo !== false && (((window.rs_id_lja == 0 || !window.rs_id_lja) && props.chamados) || !props.chamados) ?
            <>
              {(!hideTipo ?
                <InputContainer>
                  <Input
                    type="radio"
                    required={false}
                    name="tipo"
                    id="lojas"
                    label="Lojas"
                    value="loja"
                    checked={tipo === "loja"}
                    onChange={setRadios}
                  />
                  <Input
                    type="radio"
                    required={false}
                    name="tipo"
                    id="usuarios"
                    label="Usuários"
                    value="usuario"
                    checked={tipo === "usuario"}
                    onChange={setRadios}
                  />
                </InputContainer>
                : '')}
            </>
            : '')}

          <Row direction={window.isMobile ? 'column-reverse' : ''}>
            <Col className="mb-4 mb-lg-0">
              <div ref={leftCol}>
                <Form
                  api={window.host + "/systems/integration-react/api/registry.php?do=set_registry&filter_id_module=" + filterModule + "&token=" + window.token}
                  data={data}
                  className={(!props?.avulso ? 'mb-4' : '')}
                  callback={() => handleCloseModal(true)}
                  response={(e) => handleResponse(e)}
                  toast={handleToast}
                >
                  {(!window.isMobile && !props.visitas && !props?.avulso && props?.tipo !== false && (((window.rs_id_lja == 0 || !window.rs_id_lja) && props.chamados) || !props.chamados) ?
                    <>
                      {(
                        !hideTipo ?
                          <InputContainer>
                            <Input
                              type="radio"
                              required={false}
                              name="tipo"
                              id="lojas"
                              label="Lojas"
                              value="loja"
                              checked={tipo === "loja"}
                              onChange={setRadios}
                            />

                            <Input
                              type="radio"
                              required={false}
                              name="tipo"
                              id="usuarios"
                              label="Usuários"
                              value="usuario"
                              checked={tipo === "usuario"}
                              onChange={setRadios}
                            />
                          </InputContainer>
                          : '')}
                    </>
                    : '')}

                  {(!props.visitas && !props.avulso && props?.systems !== false ?
                    <InputContainer display={(window.isMobile ? 'block' : '')}>
                      <Input
                        type="checkbox"
                        required={false}
                        name="sistemas"
                        id={'sistema_1'}
                        label={'Jobs'}
                        value={1}
                        checked={true}
                        onChange={() => console.log('')}
                        disabled={true}
                      />

                      {optionsSystems.map((item, i) => {
                        return (
                          <Input
                            key={'sistema_' + item.value}
                            type="checkbox"
                            required={false}
                            name="sistemas"
                            id={'sistema_' + item.value}
                            label={item.label.replace(' React', '').replace(' R', '')}
                            value={item.value}
                            checked={(system.includes(item.value.toString()) ? true : null)}
                            onChange={(e) => handleSystem(e)}
                            disabled={item?.disabled}
                          />
                        )
                      })}
                    </InputContainer>
                    : '')}

                  {(!props.chamados && !props.fases && !props.plano && !props.id_group && !props.avulso ?
                    <SelectReact
                      label="Frequência"
                      name="id_frequency"
                      options={optionsFrequency}
                      value={idFrequency}
                      onChange={(e) => setIdFrequency(e.value)}
                    />
                    : '')}

                  {((idFrequency && optionsFrequencyAux.filter((elem) => elem.aux1 == idFrequency).length > 0) && !props.avulso ?
                    <SelectReact
                      label="Opção frequência"
                      name="id_frequency_aux"
                      options={(idFrequency ? optionsFrequencyAux.filter((elem) => elem.aux1 == idFrequency) : [])}
                      value={idFrequencyAux}
                      onChange={(e) => setIdFrequencyAux(e.value)}
                    />
                    : '')}

                  {/* SE ESTIVER EM CHAMADOS O CAMPO ABAIXO NÃO É EXIBIDO */}
                  {(!props.chamados ?
                    <Input
                      type="date"
                      label="Data"
                      name="date_start"
                      value={dateStart}
                      disabled={((window.rs_permission_apl !== 'master') && props?.avulso ? true : false)}
                      valueStart={(props.empty ? (props.chamados || system.includes('224') ? new Date() : '') : new Date())}
                      noWeekend={(idFrequency == 1 ? true : false)}
                      onChange={(e) => (setDateStart(e), handleCheckDate(undefined, e))}
                    />
                    :
                    ''
                  )}

                  <Input
                    type="date"
                    label="Data Final"
                    name="date_end"
                    id="date_end"
                    value={dateEnd}
                    valueStart={(dateStart ? addDays(dateStart, 1) : '')}
                    disabled={(window.rs_permission_apl !== 'master' && props?.avulso ? true : false)}
                    noWeekend={(idFrequency == 1 ? true : false)}
                    onChange={(e) => setDateEnd(e)}
                    required={idFrequency == global.frequencia.unico || !idFrequency ? false : true}
                    hide={idFrequency == global.frequencia.unico || !idFrequency ? true : false}
                  />

                  {(!props.chamados && !props?.avulso ?
                    <SelectReact
                      label="Hora limite"
                      name="hour_limit"
                      options={optionsHourLimit}
                      value={hourLimit}
                      onChange={(e) => (setHourLimit(e.value), handleCheckHour(undefined, e.value))}
                    />
                    : '')}

                  {(!props.plano && !props?.id_group && props?.category?.show !== false ?
                    <SelectReact
                      label="Categoria"
                      name="id_category"
                      options={optionsCategory}
                      value={idCategory}
                      disabled={(window.rs_permission_apl !== 'master' && props?.avulso ? true : false)}
                      onChange={(e) => handleSetCategory(e.value)}
                    />
                    : '')}

                  {idCategory && !props.plano && !props?.id_group && props?.subcategory?.show !== false ? (
                    <SelectReact
                      label="Subcategoria"
                      name="id_subcategory"
                      options={optionsSubCategory}
                      value={idSubcategory}
                      disabled={(window.rs_permission_apl !== 'master' && props?.avulso ? true : false)}
                      onChange={handleSetSubcategory}
                    />
                  ) : (
                    <></>
                  )}

                  {((system.includes('224')) && !props?.id_group && (!props.chamados || (window.rs_id_lja > 0 && window.rs_id_lja && moduloChamadoVisible)) ?
                    <SelectReact
                      label="Módulo Chamados"
                      name="id_modulo_chamado"
                      options={optionsModuloChamados}
                      value={idModuloChamado}
                      onChange={(e) => setIdModuloChamado(e.value)}
                    />
                    : '')}

                  {(system.includes('225') && !props?.id_group ?
                    <SelectReact
                      label="Módulo Fases"
                      name="id_modulo_fase"
                      options={optionsModuloFases}
                      value={idModuloFase}
                      onChange={(e) => setIdModuloFase(e.value)}
                    />
                    : '')}

                  {/* SE FOR DO EMPREENDIMENTO FASTVIEW, NÃO FOR AVULSO (VISITAS) E NÃO SER CARD INTERNO */}
                  {(window.rs_id_emp == 26 && !props.avulso && !props?.id_group ?
                    <SelectReact
                      label="Cliente"
                      name="cliente"
                      options={optionsCliente}
                      value={cliente}
                      required={false}
                      onChange={(e) => setCliente(e.value)}
                    />
                    : '')}

                  {(props?.title !== false ?
                    <Input
                      type="text"
                      label="Título"
                      name="title"
                      disabled={(window.rs_permission_apl !== 'master' && props?.avulso ? true : false)}
                      value={title}
                      onChange={(e) => (setTitle(e.target.value))}
                    />
                    : '')}

                  <Textarea
                    name="description"
                    placeholder="Descrição"
                    editor={true}
                    value={description}
                    onChange={(e) => setDescription(e)}
                    required={(system.includes('229') || system.includes('231') ? true : false)}
                  />

                  {(!props.chamados && !props.fases && !props?.id_group && !props.avulso && props?.actions !== false ?
                    <CheckboxGroup
                      name="disable_job"
                      label="Desabilitar ações"
                      all={false}
                      items={optionsDisableJob}
                      value={disableJob}
                      callback={handleSetDisabledJob}
                      required={false}
                    />
                    : '')}

                  {(props?.attachment !== false ?
                    <Input
                      type="file"
                      label="Anexo"
                      value={anexo}
                      multiple={true}
                      required={(props?.attachmentRequired ? true : false)}
                      callback={handleSetAnexo}
                    />
                    : '')}

                  {/* O CAMPO ABAIXO SÓ É EXIBIDO SE ESTIVER EM CHAMADOS E O CHECKBOX DE AGENDAR ESTIVER MARCADO */}
                  {(props.chamados && agendar ?
                    <Input
                      type="date"
                      label="Data"
                      name="date_start"
                      value={dateStart}
                      valueStart={new Date()}
                      onChange={(e) => (setDateStart(e))}
                    />
                    :
                    ''
                  )}

                  {(microssistema ?
                    <>
                      <div className={style.separator}>
                        <span>Informações complementares</span>
                      </div>

                      <Microssistema
                        id={microssistema}
                        tipo="loja"
                        loja_id={(window.rs_id_lja && window.rs_id_lja > 0 ? window.rs_id_lja : '')}
                        relatorio_id={(props?.aux_form ? Number(JSON.parse(props?.aux_form).id_aux_form) : undefined)}
                        callback={handleMicrossistemaCallback}
                      />
                    </>
                    : '')}

                  <div className="d-flex align-items-center w-100 justify-content-between">
                    <div>
                      {(!props.empty && !props.plano ?
                        <Input
                          type="checkbox"
                          name="ativo"
                          id="ativo"
                          label="Ativo"
                          padding={false}
                          checked={ativo}
                          required={false}
                          className="me-2 mb-0"
                          onChange={() => setAtivo(!ativo)}
                        />
                        : '')}

                      {(props.chamados && props?.urgent !== false ?
                        <Input
                          type="checkbox"
                          name="urgente"
                          id="urgente"
                          label="Urgente"
                          padding={false}
                          checked={urgent}
                          required={false}
                          className="me-2 mb-0"
                          onChange={() => setUrgent(!urgent)}
                        />
                      : '')}

                      {/* {(props.chamados && props?.risk !== false ? 
                        <Input
                          type="checkbox"
                          name="risco"
                          id="risco"
                          label="Com risco"
                          padding={false}
                          checked={risk}
                          required={false}
                          className="me-2 mb-0"
                          onChange={() => setRisk(!risk)}
                        />
                      :'')} */}

                      {(props.chamados && props?.agendar !== false ?
                        <Input
                          type="checkbox"
                          name="agendar"
                          id="agendar"
                          label="Agendar"
                          padding={false}
                          checked={agendar}
                          required={false}
                          onChange={() => setAgendar(!agendar)}
                        />
                        : '')}
                    </div>

                    <Button
                      type="submit"
                      status={buttonState}
                      disabled={(validation ? false : true)}
                      title={alert}
                      float={false}
                    >
                      Salvar
                    </Button>
                  </div>
                </Form>

                {(!props.chamados && !props.fases && !props.visitas && !system.includes('229') && !system.includes('231') && !props?.id_group && !props?.avulso && props?.optional !== false ?
                  <>
                    <Title>Opcionais</Title>

                    <Form className="mb-4">
                      {/* <SelectReact
                        label="Prioridade"
                        name="id_priority"
                        options={optionsPriority}
                        value={idPriority}
                        onChange={(e) => setIdPriority(e.value)}
                        required={false}
                      /> */}

                      <Input
                        type="text"
                        label="URL Vídeo (Tutorial)"
                        name="url_video"
                        value={urlVideo}
                        onChange={(e) => setUrlVideo(e.target.value)}
                        required={false}
                      />

                      {(() => {
                        if (urlVideo) {
                          let link;
                          if (urlVideo.includes('shorts')) {
                            link = urlVideo.split('shorts/')[1];
                          } else if (urlVideo.includes('.be/')) {
                            link = urlVideo.split('.be/')[1];
                          } else {
                            link = urlVideo.split('?v=')[1];
                          }

                          if (urlVideo.includes('youtube') || urlVideo.includes('youtu.be')) {
                            return (
                              <iframe
                                className="d-block w-100"
                                style={{ height: 250, borderRadius: 4, marginTop: 10 }}
                                src={'https://www.youtube.com/embed/' + link.split('&t=')[0]}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowfullscreen
                              ></iframe>
                            )
                          }
                        }
                      })()}
                    </Form>
                  </>
                  : '')}

                {(!props.fases && !system.includes('225') && !system.includes('229') && !system.includes('231') && props?.integration !== false && (!window.rs_id_lja || window.rs_id_lja == 0)?
                  <>
                    <Title>Integração</Title>
                    <Form>
                      <SelectReact
                        label="Sistema"
                        name="id_job_system"
                        options={optionsJobSystem}
                        value={idJobSystem}
                        onChange={(e) => (
                          setIdJobSystem(e.value),
                          changeSystem(e.value),
                          setNomeIntegracao(e.label)
                        )}
                        required={false}
                      />
                      {idJobSystem ? (
                        <>
                          {(optionsJobSystemType.length > 0 ?
                            <SelectReact
                              label={(nomeIntegracao ? nomeIntegracao : 'Tipo')}
                              name="id_job_system_type"
                              options={optionsJobSystemType}
                              value={idJobSystemType}
                              onChange={(e) => (
                                setIdJobSystemType(e.value),
                                (idJobSystem == 18 ? changeSystemAux(18, e.value) : '') // TRADE
                              )}
                              required={true}
                            />
                            : '')}

                          {(idJobSystem == 1 && tipo == 'usuario' ? //CHECKLIT
                            <SelectReact
                              label="Loja"
                              name="id_job_system_type1"
                              options={optionsJobSystemType1}
                              value={idJobSystemType1}
                              onChange={(e) => (
                                setIdJobSystemType1(e.value)
                              )}
                              required={false}
                            />
                            : '')}
                        </>
                      ) : (
                        <></>
                      )}

                      {/* SISTEMA TRADE E TIPO DE TRADE = GRUPO */}
                      {idJobSystem == 18 & idJobSystemType == 1 ? (
                        <SelectReact
                          label="Grupo"
                          name="sistema_aux_1"
                          options={optionsJobSystemAux}
                          value={idJobSystemType1}
                          onChange={(e) => (
                            setIdJobSystemType1([e.value])
                          )}
                          required={false}
                        />
                      ) : (
                        <></>
                      )}

                      {/* SISTEMA TRADE E TIPO DE TRADE = INDÚSTRIA */}
                      {idJobSystem == 18 & idJobSystemType == 2 ? (
                        <>
                          <InputContainer display="block" label="Indústria" collapse={true}>
                            {(optionsJobSystemAux2.length > 0 ?
                              optionsJobSystemAux2.map((item, i) => {
                                return (
                                  <Input
                                    key={item.value}
                                    type="checkbox"
                                    label={item.label}
                                    id={item.value}
                                    value={item.value}
                                    checked={(idJobSystemType1.includes(item.value.toString()) ? true : null)}
                                    onChange={(e) => { handleSetIdJobSystemType1(e) }}
                                  />
                                )
                              })
                              : '')}
                          </InputContainer>

                          <InputContainer display="block" label="Produto" collapse={true}>
                            {(optionsJobSystemAux3.length > 0 ?
                              optionsJobSystemAux3.map((item, i) => {
                                return (
                                  <Input
                                    key={item.value}
                                    type="checkbox"
                                    label={item.label}
                                    id={item.value}
                                    value={item.value}
                                    checked={(idJobSystemType2.includes(item.value.toString()) ? true : null)}
                                    onChange={(e) => { handleSetIdJobSystemType2(e) }}
                                  />
                                )
                              })
                              : '')}
                          </InputContainer>
                        </>
                      ) : (
                        <></>
                      )}

                      {/* <SelectReact
                        required={false}
                        label="Plano de Ação"
                        name="job_action_plain"
                        options={optionsJobActionPlain}
                        value={jobActionPlain}
                        onChange={(e) => setJobActionPlain(e.value)}
                      /> */}

                      {(!props.visitas && !props.avulso ?
                        <SelectReact
                          required={false}
                          label="Job API"
                          name="job_api"
                          options={optionsJobApi}
                          value={idJobApi}
                          onChange={(e) => (
                            setIdJobApi(e.value),
                            setDisableJob(disableJob => [...disableJob, '1'])
                          )}
                        />
                        : '')}
                    </Form>
                  </>
                  : '')}
              </div>
            </Col>

            {(!props?.avulso && props?.boxUser !== false && (((window.rs_id_lja == 0 || !window.rs_id_lja) && props.chamados) || !props.chamados) ? // PARAMETRO DO VISITAS PARA CRIAR VISITAS AVULSAS OU SETANDO FALSE NO BOXUSER
              <Col>
                <Form>
                  <InputContainer
                    display="block"
                    maxHeight={leftCol?.current?.offsetHeight}
                  >
                    {(() => {
                      if (loadingUser) {
                        return <Loader show={loadingUser} />;
                      } else {
                        return (
                          <>
                            {(checkboxs.length > 0 ?
                              <>
                                <div className={'d-flex justify-content-between'}>
                                  {(!props.fases && !props.visitas && (!system.includes('225') && !system.includes('226')) ?
                                    <Input
                                      type="checkbox"
                                      name="select_all"
                                      id="select_all"
                                      label="Selecionar todos"
                                      value="select_all"
                                      className="pt-1 mb-0 ps-0"
                                      checked={(selectAll ? true : false)}
                                      onClick={checkAll}
                                      onChange={() => console.log('')}
                                    />
                                  :'')}

                                  {(tipo === 'loja' ?
                                    <div className={style.importer}>
                                      <Icon
                                        type="upload"
                                        title="Importar planilha de lojas"
                                        onClick={handleSetImporter}
                                        loading={loadImporter}
                                      />
                                    </div>
                                  :'')}
                                </div>

                                <Input
                                  type="text"
                                  name="filtro"
                                  placeholder="Buscar..."
                                  value={filterValue}
                                  onChange={(e) => (
                                    setFilter(e.target.value),
                                    setFilterValue(e.target.value)
                                  )}
                                  autocomplete="off"
                                  className="mb-2"
                                />

                                {checkboxs.filter((item) => {
                                  if (!filter) return true;
                                  if (item.label.toLowerCase().includes(filter.toLowerCase())) {
                                    return true;
                                  }
                                }).map((item, i) => {
                                  let checked;
                                  let hour;

                                  if (tipo == 'loja') {
                                    if (idStore.length > 0) {
                                      checked = idStore.filter((elem) => elem.id == item.value).length > 0;
                                    } else {
                                      checked = false;
                                    }

                                    if (idStore.length > 0) {
                                      hour = idStore.filter((elem) => elem.id == item.value)[0]?.hour_limit;
                                    } else {
                                      hour = '';
                                    }
                                  } else {
                                    if (idUsers.length > 0) {
                                      checked = idUsers.filter((elem) => elem.id == item.value).length > 0;
                                    } else {
                                      checked = false;
                                    }

                                    if (idUsers.length > 0) {
                                      hour = idUsers.filter((elem) => elem.id == item.value)[0]?.hour_limit;
                                    } else {
                                      hour = '';
                                    }
                                  }

                                  return (
                                    <CheckboxUser
                                      key={item.value}
                                      //type={( system.includes('224') || system.includes('225') || system.includes('226') ? 'radio' : 'checkbox')} // SE FOR CHAMADO, FASE OU VISITA VIRA RADIO BUTTON
                                      type={(system.includes('225') || system.includes('226') ? 'radio' : 'checkbox')} // SE FOR CHAMADO, FASE OU VISITA VIRA RADIO BUTTON
                                      name="id_user[]"
                                      emp={(optionsEmpreendimentos.length > 1 ? item?.nome_emp : '')}
                                      fullWidth={true}
                                      id={item.value}
                                      value={Number(item.value)}
                                      date={(system.includes('224') || system.includes('225') || system.includes('226') ? false : true)} // SE FOR CHAMADO, FASE OU VISITA DESATIVA O INPUT DE DATA PERSONALIZADA
                                      dateStart={dateStart}
                                      frequency={idFrequency}
                                      optionsHourLimit={optionsHourLimit}
                                      hourLimit={(hour ? hour : hourLimit)}
                                      label={item.label}
                                      required={true}
                                      className="p-0"
                                      checked={checked}
                                      onChange={handleCheck}
                                      onChangeDate={handleCheckDate}
                                      onChangeHour={handleCheckHour}
                                      height={leftCol?.current?.offsetHeight}
                                    />
                                  );
                                })
                                }
                              </>
                              :
                              <p>{'Nenhum' + (tipo == 'loja' ? 'a' : '') + ' ' + (tipo == 'loja' ? 'loja' : 'usuário') + ' disponível'}</p>
                            )}
                          </>
                        );
                      }
                    })()}
                  </InputContainer>
                </Form>
              </Col>
              : '')}

          </Row>
        </ModalBody>
      </Modal>

      {(() => {
        if (props.empty) {
          let title;

          if (props?.title) {
            title = props.title
          } else {
            if (props.chamados) {
              title = 'Novo chamado';
            } else if (props.fases) {
              title = 'Nova fase';
            } else if (props.visitas) {
              title = 'Nova visita';
            } else {
              title = 'Novo job';
            }
          }

          if (props?.format == 'box_home') {
            // return(
            //   <div className={'home_box_item'}>
            //       <a href="#" onClick={() => handleShowModal()}>
            //           <img src={icon_home} alt={'Solicitação de troco'} className={'home_box_item_icon'} />
            //           <span className={'home_box_item_txt'}>
            //               Solicitação de Desconto
            //           </span>
            //       </a>
            //   </div>
            // )
          } else {
            if (props.icon !== false) {
              if (props?.avulso) { // PARAMETRO DO VISITAS PARA CRIAR UMA VISITA AVULSA
                return (
                  <Tippy content={props?.cardTitle}>
                    <div onClick={() => handleShowModal()}>
                      <Card
                        title={(props?.cardTitle)}
                        size="smallest"
                        avulso={true}
                      />
                    </div>
                  </Tippy>
                )
              } else {
                return (
                  <Icon
                    type="new"
                    title={(props.disabled ? 'É preciso avaliar os chamados pendentes de avaliação no Pós-venda' : title)}
                    disabled={props.disabled}
                    onClick={() => (configuracoes[0]?.conf_atalho_categoria == 1 ? handleShowModalAtalhos() : handleShowModal())}
                  />
                );
              }
            }
          }
        } else {
          if (props.icon !== false) {
            let title;
            let icon;

            if (props?.disabledTitle) {
              title = props.disabledTitle;
            } else {
              if (props.disabled) {
                title = 'Editar (Cards já finalizados não serão afetados)';
              } else {
                if (props?.iconTitle) {
                  title = props?.iconTitle;
                } else {
                  title = 'Editar';
                }
              }
            }

            if (props?.icon) {
              icon = props?.icon;
            } else {
              icon = 'edit';
            }

            return (
              <Icon
                type={icon}
                title={title}
                // disabled={props.disabled}
                animated
                onClick={() => (setShowModal(true), editarJob(props.id, props.lote))}
              />
            );
          }
        }
      })()}
    </>
  );
}
