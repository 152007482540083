import { useContext } from "react";
import { GlobalContext } from "../../../../context/Global";
import style from '../card.module.scss';

import Title from "../../../../components/body/title";
import Editar from "../../../../components/body/card/editar";
import CardJobs from "../Card";
import Card from "../../../../components/body/card";
import { useState } from "react";

/*SWIPER*/
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "../../../../_assets/css/swiper.scss";
import { JobsContext } from "../../../../context/Jobs";
import { useEffect } from "react";
import axios from "axios";

// SORTABLE
import {
    DndContext,
    closestCorners,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
    useDroppable
} from "@dnd-kit/core";

import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import PageError from "../../../../components/body/pageError";
import Tippy from "@tippyjs/react";
import Icon from "../../../../components/body/icon";

export default function Chamados({jobs, jobsCols, widget, optionsModule, filters, reloadInternal, refreshCard, changeStatus, expand, createNew, refresh}){
    // CONTEXT GLOBAL
    const { loadingCards, filterModule, cardExternal, prevIndex, handleSetPrevIndex } = useContext(GlobalContext);

    // CONTEXT JOBS
    const { autoSwiper, handleSetAutoSwiper, filterEmpreendimento, configuracoes } = useContext(JobsContext);

    // ESTADOS
    const [swiper, setSwiper] = useState();
    const [iconAvaliacao, setIconAvaliacao] = useState([]);

    const handleReloadInternal = (e) => {
        reloadInternal(e);
    }

    const handleRefreshCard = (e) => {
        refreshCard(e);
    }

    const handleChangeStatus = (e) => {
        changeStatus(e);
    }

    // FUNÇÃO PARA BUSCAR TIPOS DE AVALIAÇÃO
    useEffect(() => {
        if(iconAvaliacao.length===0 && filterEmpreendimento){
        axios.get(window.host+'/systems/integration-react/api/list.php?do=get_assessment&empreendimento_id='+filterEmpreendimento+'&token='+window.token).then((response) => {
            setIconAvaliacao(response.data);
        });
        }
    },[filterEmpreendimento]);

    // SORTABLE
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        })
    );

    const [activeId, setActiveId] = useState();

    function findContainer(id) {
        if (id in jobs) {
            return id;
        }

        return Object.keys(jobs).find((key) => jobs[key].jobs.filter((elem) => elem.id === id).length > 0);  
    }

    function handleDragStart(event) {
        const { active } = event;
        const { id } = active;

        setActiveId(id);
    };

    function handleDragOver(event) {
        const { active, over, activatorEvent } = event;
        const overId = over?.id;

        if (overId == null || active.id in jobs) {
            return;
        }

        const { id } = active;

        // Find the containers
        const activeContainer = findContainer(id);
        const overContainer = findContainer(overId);

        if (!activeContainer || !overContainer || activeContainer === overContainer) {
            return;
        }

        // setJobs((prev) => {
        //     const activeItems = prev[activeContainer];
        //     const overItems = prev[overContainer];

        //     //Find the indexes for the jobs
        //     const activeIndex = activeItems.jobs.findIndex((elem) => elem.id == id);
            
        //     const overIndex = overItems.jobs.findIndex((elem) => elem.id == overId);

        //     let newIndex;
        //     if (overId in prev) {
        //         newIndex = overItems.length + 1;
        //     } else {
        //         const isBelowLastItem = over && overIndex === overItems.length - 1;
        //         // && draggingRect.offsetTop > over.rect.offsetTop + over.rect.height;        
        //         const modifier = isBelowLastItem ? 1 : 0;        
        //         newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
        //     }

        //     return {
        //         ...prev,

        //         [activeContainer]: {
        //             jobs:[
        //             ...prev[activeContainer].jobs.filter((item) => item.id !== active.id)
        //             ]
        //         },

        //         [overContainer]: {
        //             jobs: [
        //                 ...prev[overContainer].jobs.slice(0, newIndex),
        //                 jobs[activeContainer].jobs[activeIndex],
        //                 ...prev[overContainer].jobs.slice(newIndex, prev[overContainer].length)
        //             ]
        //         }
        //     };
        // });
    }

    function handleDragEnd(event) {
        const { active, over } = event;
        const { id } = active;
        const overId = over?.id;

        if (overId == null || active.id in jobs) {
            return;
        }

        const activeContainer = findContainer(id);
        const overContainer = findContainer(overId);

        if (!activeContainer || !overContainer || activeContainer !== overContainer) {
            return;
        }

        const activeIndex = jobs[activeContainer].jobs.findIndex((elem) => elem.id == active.id);
        const overIndex = jobs[overContainer].jobs.findIndex((elem) => elem.id == overId);

        if (activeIndex !== overIndex) {
            // setJobs((jobs) => ({
            //     ...jobs,
            //     [overContainer]: {
            //         jobs: arrayMove(jobs[overContainer].jobs, activeIndex, overIndex)
            //     }
            // }));
        }

        setActiveId(null);
    }; 

    // CALLBACK AO CLICAR EM EXPANDIR O CARD
    const handleExpandCallback = (e) => {
        expand?.callback();

        setTimeout(() => {
            swiper.slideTo(e?.index);

            if(prevIndex){
                swiper.slideTo(prevIndex);
                handleSetPrevIndex('');
            }

            let scrollElement = document.getElementById('card_'+e?.id);
            window.scrollTo({
                top: scrollElement.offsetTop + 42,
                behavior: 'smooth'
            });
        },100);
    }

    // CALLBACK DO CADASTRO PARA RECARREGAR A CONSULTA
    const handleReload = () => {
        if(refresh){
            refresh();
        }
    }

    return(
        <Swiper
            ref={swiper}
            focusableElements="input, select, div, button, label, option, textarea"
            preventClicks={false}
            simulateTouch={false}
            modules={[Navigation]}
            autoHeight={false}
            spaceBetween={24}
            onSwiper={(swiper) => setSwiper(swiper)}
            navigation={(widget || cardExternal.enabled ? false : true)}
            className={'calendar ' + (widget ? 'widget' : '')}
            allowTouchMove={true}
            slidesPerView={1}
            slidesPerGroup={1}
            watchSlidesProgress={true}
            breakpoints={{
              768: {
                allowTouchMove: false,
                slidesPerView: 'auto',
                slidesPerGroupAuto: true
              },
            }}
        >
            {(loadingCards ?
                [...Array(5)].map((card, i) => (
                    <SwiperSlide key={'empty_'+i}>
                    <Card
                        loading={true}
                        qtd={1}
                        widget={widget}
                    ></Card>
                    </SwiperSlide>
                ))
            :
                (optionsModule.length > 0 ?
                    (jobs ?
                        jobs?.filter((elem) => elem.tipo_fase !== 'Pós-venda').map((job, indexSlide) => {
                            let cards_length = 0;
                            return (
                                <SwiperSlide key={'swiper_'+indexSlide} className={(job.tipo_fase === 'Início' ? style.swiper_separator : '')}>
                                    {({ isVisible }) => (
                                        (isVisible ?
                                            <>
                                                {(job.tipo)}
                                                <Title
                                                    bold={true}
                                                    icon={
                                                        <>
                                                            {(job?.tipo_fase === 'Início' && window.window.rs_permission_apl !== 'leitura' ? // SE ESTIVER NA PRIMEIRA COLUNA (FILA)
                                                                <Editar
                                                                    chamados={true}
                                                                    empty={true}
                                                                    date={job?.date}
                                                                    id_emp={(job.id_emp?job.id_emp:'')}
                                                                    frequency={job?.id_frequency}
                                                                    disabled={(createNew ? false : true)}
                                                                    reload={handleReload}
                                                                />
                                                            :
                                                                <></>
                                                            )}
                                                        </>
                                                    }
                                                >
                                                    {job.title}
                                                </Title>
                                                
                                                {job.group.length>0 ? (                      
                                                    job.group?.map((group, i) => {
                                                        let cards = (group?.cards?.length>0 ? 
                                                                        group.cards.map((card, i) => {
                                                                            return(
                                                                                <CardJobs
                                                                                    key={'calendario_' + card?.id_job_status}
                                                                                    i={i}
                                                                                    indexSlide={indexSlide}
                                                                                    card={card}
                                                                                    group={group}
                                                                                    jobs={jobs}
                                                                                    job={job}         
                                                                                    loja={filters?.loja}
                                                                                    usuario={filters?.usuario}                                                 
                                                                                    chamados={true}
                                                                                    optionsModule={optionsModule}
                                                                                    iconAvaliacao={iconAvaliacao}
                                                                                    tipoCalendario={filters?.tipoCalendario}
                                                                                    subTipoCalendario={filters?.subTipoCalendario}
                                                                                    troca_operador={(window.rs_permission_apl === 'leitura' ? false : true)}
                                                                                    reloadInternal={reloadInternal}
                                                                                    refreshCard={handleRefreshCard}
                                                                                    changeStatus={handleChangeStatus}
                                                                                    widget={widget}
                                                                                    expand={{
                                                                                        callback: handleExpandCallback
                                                                                    }}
                                                                                />
                                                                            )
                                                                        })
                                                                    : 
                                                                        ((!group.title || (group.title && configuracoes[0]?.conf_operador_visivel !== 0)) ? // VERIFICA SE É FILA DE ALGUM OPERADOR
                                                                            <Card
                                                                                empty={true}
                                                                                title={(group.title?'Operador livre':'Nenhum chamado em fila')}  
                                                                                widget={widget}
                                                                            ></Card>
                                                                        :'')
                                                                    );

                                                        return(                          
                                                            <div key={'group_'+i}>
                                                                {(() => {
                                                                    if(group.title){
                                                                        if(group?.cards?.length > 0 || configuracoes[0]?.conf_operador_visivel !== 0){
                                                                            cards_length++;
                                                                            return(
                                                                                <>
                                                                                    <Title
                                                                                        key={'chamados_operator_'+i}
                                                                                        icon={
                                                                                            <Tippy content={'Operador '+(group.online==1 ? 'online' : 'offline')}>
                                                                                                <span className="small text-secondary">
                                                                                                    {(group.online==1?'On':'Off')}
                                                                                                </span>
                                                                                            </Tippy>
                                                                                        }
                                                                                    >
                                                                                        {group.title}
                                                                                    </Title>
                                                                                    {cards}
                                                                                </>
                                                                            )
                                                                        }
                                                                    }else{
                                                                        // VERIFICA SE EXISTE CHAMADOS EM PÓS VENDA / AVALIAÇÃO
                                                                        let avaliacao_aux = false;
                                                                        jobs.map((item, i) => {
                                                                            if(item.tipo_fase === 'Pós-venda'){
                                                                                item?.group.map((item2, i) => {
                                                                                    if(item2.cards.length > 0){
                                                                                        avaliacao_aux = true;
                                                                                    }
                                                                                })
                                                                            }
                                                                        })

                                                                        return (
                                                                            <>
                                                                                {cards}

                                                                                {(job?.tipo_fase === 'Início' && avaliacao_aux ? 
                                                                                    <>
                                                                                        <div className={style.rate_txt}>
                                                                                            <p>
                                                                                                Sua <span>Avaliação</span> é muito importante e pedimos que faça a avaliação clicando em um dos 4 botões abaixo:
                                                                                            </p>

                                                                                            <div className={style.rate_icons}>
                                                                                                <p>
                                                                                                    <Icon type="check" animated /> Bom
                                                                                                </p>
                                                                                                <p>
                                                                                                    <Icon type="ban" animated /> Médio
                                                                                                </p>
                                                                                                <p>
                                                                                                    <Icon type="reprovar2" animated /> Ruim
                                                                                                </p>
                                                                                                <p>
                                                                                                    <Icon type="reabrir" animated /> Reabrir
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>

                                                                                        {jobs?.filter((elem) => elem.tipo_fase === 'Pós-venda').map((job, i) => {
                                                                                            return (                
                                                                                                job.group?.map((group, i) => {
                                                                                                    return(
                                                                                                        group?.cards.map((card, i) => {
                                                                                                            return(
                                                                                                                <CardJobs
                                                                                                                    key={'calendario_' + card?.id_job_status}
                                                                                                                    i={i}
                                                                                                                    indexSlide={indexSlide}
                                                                                                                    card={card}
                                                                                                                    group={group}
                                                                                                                    jobs={jobs}
                                                                                                                    job={job}         
                                                                                                                    loja={filters?.loja}
                                                                                                                    usuario={filters?.usuario}                                                 
                                                                                                                    chamados={true}
                                                                                                                    optionsModule={optionsModule}
                                                                                                                    iconAvaliacao={iconAvaliacao}
                                                                                                                    tipoCalendario={filters?.tipoCalendario}
                                                                                                                    subTipoCalendario={filters?.subTipoCalendario}
                                                                                                                    troca_operador={(window.rs_permission_apl === 'leitura' ? false : true)}
                                                                                                                    reloadInternal={reloadInternal}
                                                                                                                    refreshCard={handleRefreshCard}
                                                                                                                    changeStatus={handleChangeStatus}
                                                                                                                    widget={widget}
                                                                                                                    expand={{
                                                                                                                        callback: handleExpandCallback
                                                                                                                    }}
                                                                                                                />
                                                                                                            )
                                                                                                        }) 
                                                                                                    )
                                                                                                })
                                                                                            )
                                                                                        })}
                                                                                    </>
                                                                                :'')}
                                                                            </>
                                                                        )
                                                                    }                                
                                                                })()}
                                                            </div>
                                                        )
                                                    })
                                                ) : (
                                                    <Card
                                                        empty={true}
                                                        title={'Nenhum chamado em fila'}
                                                        widget={widget}
                                                    ></Card>
                                                )}     

                                                {/* VERIFICA SE NA FILA DE OPERAÇÃO TEM ALGUM CARD EM ALGUM OPERADOR PARA PODER COLOCAR O AVISO DE NENHUM CHAMADO EM FILA */}
                                                {(job.tipo_fase === 'Operação' && (cards_length === 0 && configuracoes[0]?.conf_operador_visivel === 0) && job.group.length > 0 ?
                                                    <Card
                                                        empty={true}
                                                        title={'Nenhum chamado em fila'}
                                                        widget={widget}
                                                    ></Card>
                                                :'')}
                                            </>
                                        :'')
                                    )}
                                </SwiperSlide>
                            )
                        })
                    :
                        jobsCols?.sort((a, b) => a.id_module - b.id_module).map((job, indexSlide) => { // SORT PARA REORDENAR DE ACORDO COM O ID
                            return (
                                <SwiperSlide key={'swiper_'+indexSlide} className={(job.tipo_fase === 'Início' ? style.swiper_separator : '')}>
                                    {({ isVisible }) => (
                                        (isVisible ?
                                            <>
                                                <Title
                                                    bold={true}
                                                    icon={
                                                        <>
                                                            {(window.window.rs_permission_apl !== 'leitura' ? // SE ESTIVER NA PRIMEIRA COLUNA (FILA) E TIVER PERMISSÃO DIFERENTE DE "SOMENTE LEITURA"
                                                                <Editar
                                                                    chamados={true}
                                                                    empty={true}
                                                                    disabled={(createNew ? false : true)}
                                                                    reload={handleReload}
                                                                />
                                                            :
                                                                <></>
                                                            )}
                                                        </>
                                                    }
                                                >
                                                    {job?.module}
                                                </Title>

                                                {job?.data.filter((elem) => elem.tipo_fase !== 'Pós-venda').map((col, i) => {
                                                    let cards_length = 0;

                                                    return(
                                                        <div key={'col_'+i}>
                                                            <div className={style.separator}>
                                                                <span>{col?.title}</span>
                                                            </div>

                                                            {col?.group?.length > 0 ? (                      
                                                                col.group?.map((group, i) => {
                                                                    let cards = (group?.cards?.length>0 ? 
                                                                                    group.cards.map((card, i) => {
                                                                                        return(
                                                                                            <CardJobs
                                                                                                key={'calendario_' + card?.id_job_status}
                                                                                                i={i}
                                                                                                indexSlide={indexSlide}
                                                                                                card={card}
                                                                                                group={group}
                                                                                                jobs={jobs}
                                                                                                job={col}         
                                                                                                loja={filters?.loja}
                                                                                                usuario={filters?.usuario}                                                 
                                                                                                chamados={true}
                                                                                                optionsModule={optionsModule}
                                                                                                iconAvaliacao={iconAvaliacao}
                                                                                                tipoCalendario={filters?.tipoCalendario}
                                                                                                subTipoCalendario={filters?.subTipoCalendario}
                                                                                                troca_operador={(window.rs_permission_apl === 'leitura' ? false : true)}
                                                                                                reloadInternal={reloadInternal}
                                                                                                refreshCard={handleRefreshCard}
                                                                                                changeStatus={handleChangeStatus}
                                                                                                widget={widget}
                                                                                                expand={{
                                                                                                    callback: handleExpandCallback
                                                                                                }}
                                                                                            />
                                                                                        )
                                                                                    })
                                                                                : 
                                                                                    ((!group.title || (group.title && configuracoes[0]?.conf_operador_visivel !== 0)) ? // VERIFICA SE É FILA DE ALGUM OPERADOR
                                                                                        <Card
                                                                                            empty={true}
                                                                                            title={(group.title?'Operador livre':'Nenhum chamado em fila')}  
                                                                                            widget={widget}
                                                                                        ></Card>
                                                                                    :'')
                                                                                );

                                                                    return(                          
                                                                        <div key={'group_'+i}>
                                                                            {(() => {
                                                                                if(group.title){
                                                                                    if(group?.cards?.length > 0 || configuracoes[0]?.conf_operador_visivel !== 0){
                                                                                        cards_length++;
                                                                                        return(
                                                                                            <>
                                                                                                <Title
                                                                                                    key={'chamados_operator_'+i}
                                                                                                    icon={
                                                                                                        <Tippy content={'Operador '+(group.online==1 ? 'online' : 'offline')}>
                                                                                                            <span className="small text-secondary">
                                                                                                                {(group.online==1?'On':'Off')}
                                                                                                            </span>
                                                                                                        </Tippy>
                                                                                                    }
                                                                                                >
                                                                                                    {group.title}
                                                                                                </Title>
                                                                                                {cards}
                                                                                            </>
                                                                                        )
                                                                                    }
                                                                                }else{
                                                                                    // VERIFICA SE EXISTE CHAMADOS EM PÓS VENDA / AVALIAÇÃO
                                                                                        let avaliacao_aux = false;
                                                                                        job?.data.map((item, i) => {
                                                                                            if(item.tipo_fase === 'Pós-venda'){
                                                                                                item?.group.map((item2, i) => {
                                                                                                    if(item2.cards.length > 0){
                                                                                                        avaliacao_aux = true;
                                                                                                    }
                                                                                                })
                                                                                            }
                                                                                        })

                                                                                        return (
                                                                                            <>
                                                                                                {cards}

                                                                                                {(avaliacao_aux ? 
                                                                                                    <>
                                                                                                        <div className={style.separator}>
                                                                                                            <span>Avaliação</span>
                                                                                                        </div>

                                                                                                        <div className={style.rate_txt}>
                                                                                                            <p>
                                                                                                                Sua <span>Avaliação</span> é muito importante e pedimos que faça a avaliação clicando em um dos 4 botões abaixo:
                                                                                                            </p>

                                                                                                            <div className={style.rate_icons}>
                                                                                                                <p>
                                                                                                                    <Icon type="check" animated /> Bom
                                                                                                                </p>
                                                                                                                <p>
                                                                                                                    <Icon type="ban" animated /> Médio
                                                                                                                </p>
                                                                                                                <p>
                                                                                                                    <Icon type="reprovar2" animated /> Ruim
                                                                                                                </p>
                                                                                                                <p>
                                                                                                                    <Icon type="reabrir" animated /> Reabrir
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        {job?.data?.filter((elem) => elem.tipo_fase === 'Pós-venda').map((job, i) => {
                                                                                                            return (                
                                                                                                                job.group?.map((group, i) => {
                                                                                                                    return(
                                                                                                                        group?.cards.map((card, i) => {
                                                                                                                            return(
                                                                                                                                <CardJobs
                                                                                                                                    key={'calendario_' + card?.id_job_status}
                                                                                                                                    i={i}
                                                                                                                                    indexSlide={indexSlide}
                                                                                                                                    card={card}
                                                                                                                                    group={group}
                                                                                                                                    jobs={job?.data}
                                                                                                                                    job={job}         
                                                                                                                                    loja={filters?.loja}
                                                                                                                                    usuario={filters?.usuario}                                                 
                                                                                                                                    chamados={true}
                                                                                                                                    optionsModule={optionsModule}
                                                                                                                                    iconAvaliacao={iconAvaliacao}
                                                                                                                                    tipoCalendario={filters?.tipoCalendario}
                                                                                                                                    subTipoCalendario={filters?.subTipoCalendario}
                                                                                                                                    troca_operador={(window.rs_permission_apl === 'leitura' ? false : true)}
                                                                                                                                    reloadInternal={reloadInternal}
                                                                                                                                    refreshCard={handleRefreshCard}
                                                                                                                                    changeStatus={handleChangeStatus}
                                                                                                                                    widget={widget}
                                                                                                                                    expand={{
                                                                                                                                        callback: handleExpandCallback
                                                                                                                                    }}
                                                                                                                                />
                                                                                                                            )
                                                                                                                        }) 
                                                                                                                    )
                                                                                                                })
                                                                                                            )
                                                                                                        })}
                                                                                                    </>
                                                                                                :'')}
                                                                                            </>
                                                                                        )
                                                                                }                                
                                                                            })()}
                                                                        </div>
                                                                    )
                                                                })
                                                            ) : (
                                                                <Card
                                                                    empty={true}
                                                                    title={'Nenhum chamado em fila'}
                                                                    widget={widget}
                                                                ></Card>
                                                            )}     

                                                            {/* VERIFICA SE NA FILA DE OPERAÇÃO TEM ALGUM CARD EM ALGUM OPERADOR PARA PODER COLOCAR O AVISO DE NENHUM CHAMADO EM FILA */}
                                                            {(col.tipo_fase === 'Operação' && (cards_length === 0 && configuracoes[0]?.conf_operador_visivel === 0) && col.group.length > 0 ?
                                                                <Card
                                                                    empty={true}
                                                                    title={'Nenhum chamado em fila'}
                                                                    widget={widget}
                                                                ></Card>
                                                            :'')}
                                                        </div>
                                                    )
                                                })}
                                            </>
                                        :'')
                                    )}
                                </SwiperSlide>
                            )
                        })
                    )
                :
                    <PageError
                        title="Módulo não configurado"
                    />
                )
            )}
        </Swiper>
    )
}