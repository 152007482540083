import React, { useMemo, useCallback, createContext, useState } from "react";

export const ChecklistContext = createContext();

export const ChecklistProvider = ({ children }) => {
  const [view, setView] = useState(1);
  const [clicked, setClicked] = useState(false);
  const [editView, setEditView] = useState(false);
  const [filterSupervisao, setFilterSupervisao] = useState('');
  const [filterDate, setFilterDate] = useState(new Date(window.currentYear,window.currentMonth-1,window.currentDay));
  const [filterDateMonth, setFilterDateMonth] = useState(new Date(window.currentYear,window.currentMonth-1,window.currentDay));
  const [filterEmpreendimento, setFilterEmpreendimento] = useState([]);
  const [filterLoja, setFilterLoja] = useState([]);
  const [filterStatus, setFilterStatus] = useState([]);
  const [filterStatusSupervisor, setFilterStatusSupervisor] = useState([]);
  const [pageError, setPageError] = useState(false);
  const [optionsSupervisao, setOptionsSupervisao] = useState([]);
  const [filterCategory, setFilterCategory] = useState([]);
  const [filterSubCategory, setFilterSubCategory] = useState([]);

  // TROCA TELA DE VISUALIZAÇÃO
  const handleSetView = useCallback((value) => {
    setView(value);
  }, [view]);

  // SETA CLIQUE
  const handleSetClicked = useCallback((value) => {
    setClicked(value);
  }, [clicked]);

  // EDIÇÃO
  const handleSetEditView = useCallback((value) => {
    setEditView(value);
  }, [editView]);

  // FILTRO DE SUPERVISÃO
  const handleSetFilterSupervisao = useCallback((value) => {
    setFilterSupervisao(value);
  }, [filterSupervisao]);

  // FILTRO DE DATA
  const handleSetFilterDate = useCallback((value) => {
    setFilterDate(value);
  },[filterDate]);

  // FILTRO DE DATA MÊS
  const handleSetFilterDateMonth = useCallback((value) => {
    setFilterDateMonth(value);
  },[filterDateMonth]);

  // FILTRO DE EMPREENDIMENTO
  const handleSetFilterEmpreendimento = useCallback((value) => {
    setFilterEmpreendimento(value);
  },[filterEmpreendimento]);

  // FILTRO DE LOJA
  const handleSetFilterLoja = useCallback((value) => {
    setFilterLoja(value);
  },[filterLoja]);

  // FILTRO DE STATUS
  const handleSetFilterStatus = useCallback((value) => {
    setFilterStatus(value);
  },[filterStatus]);

  // FILTRO DE STATUS SUPERVISOR
  const handleSetFilterStatusSupervisor = useCallback((value) => {
    setFilterStatusSupervisor(value);
  },[filterStatusSupervisor]);

  // SETA PÁGINA COM ERRO
  const handleSetPageError = useCallback((value) => {
    setPageError(value);
  },[pageError]);

  // OPTIONS SUPERVISÃO
  const handleSetOptionsSupervisao = useCallback((value) => {
    setOptionsSupervisao(value);
  },[optionsSupervisao]);

  //FILTRO CATEGORIA
  const handleSetFilterCategory = useCallback((value) => {
    setFilterCategory(value);
  },[filterCategory]);

  //FILTRO SUBCATEGORIA
  const handleSetFilterSubCategory = useCallback((value)=>{
    setFilterSubCategory(value);
  },[filterSubCategory])

  const value = useMemo(
    () => ({
      view,
      handleSetView,
      clicked,
      handleSetClicked,
      editView,
      handleSetEditView,
      filterSupervisao,
      handleSetFilterSupervisao,
      filterDate,
      handleSetFilterDate,
      filterDateMonth,
      handleSetFilterDateMonth,
      filterEmpreendimento,
      handleSetFilterEmpreendimento,
      filterLoja,
      handleSetFilterLoja,
      pageError,
      handleSetPageError,
      filterStatus,
      handleSetFilterStatus,
      filterStatusSupervisor,
      handleSetFilterStatusSupervisor,
      optionsSupervisao,
      handleSetOptionsSupervisao,
      filterCategory,
      handleSetFilterCategory,
      filterSubCategory,
      handleSetFilterSubCategory
    }),
    [
      view,
      handleSetView,
      clicked,
      handleSetClicked,
      editView,
      handleSetEditView,
      filterSupervisao,
      handleSetFilterSupervisao,
      filterDate,
      handleSetFilterDate,
      filterDateMonth,
      handleSetFilterDateMonth,
      filterEmpreendimento,
      handleSetFilterEmpreendimento,
      filterLoja,
      handleSetFilterLoja,
      pageError,
      handleSetPageError,
      filterStatus,
      handleSetFilterStatus,
      filterStatusSupervisor,
      handleSetFilterStatusSupervisor,
      optionsSupervisao,
      handleSetOptionsSupervisao,
      filterCategory,
      handleSetFilterCategory,
      filterSubCategory,
      handleSetFilterSubCategory
    ]
  );

  return (
    <ChecklistContext.Provider value={value}>{children}</ChecklistContext.Provider>
  );
};
