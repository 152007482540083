import { useEffect, useState } from "react";
import Table from "../../../components/body/table";
import Tbody from "../../../components/body/table/tbody";
import Td from "../../../components/body/table/tbody/td";
import Thead from "../../../components/body/table/thead";
import Th from "../../../components/body/table/thead/th";
import Tr from "../../../components/body/table/tr";
import SelectReact from "../../../components/body/select";
import FilterCheckbox from "../../../components/body/filterCheckbox";
import Input from "../../../components/body/form/input";
import "./style.css";
import Icon from "../../../components/body/icon";
import { groupBy } from "lodash";

import TemperaturaTable from "./tempheader";
import axios from "axios";

export default function Lista({ filters, icons }) {

    //ESTADOS
    const [lista, setLista] = useState([]);
    const [rowCounter, setRowCounter] = useState();

    const [filter, setFilter] = useState(1);
    const [loja, setLoja] = useState('');
    const [categoria, setCategoria] = useState(1);
    const [nome, setNome] = useState('');
    const [date, setDate] = useState(new Date());
    const [logo, setlogo] = useState('');
    const [reload, setReload] = useState(false);
    const [tipos, setTipos] = useState();
    const [hide, setHide] = useState(true);

    //FILTROS
    const handleFilterLoja = (e) => {
        setLoja(e)
    }
    const handleFilterCategoria = (e) => {
        setCategoria(e.value)
    }
    const handleFilterNome = (e) => {
        setNome(e)
    }
    const handleFilterDate = (e) => {
        setDate(e)
    }
    //GET DATA DA TABLE
    const handleSetItems = (e) => {
        let data = groupBy(e, 'periodo_nome')
        setLista(data)
        setRowCounter(e)
    }

    //thead1
    const thead = [
        { enabled: true, label: "Manhã", colspan: filter === 1 ? 9 : 7, align: 'center', className: "controle-tem-ultima" },
        { enabled: true, label: "Tarde", colspan: 6, align: 'center', className: "controle-tem-ultima" },
        { enabled: (!hide ? true : false), label: "Tarde", colspan: 6, align: 'center', className: "controle-tem-ultima" },
        { enabled: true, label: "Noite", colspan: 6, align: 'center', className: "controle-tem-ultima" },
    ]
    //thead2
    const thead2 = [
        { enabled: true, label: 'Data', id: 'data', name: 'data', type: 'date', filter: false },
        { enabled: (filter === 1 ? true : false), label: 'Loja', id: 'loja', name: 'loja', filter: false },
        { enabled: (filter === 1 ? true : false), label: 'Categoria', id: 'categoria', name: 'categoria', filter: false },
        { enabled: true, label: 'Horário', id: 'horario', name: 'horario', filter: false },
        { enabled: true, label: 'Mínimo', id: 'minimo', name: 'minimo', filter: false },
        { enabled: true, label: 'Máximo', id: 'maximo', name: 'maximo', filter: false },
        { enabled: true, label: 'Momento', id: 'momento', name: 'momento', filter: false },
        { enabled: true, label: 'Umidade', id: 'umidade', name: 'umidade', filter: false },
        { enabled: true, label: 'Responsável', id: 'responsavel', name: 'responsavel', className: "controle-tem-ultima", filter: false },

        { enabled: true, label: 'Horário', id: 'horario', name: 'horario', filter: false },
        { enabled: true, label: 'Mínimo', id: 'minimo', name: 'minimo', filter: false },
        { enabled: true, label: 'Máximo', id: 'maximo', name: 'maximo', filter: false },
        { enabled: true, label: 'Momento', id: 'momento', name: 'momento', filter: false },
        { enabled: true, label: 'Umidade', id: 'umidade', name: 'umidade', filter: false },
        { enabled: true, label: 'Responsável', id: 'responsavel', name: 'responsavel', className: "controle-tem-ultima", filter: false },

        { enabled: (!hide ? true : false), label: 'Horário', id: 'horario', name: 'horario', filter: false },
        { enabled: (!hide ? true : false), label: 'Mínimo', id: 'minimo', name: 'minimo', filter: false },
        { enabled: (!hide ? true : false), label: 'Máximo', id: 'maximo', name: 'maximo', filter: false },
        { enabled: (!hide ? true : false), label: 'Momento', id: 'momento', name: 'momento', filter: false },
        { enabled: (!hide ? true : false), label: 'Umidade', id: 'umidade', name: 'umidade', filter: false },
        { enabled: (!hide ? true : false), label: 'Responsável', id: 'responsavel', name: 'responsavel', className: "controle-tem-ultima", filter: false },

        { enabled: true, label: 'Horário', id: 'horario', name: 'horario', filter: false },
        { enabled: true, label: 'Mínimo', id: 'minimo', name: 'minimo', filter: false },
        { enabled: true, label: 'Máximo', id: 'maximo', name: 'maximo', filter: false },
        { enabled: true, label: 'Momento', id: 'momento', name: 'momento', filter: false },
        { enabled: true, label: 'Umidade', id: 'umidade', name: 'umidade', filter: false },
        { enabled: true, label: 'Responsável', id: 'responsavel', name: 'responsavel', className: "controle-tem-ultima", filter: false },
    ]

    // TITLES EXPORT
    let thead_export = {};
    thead2.map((item, i) => {
        if (item?.export !== false) {
            thead_export[item?.name] = item?.label;
        }
    })

    // URL API TABLE
    const url = window.host + '/systems/controle-temperatura/api/lista.php';

    const params = {
        do: 'get',
        token: window.token,
        data_inicio: '',
        data_fim: '',
        // controle_tipo_id: loja
    }

    // BODY DO EXPORTADOR   
    const body = {
        titles: thead_export,
        url: url,
        name: "Controle de Temperatura",
        filters: params,
        orientation: 'L',
        key: 'data'
    }

    // BUSCAR LOGO EMPREENDIMENTO
    useEffect(() => {
        if (!logo || logo === undefined) {
            axios({
                method: 'get',
                url: window.host + '/api/sql.php?do=select&component=empreendimento',
                params: {
                    token: window.token,
                    filter_search_id: window.rs_id_emp
                }
            }).then((response) => {
                if (response?.data[0]?.logo) {
                    setlogo(response?.data[0]?.logo);
                } else {
                    setlogo(undefined);
                }
            });
        }
    }, []);


    //GET TIPOS
    function getTipos() {
        axios.get(window.host + '/systems/controle-temperatura/api/gerenciador.php?do=getControleTipo&token=' + window.token)
            .then((response) => {
                setTipos(response.data)
            })
    }
    useEffect(() => {
        getTipos()
    }, []);

    useEffect(() => {
        if (icons) {
            icons(
                <>
                    <Icon type="excel" api={{ body: body }} />
                    <Icon type="print" />
                    <Icon type="expandir" expanded={!hide} onClick={() => { setHide(!hide) }} />
                </>
            )
        }
        if (filters) {
            filters(
                <>
                    <SelectReact
                        value={filter}
                        onChange={(e) => (setFilter(e.value), setCategoria(e.value == 2 ? 1 : ''))}
                        options={[{ value: 1, label: "Loja" }, { value: 2, label: "Ambiente" }]}
                    />
                    {
                        filter == 1 ?
                            <FilterCheckbox
                                name="filter_store"
                                api={window.host + "/api/sql.php?do=select&component=loja&np=true&filial=true&limit=false&token=" + window.token}
                                onChangeClose={handleFilterLoja}
                            >
                                Loja
                            </FilterCheckbox>
                            :
                            <SelectReact
                                placeholder="Categoria"
                                name="filter_category"
                                // api={window.host + "/systems/integration-react/api/registry.php?do=get_category&grupo_id=true&empreendimento_id=" + '' + "&filter_id_module=" + ('') + "&filter_id_apl_default=0&token=" + window.token}
                                onChange={handleFilterCategoria}
                                value={categoria}
                                options={tipos}
                            />
                    }

                    <FilterCheckbox
                        name="filter_nome"
                        // api={window.host + "/systems/integration-react/api/registry.php?do=get_category&grupo_id=true&empreendimento_id=" + filterEmpreendimento + "&filter_id_module=" + (filterModule == undefined ? '' : filterModule) + "&filter_id_apl_default=0&token=" + window.token}
                        onChangeClose={handleFilterNome}
                        value={nome}
                        reload={nome}
                    >
                        Nome
                    </FilterCheckbox>
                    <Input
                        type="date"
                        icon={false}
                        required={false}
                        value={date}
                        onChange={(e) => handleFilterDate(e)}
                    />
                </>
            )
        }
        if (filter === 1) {
            setCategoria('')
        }

    }, [loja, nome, categoria, date, filter, hide]);

    let rows

    function count(num) {
        let rows = []
        for (let j = num + 1; j <= 31; j++) {
            rows.push(j);
        }
        return rows
    }

    if (categoria) {
        rows = count(lista?.P1?.length)
    }
    return (
        <>
            <TemperaturaTable
                logo={(logo ? process.env.REACT_APP_URL_UPLOAD + '/' + logo : '')}
                categoria={"Ambiente"}
                nome={"Depósito medicamentos"}
                razao_social={"SEROMA FARMÁCIAS E PERFUMES LTDA"}
                mes={"Setembro"}
                ano={"2023"}
                temperatura={"Temperatura: 15 - 30°C"}
                umidade={"Umidade: 45 - 70%"}
            >
                <Table
                    thead={thead}
                    thead2={thead2}
                    api={url}
                    params={params}
                    onLoad={handleSetItems}
                >
                    <Tbody>
                        {
                            lista ?
                                <>
                                    <Tr>
                                        {

                                            lista?.P1?.length > 0 ?
                                                lista?.P1?.map((item, i) => {
                                                    let color;
                                                    if (Number(item.umidade) < 45 || Number(item.umidade) > 75) {
                                                        color = "text-danger"
                                                    }
                                                    let data_aux = item?.data.split(' ')
                                                    return (
                                                        <>
                                                            <Td>
                                                                {categoria ? i + 1 : data_aux[0]}
                                                            </Td>
                                                            <Td hide={categoria ? true : false} >
                                                                {item.loja}
                                                            </Td>
                                                            <Td hide={categoria ? true : false}>
                                                                {item.controle_temperatura_id}
                                                            </Td>
                                                            <Td>
                                                                {data_aux[1]}
                                                            </Td>
                                                            <Td>
                                                                {`${item.temperatura_minima}°C`}
                                                            </Td>
                                                            <Td>
                                                                {`${item.temperatura_maxima}°C`}
                                                            </Td>
                                                            <Td>
                                                                {`${item.temperatura}°C`}
                                                            </Td>
                                                            <Td className={color}>
                                                                {`${item.umidade}%`}
                                                            </Td>
                                                            <Td className="controle-tem-ultima">
                                                                {item.usuario_nome}
                                                            </Td>
                                                        </>
                                                    )
                                                })
                                                :
                                                <>
                                                    <Td>
                                                        -
                                                    </Td>
                                                    <Td hide={categoria ? true : false} >
                                                        -
                                                    </Td>
                                                    <Td hide={categoria ? true : false}>
                                                        -
                                                    </Td>
                                                    <Td>
                                                        -
                                                    </Td>
                                                    <Td>
                                                        -
                                                    </Td>
                                                    <Td>
                                                        -
                                                    </Td>
                                                    <Td>
                                                        -
                                                    </Td>
                                                    <Td >
                                                        -
                                                    </Td>
                                                    <Td className="controle-tem-ultima">
                                                        -
                                                    </Td>
                                                </>
                                        }
                                        {
                                            lista?.P2?.length > 0 ?
                                                lista?.P2?.map((item) => {
                                                    let color;
                                                    if (Number(item.umidade) < 45 || Number(item.umidade) > 75) {
                                                        color = "text-danger"
                                                    }
                                                    let data_aux = item?.data.split(' ')
                                                    return (
                                                        <>
                                                            <Td>
                                                                {data_aux[1]}
                                                            </Td>
                                                            <Td>
                                                                {`${item.temperatura_minima}°C`}
                                                            </Td>
                                                            <Td>
                                                                {`${item.temperatura_maxima}°C`}
                                                            </Td>
                                                            <Td>
                                                                {`${item.temperatura}°C`}
                                                            </Td>
                                                            <Td className={color}>
                                                                {`${item.umidade}%`}
                                                            </Td>
                                                            <Td className="controle-tem-ultima">
                                                                {item.usuario_nome}
                                                            </Td>
                                                        </>
                                                    )
                                                })
                                                :
                                                <>
                                                    <Td>
                                                        -
                                                    </Td>
                                                    <Td>
                                                        -
                                                    </Td>
                                                    <Td>
                                                        -
                                                    </Td>
                                                    <Td>
                                                        -
                                                    </Td>
                                                    <Td>
                                                        -
                                                    </Td>
                                                    <Td className="controle-tem-ultima">
                                                        -
                                                    </Td>
                                                </>
                                        }
                                        {
                                            !hide ?
                                                lista?.P3?.length > 0 ?
                                                    lista?.P3?.map((item) => {
                                                        let color;
                                                        if (Number(item.umidade) < 45 || Number(item.umidade) > 75) {
                                                            color = "text-danger"
                                                        }
                                                        let data_aux = item?.data.split(' ')
                                                        return (
                                                            <>
                                                                <Td>
                                                                    {data_aux[1]}
                                                                </Td>
                                                                <Td>
                                                                    {`${item.temperatura_minima}°C`}
                                                                </Td>
                                                                <Td>
                                                                    {`${item.temperatura_maxima}°C`}
                                                                </Td>
                                                                <Td>
                                                                    {`${item.temperatura}°C`}
                                                                </Td>
                                                                <Td className={color}>
                                                                    {`${item.umidade}%`}
                                                                </Td>
                                                                <Td className="controle-tem-ultima">
                                                                    {item.usuario_nome}
                                                                </Td>
                                                            </>
                                                        )
                                                    })
                                                    :
                                                    <>
                                                        <Td>
                                                            -
                                                        </Td>
                                                        <Td>
                                                            -
                                                        </Td>
                                                        <Td>
                                                            -
                                                        </Td>
                                                        <Td>
                                                            -
                                                        </Td>
                                                        <Td >
                                                            -
                                                        </Td>
                                                        <Td className="controle-tem-ultima">
                                                            -
                                                        </Td>
                                                    </>
                                                : <></>
                                        }
                                        {
                                            lista?.P4?.length > 0 ?
                                                lista?.P4?.map((item) => {
                                                    let color;
                                                    if (Number(item.umidade) < 45 || Number(item.umidade) > 75) {
                                                        color = "text-danger"
                                                    }
                                                    let data_aux = item?.data.split(' ')
                                                    return (
                                                        <>
                                                            <Td>
                                                                {data_aux[1]}
                                                            </Td>
                                                            <Td>
                                                                {`${item.temperatura_minima}°C`}
                                                            </Td>
                                                            <Td>
                                                                {`${item.temperatura_maxima}°C`}
                                                            </Td>
                                                            <Td>
                                                                {`${item.temperatura}°C`}
                                                            </Td>
                                                            <Td className={color}>
                                                                {`${item.umidade}%`}
                                                            </Td>
                                                            <Td className="controle-tem-ultima">
                                                                {item.usuario_nome}
                                                            </Td>
                                                        </>
                                                    )
                                                })
                                                :
                                                <>
                                                    <Td>
                                                        -
                                                    </Td>
                                                    <Td>
                                                        -
                                                    </Td>
                                                    <Td>
                                                        -
                                                    </Td>
                                                    <Td>
                                                        -
                                                    </Td>
                                                    <Td >
                                                        -
                                                    </Td>
                                                    <Td className="controle-tem-ultima">
                                                        -
                                                    </Td>
                                                </>
                                        }
                                    </Tr>
                                    {
                                        categoria &&
                                        rows &&
                                        rows.map((item, i) => {
                                            return (
                                                <Tr key={i}>
                                                    <Td>{item}</Td>
                                                    <Td>-</Td>
                                                    <Td>-</Td>
                                                    <Td>-</Td>
                                                    <Td>-</Td>
                                                    <Td>-</Td>
                                                    <Td className="controle-tem-ultima">-</Td>
                                                    <Td>-</Td>
                                                    <Td>-</Td>
                                                    <Td>-</Td>
                                                    <Td>-</Td>
                                                    <Td>-</Td>
                                                    <Td className="controle-tem-ultima">-</Td>
                                                    <Td hide={hide}>-</Td>
                                                    <Td hide={hide}>-</Td>
                                                    <Td hide={hide}>-</Td>
                                                    <Td hide={hide}>-</Td>
                                                    <Td hide={hide}>-</Td>
                                                    <Td hide={hide} className="controle-tem-ultima">-</Td>
                                                    <Td>-</Td>
                                                    <Td>-</Td>
                                                    <Td>-</Td>
                                                    <Td>-</Td>
                                                    <Td>-</Td>
                                                    <Td className="controle-tem-ultima">-</Td>
                                                </Tr>
                                            )
                                        })
                                    }
                                </>
                            :<></>
                        }

                    </Tbody>
                </Table>
            </TemperaturaTable>
        </>
    )
}