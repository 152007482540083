import { useState, useContext, useEffect } from "react";
import SelectReact from "../../select";
import axios from "axios";
import { toast } from "react-hot-toast";
import { JobsContext } from "../../../../context/Jobs";

export default function TrocaLoja({ label, placeholder, options, margin, params, filterModule, onChange, menuPlacement, fases, chamados, visitas }) {
    // CONTEXT JOBS
    const { filterEmpreendimento } = useContext(JobsContext);

    // ESTADOS
    const [optionSelected, setOptionSelected] = useState([]);
    const [option, setOption] = useState();

    // FUNÇÃO PARA TROCAR O OPERADOR
    function handleChangeOperator(e) {
        let tipo_aux;
        if (params?.tipo_fase === 'Check') {
            tipo_aux = 'present';
        } else {
            tipo_aux = 'next';
        }

        // -> verificar se vai para usuario ou loja

        let subtype_aux
        if (params?.filter_subtype == 'store') {
            subtype_aux = 'user'
        } else {
            subtype_aux = 'store'
        }

        setOptionSelected(e.value);
        axios({
            method: 'post',
            url: window.host + '/systems/integration-react/api/list.php?do=set_operator&filter_id_module=' + (params.id_modulo ? params.id_modulo : params?.filterModule) + '&token=' + window.token,
            data: {
                id_job: params?.id_job ? params?.id_job : params?.id_job2,
                id_mov: params?.id_job_status ? params?.id_job_status : params?.id_job_status2,
                id_operador: e.value,
                tipo_fase: params?.tipo_fase,
                ativ_desc: params?.ativ_desc + ' ' + e.label,
                tipo: tipo_aux,
                filter_subtype: subtype_aux,
                nivel_msg: 2
            },
            headers: { 'Content-Type': 'multipart/form-data' }
        }).then(() => {
            toast(
                params?.filter_subtype == 'store' ?
                    'Card encaminhado ao usuário ' + e.label
                    :
                    'Card encaminhado a loja ' + e.label
            );
            onChange();
        });
    }
    // DEFINE A API QUE IRÁ BUSCAR OS OPTIONS DE OPERADORES
    //FUNÇÃO GET OPTIONS
    function getOptions() {
        let tipo_fase_aux;
        if (params?.tipo_fase === 'Check') {
            tipo_fase_aux = 'Check';
        } else {
            tipo_fase_aux = 'Operação';
        }
        if (params?.filter_subtype == 'user') {
            axios({
                method: 'get',
                url: window.host + '/systems/integration-react/api/list.php?do=get_operator',
                params: {
                    token: window.token,
                    filter_id_module: (params?.id_modulo ? params?.id_modulo : filterModule),
                    tipo_fase: tipo_fase_aux,
                    filter_subtype: 'store',
                    filter_id_operator: ((window.rs_permission_apl === 'lojista' || window.rs_permission_apl === 'operador' || window.rs_permission_apl === 'gerente' || window.rs_permission_apl === 'checker') ? window.rs_id_usr : ''),
                    id_apl: 224
                }
            }).then((response) => {
                setOption(response.data);
            })
        } else if (params?.filter_subtype == 'store') {
            axios({
                method: 'get',
                url: window.host + '/systems/integration-react/api/list.php?do=get_operator',
                params: {
                    token: window.token,
                    filter_id_module: (params?.id_modulo ? params?.id_modulo : filterModule),
                    tipo_fase: tipo_fase_aux,
                    filter_subtype: 'user',
                    filter_id_operator: ((window.rs_permission_apl === 'lojista' || window.rs_permission_apl === 'operador' || window.rs_permission_apl === 'gerente' || window.rs_permission_apl === 'checker') ? window.rs_id_usr : ''),
                    id_apl: 224
                }
            }).then((response) => {
                setOption(response.data);
            })
        }
    }
    //trocar o options entre loja e usuario
    useEffect(() => {
        getOptions()
    }, [filterEmpreendimento, params?.filter_subtype]);

    return (
        <div className={(margin !== false ? 'mt-3 mb-2' : '')}>
            <SelectReact
                label={label}
                placeholder={placeholder}
                name="troca_operador"
                options={option}
                value={optionSelected}
                required={false}
                menuPlacement={menuPlacement}
                isRtl={true}
                onChange={(e) => handleChangeOperator(e)}
            />
        </div>
    )
}