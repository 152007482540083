import { useEffect, useState } from "react";
import axios from "axios";
import Gerenciador from "../../../../components/body/gerenciador";
import Icon from "../../../../components/body/icon";
import Input from "../../../../components/body/form/input";
import Form from "../../../../components/body/form";
import SelectReact from "../../../../components/body/select";
import Button from "../../../../components/body/button";

export default function Cadastro({  id_loja,id, callback, tipo_aux, ambiente_aux, minima_aux, maxima_aux, umidade_aux, umidadeMin_aux, umidadeMax_aux, temperaturaMin_aux, temperaturaMax_aux }) {
    console.log(id_loja)
    //EDITAR CATEGORIA
    const [tipo, setTipo] = useState('');
    const [ambiente, setAmbiente] = useState('');
    const [minima, setMinima] = useState('');
    const [maxima, setMaxima] = useState('');
    const [momento, setMomento] = useState('');
    const [umidade, setUmidade] = useState('');
    const [umidadeMin, setUmidadeMin] = useState('');
    const [umidadeMax, setUmidadeMax] = useState('');
    const [temperaturaMin, setTemperaturaMin] = useState('');
    const [temperaturaMax, setTemperaturaMax] = useState('');
    const [foto, setFoto] = useState(1);
    const [observacao, setObservacao] = useState(2);
    const [tipos, setTipos] = useState()

    const options_boolean = [{ value: 1, label: "Sim" }, { value: 2, label: "Não" }]

    useEffect(() => {
        if (umidade == 1 && !id) {
            setUmidadeMin("45%")
            setUmidadeMax("75%")
        } else if (!id) {
            setUmidadeMin("")
            setUmidadeMax("")
        }

    }, [umidade]);

    //GET TIPOS
    function getTipos() {
        axios.get(window.host + '/systems/controle-temperatura/api/gerenciador.php', {
            params: {
                do: 'getControleTipo',
                token: window.token
            }
        }).then((response) => {
            setTipos(response.data)
        })
    }
    useEffect(() => {
        getTipos()
    }, []);

    useEffect(() => {
        if (id) {
            setTipo(tipo_aux);
            setAmbiente(ambiente_aux);
            setMinima(minima_aux);
            setMaxima(maxima_aux);
            // setMomento('');
            setUmidade(umidade_aux);
            setUmidadeMin(umidadeMin_aux);
            setUmidadeMax(umidadeMax_aux);
            setTemperaturaMin(temperaturaMin_aux);
            setTemperaturaMax(temperaturaMax_aux);
            setFoto(1);
            setObservacao(2);
        } else {
            setTipo('');
            setAmbiente('');
            setMinima('');
            setMaxima('');
            // setMomento('');
            setUmidade('');
            setUmidadeMin('');
            setUmidadeMax('');
            setTemperaturaMin('20°C');
            setTemperaturaMax('30°C');
            setFoto('');
            setObservacao(2);
        }
    }, [id])

    //CADASTRO CATEGORIA
    //INFO DO INPUT QUE VAI PRA API
    const data = {
        ativo: 1,
        loja_id: '',
        id: id,
        controle_tipo_id: tipo,
        nome: ambiente,
        temperatura_minima: minima,
        temperatura_maxima: maxima,
        //temperatura_momento: momento  ?
        umidade: umidade,
        foto_termometro: foto,
        observacao: observacao,
        temperatura_maxima_valor: temperaturaMax,
        temperatura_minima_valor: temperaturaMin,
        umidade_maxima_valor: umidadeMax,
        umidade_minima_valor: umidadeMin,
        loja_id: id_loja
    }

    //CADASTRAR FOTO POR ID-STRING
    const handleFoto = (res) => {
        let files;
        JSON.parse(res[0]).map((file, i) => {
            files = (file.id);
        });
        setFoto(files);
    }

    //LIMPAR FORMULÁRIO AO ENVIAR
    function resetForm() {
        callback(false)
        setTipo('');
        setAmbiente('');
        setMinima('');
        setMaxima('');
        setMomento('');
        setUmidade('');
        setUmidadeMin('');
        setUmidadeMax('');
        setTemperaturaMin('');
        setTemperaturaMax('');
        setFoto(1);
        setObservacao(2);
    }


    return (
        <Gerenciador
            id="editar-categoria-melhorias"
            title={`${id ? "Editar" : "Cadastrar"} Categoria`}
            autoScroll={true}
            icon={<Icon type="reprovar" onClick={() => callback(false)} />}
        >
            <Form
                api={window.host + '/systems/controle-temperatura/api/gerenciador.php?do=setControleTemperatura&token=' + window.token}
                data={data}
                callback={resetForm}
            >
                <h6 className="mb-3">
                    Componentes
                </h6>
                <SelectReact
                    label="Tipo"
                    value={tipo}
                    onChange={(e) => setTipo(e.value)}
                    options={tipos}
                />
                <Input
                    type="text"
                    name="nome-ambiente"
                    label="Nome do ambiente"
                    value={ambiente}
                    onChange={(e) => setAmbiente(e.target.value)}
                />
                <SelectReact
                    name="temperatura-minima"
                    label="Temperatura mínima"
                    value={minima}
                    onChange={(e) => setMinima(e.value)}
                    options={options_boolean}
                />
                <SelectReact
                    name="temperatura-maxima"
                    label="Temperatura máxima"
                    value={maxima}
                    onChange={(e) => setMaxima(e.value)}
                    options={options_boolean}
                />
                <SelectReact
                    name="umidade"
                    label="Umidade"
                    value={umidade}
                    onChange={(e) => setUmidade(e.value)}
                    options={options_boolean}
                    required={false}
                />
                <SelectReact
                    name="foto-termometro"
                    label="Foto Termômetro"
                    // type="file"
                    value={foto}
                    onChange={(e) => setFoto(e.value)}
                    // callback={handleFoto}
                    required={false}
               options={options_boolean}
                />
                <SelectReact
                    name="observacao"
                    label="Observação"
                    value={observacao}
                    onChange={(e) => setObservacao(e.value)}
                    required={false}
                    options={options_boolean}
                />
                <h6 className="my-3">
                    Valores de Referência
                </h6>
                <Input
                    name="temperatura-minima"
                    type="text"
                    label="Temperatura mínima"
                    value={temperaturaMin}
                    onChange={(e) => setTemperaturaMin(e.target.value)}
                    required={false}
                />
                <Input
                    name="temperatura-maxima"
                    label="Temperatura máxima"
                    value={temperaturaMax}
                    onChange={(e) => setTemperaturaMax(e.target.value)}
                    required={false}
                />

                <Input
                    name="umdade-minima"
                    type="text"
                    label="Umidade minima"
                    value={umidadeMin}
                    onChange={(e) => setUmidadeMin(e.target.value)}
                    required={false}
                />
                <Input
                    name="umidade-maxima"
                    type="text"
                    label="Umidade máxima"
                    value={umidadeMax}
                    onChange={(e) => setUmidadeMax(e.target.value)}
                    required={false}
                />

                <Button type="submit">Enviar</Button>
            </Form>
        </Gerenciador>
    )
}