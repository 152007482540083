import { useState, useEffect } from "react";
import axios from "axios";
import Modal from "../../../../components/body/modal";
import ModalBody from "../../../../components/body/modal/modalBody";
import ModalHeader from "../../../../components/body/modal/modalHeader";
import ModalTitle from "../../../../components/body/modal/modalHeader/modalTitle";
import Icon from "../../../../components/body/icon";
import style from '../../../../components/body/upload/Files.module.scss';
import { cdh } from "../../../../_assets/js/global";

import Chat from "../../../../components/body/chat";
import Message from "../../../../components/body/chat/message";
import Loader from "../../../../components/body/loader";
import Checklist from "../../../Checklist/Lista/cadastro";

export default function ModalListaJob(props) {



    //mesage

    const [messages, setMessages] = useState([]);
    const [searchMessages, setSearchMessages] = useState('');


    // VARIÁVEIS
    var fase;
    var messageFiles = [];
    var anexosValues = [];
    var idJobStatus = props.idJobStatus
    // GET MESSAGES
    function getMessage(idJobStatus, files) {
        setSearchMessages(true);
        anexosValues = [];
        messageFiles = [];

        axios.get(window.host + '/systems/integration-react/api/list.php?do=get_msg&filter_id_module=' + props?.filterModule + '&id=' +idJobStatus + '&token=' + window.token).then((response) => {
            setMessages(response.data);
            setSearchMessages(false);
            props?.handleRefreshChat(false);

            var divChat = document.getElementById('chat_' + props?.idJobStatus);
            if (divChat) {
                setTimeout(() => {
                    divChat.scrollTop = divChat.scrollHeight;
                }, 50);
            }

            // FILES DO JOB
            if (files && files.length > 0) {
                files.split(',').map((item, i) => {
                    anexosValues.push(item);
                });
            } else {
                anexosValues = [];
            }

            // CONCATENA ANEXOS
            if (response.data.length > 0) {
                response.data.map((mensagem, i) => {
                    if (mensagem.anexos) {
                        mensagem.anexos.split(',').map((item, i) => {
                            if (!props?.anexos.includes(item)) {
                                anexosValues.push(item);
                            }
                        });
                    }
                });
            }

            // setAnexos(anexosValues);
        });
    }

    // FUNÇÃO PARA RECARREGAR CHAT SEMPRE QUE RECEBER NOVA MENSAGEM
    useEffect(() => {
        if (props?.handleRefreshChat && props.show) {
            getMessage(props.idJobStatus, props?.anexos, props.modalTitle)
        }
    }, [props?.handleRefreshChat]);
    return (
        <Modal show={props.show} onHide={props.onHide}>
            <ModalHeader>
                <ModalTitle>
                    {(props.chamados ? 'Chamado' : 'Job') + ' - ' + (props.modalTitle ? props.modalTitle : 'Job')}
                    <span className="text-secondary">{' (' + props.idJobStatus + ')'}</span>
                </ModalTitle>
            </ModalHeader>
            <ModalBody>
                <Chat
                    api={window.host + '/systems/integration-react/api/list.php?do=set_msg&filter_id_module=' + props?.filterModule + '&token=' + window.token}
                    id={props?.idJobStatus}
                    send={false}
                    anexo={{
                        multiple: true
                    }}
                >
                    {(props?.modalDescription ?
                        <p dangerouslySetInnerHTML={{__html: props?.modalDescription}} />
                    : '')}

                    {(
                        props?.modalSistema ?
                            <p>{props?.modalSistema}</p>
                            : <></>
                    )}

                    {(props?.anexos.length > 0 ?
                        <>
                            <p className="mb-1">Anexos:</p>
                            <div className={style.files}>
                                {props?.anexos.split(',').map((item, i) => {
                                    return (
                                        <a href={process.env.REACT_APP_URL_UPLOAD + '/' + item} target="_blank" className={style.file__link}>
                                            Arquivo {props?.anexos.split(',').length - i}
                                            <Icon type="external" />
                                        </a>
                                    )
                                }).reverse()}
                            </div>
                        </>
                        : '')}

                    {(searchMessages ?
                        <Loader show={true} className="mb-3" />
                        :
                        (messages.length > 0 ?
                            messages?.map((message, i) => {
                                if (message.anexos) {
                                    message.anexos.split(',').map((item, i) => {
                                        messageFiles.push(item);
                                    });
                                }
                                return (
                                    <Message
                                        key={message.id}
                                        sender={message.name_usr}
                                        date={cdh(message.cad)}
                                        text={
                                            message.mensagem + (message.motivo ? '\nMotivo: ' + message.motivo + '' : '')
                                        }
                                        files={
                                            (message?.anexos ?
                                                message?.anexos.includes("id") ?
                                                    JSON.parse(message?.anexos)
                                                        .map((item, i) => {
                                                            console.log('JSON.parse(message?.anexos): ', JSON.parse(message?.anexos));
                                                            return (
                                                                <a href={process.env.REACT_APP_URL_UPLOAD + '/' + item.id} target="_blank" className={style.file__link + ' d-block'}>
                                                                    {item.name}
                                                                    <Icon type="external" />
                                                                </a>
                                                            )
                                                        })
                                                    :
                                                    message.anexos.split(',').map((item, i) => {
                                                        return (
                                                            <a href={process.env.REACT_APP_URL_UPLOAD + '/' + item} target="_blank" className={style.file__link + ' d-block'} key={'message_' + message.id + '_file_' + i}>
                                                                Arquivo {i + 1}
                                                                <Icon type="external" />
                                                            </a>
                                                        )
                                                    })
                                                : <></>
                                            )
                                        }
                                        align={(window.rs_id_usr == message.id_usr ? 'right' : 'left')}
                                    />
                                )
                            })
                            : '')
                    )}


                    {(messages.length == 0 && props?.anexos.length == 0 && !searchMessages ?
                        <p>Nenhum registro de mensagens</p>
                        : '')}
                </Chat>
                {(
                    props?.modalDadoAux && props?.modalMov && props?.integration_type !== undefined && props?.integration_type_1 !== undefined && props?.integration_type_1 !== '' && props?.modalStatus !== 0 ?
                        <div className="mt-3">
                            <Checklist
                                //disabled={true}
                                integration={true}
                                checklist_id={props?.integration_type}
                                loja_id={props?.integration_type_1}
                                callback={false}
                                submit={{
                                    enabled: false,
                                }}
                                interaction={false}
                                job={
                                    {
                                        job_id: props?.idJobStatus,
                                        data_job: props?.modalData,
                                        sistema_dado_aux: props?.modalDadoAux
                                    }
                                }
                            />
                        </div>
                        : <></>
                )}
            </ModalBody>
        </Modal>
    )
}

{/* <Modal show={showModal} onHide={handleCloseModal}>
<ModalHeader>
    <ModalTitle>
        {(props.chamados ? 'Chamado' : 'Job') + ' - ' + (modalTitle ? modalTitle : 'Job')}
        <span className="text-secondary">{' (' + idJobStatus + ')'}</span>
    </ModalTitle>
</ModalHeader>
<ModalBody>
    <Chat
        api={window.host + '/systems/integration-react/api/list.php?do=set_msg&filter_id_module=' + filterModule + '&token=' + window.token}
        id={idJobStatus}
        send={false}
        anexo={{
            multiple: true
        }}
    >
        {(modalDescription ?
            <p>
                {modalDescription}
            </p>
            : '')}

        {(
            modalSistema ?
                <p>{modalSistema}</p>
                : <></>
        )}

        {(anexos.length > 0 ?
            <>
                <p className="mb-1">Anexos:</p>
                <div className={style.files}>
                    {anexos.split(',').map((item, i) => {
                        return (
                            <a href={process.env.REACT_APP_URL_UPLOAD + '/' + item} target="_blank" className={style.file__link}>
                                Arquivo {anexos.split(',').length - i}
                                <Icon type="external" />
                            </a>
                        )
                    }).reverse()}
                </div>
            </>
            : '')}

        {(searchMessages ?
            <Loader show={true} className="mb-3" />
            :
            (messages.length > 0 ?
                messages?.map((message, i) => {
                    if (message.anexos) {
                        message.anexos.split(',').map((item, i) => {
                            messageFiles.push(item);
                        });
                    }
                    return (
                        <Message
                            key={message.id}
                            sender={message.name_usr}
                            date={cdh(message.cad)}
                            text={
                                message.mensagem + (message.motivo ? '\nMotivo: ' + message.motivo + '' : '')
                            }
                            files={
                                (message?.anexos ?
                                    message?.anexos.includes("id") ?
                                        JSON.parse(message?.anexos)
                                            .map((item, i) => {
                                                console.log('JSON.parse(message?.anexos): ', JSON.parse(message?.anexos));
                                                return (
                                                    <a href={process.env.REACT_APP_URL_UPLOAD + '/' + item.id} target="_blank" className={style.file__link + ' d-block'}>
                                                        {item.name}
                                                        <Icon type="external" />
                                                    </a>
                                                )
                                            })
                                        :
                                        message.anexos.split(',').map((item, i) => {
                                            return (
                                                <a href={process.env.REACT_APP_URL_UPLOAD + '/' + item} target="_blank" className={style.file__link + ' d-block'} key={'message_' + message.id + '_file_' + i}>
                                                    Arquivo {i + 1}
                                                    <Icon type="external" />
                                                </a>
                                            )
                                        })
                                    : <></>
                                )
                            }
                            align={(window.rs_id_usr == message.id_usr ? 'right' : 'left')}
                        />
                    )
                })
                : '')
        )}


        {(messages.length == 0 && anexos.length == 0 && !searchMessages ?
            <p>Nenhum registro de mensagens</p>
            : '')}
    </Chat>
    {(
        modalDadoAux && modalMov && integration_type !== undefined && integration_type_1 !== undefined && integration_type_1 !== '' && modalStatus !== 0 ?
            <div className="mt-3">
                <Checklist
                    //disabled={true}
                    integration={true}
                    checklist_id={integration_type}
                    loja_id={integration_type_1}
                    callback={false}
                    submit={{
                        enabled: false,
                    }}
                    interaction={false}
                    job={
                        {
                            job_id: idJobStatus,
                            data_job: modalData,
                            sistema_dado_aux: modalDadoAux
                        }
                    }
                />
            </div>
            : <></>
    )}
</ModalBody>
</Modal> */}