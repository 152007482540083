import Col from "../../../../components/body/col";
import Icon from "../../../../components/body/icon";
import { useState, useEffect } from "react";
import Gerenciador from "../../../../components/body/gerenciador";
import Form from "../../../../components/body/form";
import Input from "../../../../components/body/form/input";
import SelectReact from "../../../../components/body/select";
import { addDays, cd, subDays } from "../../../../_assets/js/global";
import Button from "../../../../components/body/button";

export default function EditorObras(props) {
    //ESTADOS INPUTS
    const [status, setStatus] = useState(2518); // SETA POR PADRÃO O STATUS "START"
    const [formato, setFormato] = useState('');
    const [tipo, setTipo] = useState('');
    const [qualificacao, setQualificacao] = useState('');
    const [dataInicio, setDataInicio] = useState(new Date(window.currentDate));
    const [dataCarencia, setDataCarencia] = useState('');
    const [dataPrevisao, setDataPrevisao] = useState('');
    const [dataInauguracao, setDataInauguracao] = useState('');
    const [buttonStatus, setButtonStatus] = useState('');

    // GET INFO OBRA
    useEffect(() => {
        if(props?.options){
            if(props?.options?.registry.length > 0){
                setStatus(props?.options?.registry[0]?.mov_id_status);
                setFormato(props?.options?.registry[0]?.mov_id_formato);
                setTipo(props?.options?.registry[0]?.mov_id_tipo);
                setQualificacao(props?.options?.registry[0]?.mov_id_qualificacao);
                setDataInicio((props?.options?.registry[0]?.mov_data ? new Date(props?.options?.registry[0]?.mov_data+' 00:00:00') : ''));
                setDataCarencia((props?.options?.registry[0]?.mov_data_carencia ? new Date(props?.options?.registry[0]?.mov_data_carencia+' 00:00:00') : ''));
                setDataPrevisao((props?.options?.registry[0]?.mov_data_previsao ? new Date(props?.options?.registry[0]?.mov_data_previsao+' 00:00:00') : ''));
                setDataInauguracao((props?.options?.registry[0]?.mov_data_inauguracao ? new Date(props?.options?.registry[0]?.mov_data_inauguracao+' 00:00:00') : ''));
            }else{
                formReset();
            }
        }
    },[props?.options]);

    // RESET FORM
    function formReset(){
        setStatus(2518); // SETA POR PADRÃO O STATUS "START"
        setFormato('');
        setTipo('');
        setQualificacao('');
        setDataInicio(new Date(window.currentDate));
        setDataCarencia('');
        setDataPrevisao('');
        setDataInauguracao('');
    }

    const data = {
        lote: (props?.lote ? props?.lote : ''),
        id_frequency: 4,
        enabled_systems: ['227'],
        date_start: dataInicio ? cd(dataInicio) : '',
        date_lack: dataCarencia ? cd(dataCarencia) : '',
        date_prevision: dataPrevisao ? cd(dataPrevisao) : '',
        date_opening: dataInauguracao ? cd(dataInauguracao) : '',
        id_store: [{id:props?.loja}],
        id_status: status,
        id_format: formato,
        id_type: tipo,
        id_qualification: qualificacao,
        ativo: 1
    }

    // CALLBACK CADASTRO DA OBRA
    const handleFormCallback = () => {
        props?.callback((props?.id ? false : true));
        if(!props?.id){
            formReset();
        }
    }

    // STATUS SUBMIT
    const handleSubmitStatus = (e) => {
        setButtonStatus(e);
    }

    // FECHA EDITOR
    const handleCloseEditor = () => {
        props.close();
    }

    return (
        <Col>
            <Gerenciador
                id="editor-obras"
                title={props?.id ? "Editor" : "Novo"}
                icon={
                    <Icon
                        type="reprovar"
                        title="Fechar"
                        onClick={handleCloseEditor}
                    />
                }
            >                
                <Form
                    fullHeight={true}
                    api={window.host+"/systems/integration-react/api/registry.php?do=set_registry&token=" + window.token}
                    data={data}
                    toast={'Obra '+(props?.lote ? 'editada' : 'cadastrada') + ' com sucesso'}
                    status={handleSubmitStatus}
                    callback={handleFormCallback}
                >
                    <SelectReact
                        name="status"
                        label="Status"
                        value={status}
                        onChange={(e) => setStatus(e.value)}
                        options={props?.options?.status}
                        loading={props?.loading}
                    />
                    <SelectReact
                        name="formato"
                        label="Formato"
                        value={formato}
                        onChange={(e) => setFormato(e.value)}
                        options={props?.options?.format}
                        required={false}
                        loading={props?.loading}
                    />
                    <SelectReact
                        name="tipo"
                        label="Tipo"
                        value={tipo}
                        onChange={(e) => setTipo(e.value)}
                        options={props?.options?.type}
                        loading={props?.loading}
                    />
                    <SelectReact
                        name="qualificacao"
                        label="Qualificação"
                        value={qualificacao}
                        onChange={(e) => setQualificacao(e.value)}
                        options={props?.options?.qualification}
                        required={false}
                        loading={props?.loading}
                    />
                    <Input
                        name="data-inicio"
                        type="date"
                        label="Data de Início"
                        value={dataInicio}
                        valueEnd={subDays(dataPrevisao, 1)}
                        onChange={(e) => setDataInicio(e)}
                        required={false}
                        loading={props?.loading}
                    />

                    <Input
                        name="data-carencia"
                        type="date"
                        label="Início de carência"
                        value={dataCarencia}
                        valueStart={addDays(dataInicio, 2)}
                        onChange={(e) => setDataCarencia(e)}
                        required={false}
                        loading={props?.loading}
                    />

                    <Input
                        name="data-previsao"
                        type="date"
                        label="Previsão de Inauguração"
                        value={dataPrevisao}
                        valueStart={addDays(dataInicio, 2)}
                        onChange={(e) => setDataPrevisao(e)}
                        required={false}
                        loading={props?.loading}
                    />

                    <Input
                        name="data-inauguracao"
                        type="date"
                        label="Inauguração Efetiva"
                        value={dataInauguracao}
                        valueStart={addDays(dataInicio, 2)}
                        onChange={(e) => setDataInauguracao(e)}
                        required={false}
                        loading={props?.loading}
                    />
                    
                    <Button type="submit" status={buttonStatus} loading={props?.loading}>Salvar</Button>
                </Form>
            </Gerenciador>
        </Col>
    )
}