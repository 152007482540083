import style from './detalhes.module.scss';

import { cloneElement, useState } from "react";
import Tr from "../../../../../components/body/table/tr";
import Td from "../../../../../components/body/table/tbody/td";

import Col from "../../../../../components/body/col";
import Icon from "../../../../../components/body/icon";
import CardAtualizacoes from "../../../../../components/body/obras/cardAtualizacoes";
import Row from "../../../../../components/body/row";
import Table from "../../../../../components/body/table";
import Tbody from "../../../../../components/body/table/tbody";
import ModalCadastroDocumentos from "./modal/modalCadastroDocumentos";
import ModalMotivo from "./modal/modalMotivo";
import ModalComentarios from "./modal/modalComentarios";
import axios from 'axios';
import { useEffect } from 'react';
import Loader from '../../../../../components/body/loader';
import { toast } from 'react-hot-toast';
import { cdh, diffDays, downloadFile, get_date } from '../../../../../_assets/js/global';
import Tippy from '@tippyjs/react';
import Chat from '../../../../../components/body/chat';
import Message from '../../../../../components/body/chat/message';

export default function Detalhes(props) {
    // VARIÁVEIS
    var categoria = '';
    var categoriaInicial = '';
    var categoriaIntermediaria = '';
    var categoriaFinal = '';
    var reprovados_aux = 0;

    // ESTADOS
    const [dataInicio, setDataInicio] = useState((props?.obra?.data_inicio ? get_date('date', props?.obra?.data_inicio, 'date_sql') : ''));
    const [dataCarencia, setDataCarencia] = useState((props?.obra?.data_carencia ? get_date('date', props?.obra?.data_carencia, 'date_sql') : ''));
    const [dataPrevisao, setDataPrevisao] = useState((props?.obra?.data_previsao ? get_date('date', props?.obra?.data_previsao, 'date_sql') : ''));
    const [dataInauguracao, setDataInauguracao] = useState((props?.obra?.data_inauguracao ? get_date('date', props?.obra?.data_inauguracao, 'date_sql') : ''));
    const [activeProgress, setActiveProgress] = useState(false);
    const [arquivos, setArquivos] = useState([]);
    const [documentos, setDocumentos] = useState('');
    const [vistoriasIniciais, setVistoriasIniciais] = useState([]);
    const [vistoriasIntermediarias, setVistoriasIntermediarias] = useState([]);
    const [vistoriasFinais, setVistoriasFinais] = useState([]);
    const [atualizacoes, setAtualizacoes] = useState([]);
    const [loadingArquivos, setLoadingArquivos] = useState(false);
    const [loadingDocumentos, setLoadingDocumentos] = useState(false);
    const [loadingVistoriasIniciais, setLoadingVistoriasIniciais] = useState(false);
    const [loadingVistoriasIntermediarias, setLoadingVistoriasIntermediarias] = useState(false);
    const [loadingVistoriasFinais, setLoadingVistoriasFinais] = useState(false);
    const [loadingAtualizacoes, setLoadingAtualizacoes] = useState(false);
    const [collapseVistoriasIniciais, setCollapseVistoriasIniciais] = useState(true);
    const [collapseVistoriasIntermediarias, setCollapseVistoriasIntermediarias] = useState(true);
    const [collapseVistoriasFinais, setCollapseVistoriasFinais] = useState(true);
    const [job, setJob] = useState('');
    const [status, setStatus] = useState('');
    const [documento, setDocumento] = useState(false);
    const [vistoria, setVistoria] = useState(false);
    const [interaction, setInteraction] = useState(true);
    const [colHeight, setColHeight] = useState('');
    const [visualizados, setVisualizados] = useState([]);
    const [reprovados, setReprovados] = useState(0);
    const [alertActived, setAlertActived] = useState(false);
    const [closeAlert, setCloseAlert] = useState(false);

    //ABRIR MODAL CADASTRO ADICIONAR DOCUMENTOS
    const [showModalEnvioDocumentos, setShowModalEnvioDocumentos] = useState(false);
    const [reload, setReload] = useState(false)

    const handleCloseCadastro = (e) => {
        setShowModalEnvioDocumentos(e);
        setReload(true);
        setTimeout(() => {
            setReload(false);
        }, 500);
    }

    //ABRIR MODAL CADASTRO ADICIONAR SOLICITAR ANALISE DE PROJETOS
    const [showEditarSolicitacao, setShowEditarSolicitacao] = useState(false);
    const handleShowEditarSolicitacao = () => setShowEditarSolicitacao(!showEditarSolicitacao);

    //ABRIR MODAL APROVAR DOCUMENTO
    const [showMotivo, setShowMotivo] = useState(false);

    const handleCloseMotivo = (e) => {
        setShowMotivo(false);
        if(e){
            set_updates(e);
        }
    }

    // MODAL COMENTÁRIOS
    const [showCometarios, setShowCometarios] = useState(false);

    const handleCloseCometarios = (e) => {
        setShowCometarios(e);
        setReload(true);
        setTimeout(() => {
            setReload(false);
        }, 500);
    }

    // GET DETALHES
    useEffect(() => {
        if(props.show){
            setActiveProgress(true); 
        }else if(!props.show){
            setActiveProgress(false);
        }
    },[props.show]);

    // SCROLL AUTOMÁTICO AO ABIR DETALHES
    useEffect(() => {        
        if(props.show){      
            setTimeout(() => {
                let offsetTopDetails = document.getElementById('detalhes_'+props?.obra?.id_job_status).offsetTop;
                let heightTr = document.getElementById('tr_'+props?.obra?.id_job_status).offsetHeight;
                document.getElementById('table_obras').scroll(0, (offsetTopDetails - heightTr - 56));
            },100);
        }
    },[props?.show]);

    // GET ARQUIVOS
    function get_arquivos(loading, reload){
        if(loading !== false){
            setLoadingArquivos(true);
        }
        axios({
            url: window.host + "/systems/integration-react/api/list.php?do=get_select&token=" + window.token,
            method: "get",
            params: {
                filter_id_group: props?.id,
                filter_type_registry: 'file',
                filter_subcategory_active: [1]
            }
        }).then((response) => {
            setArquivos(response.data);
            setLoadingArquivos(false);
            categoria = '';
        });

        if(props?.callback && reload){
            props.callback(true);
        }
    }

    // GET DOCUMENTOS
    function get_documentos(loading, reload){
        if(loading !== false){
            setLoadingDocumentos(true);
        }

        // ZERA NÚMERO DE REPROVADOS
        reprovados_aux = 0;

        axios({
            url: window.host + "/systems/integration-react/api/list.php?do=get_select&token=" + window.token,
            method: "get",
            params: {
                filter_id_group: props?.id,
                filter_type_registry: 'document',
                order_by: 'id_categoria'
            }
        }).then((response) => {
            setDocumentos(response.data);
            setLoadingDocumentos(false);

            if(window.rs_permission_apl === 'operador' && !closeAlert){
                setAlertActived(true);
            }
            
            categoria = '';            
        });

        if(props?.callback && reload){
            props.callback(true);
        }
    }

    // VERIFICA SE TEVE ALTERAÇÃO NOS DOCUMENTOS E RECAUCULA NÚMERO DE REPROVADOS
    useEffect(() => {
        setReprovados(reprovados_aux);
    },[documentos]);

    // RECARREGA LISTA DE DOCUMENTOS
    const refreshDocumentos = (e) => {
        if(e){
            get_documentos(true, true);
            if(e !== true){
                set_updates('Enviou o documento "'+e+'"');
            }
        } 
    }

    // GET VISTORIAS
    function get_vistorias(loading, reload){
        if(loading !== false){
            setLoadingVistoriasIniciais(true);
            setLoadingVistoriasIntermediarias(true);
            setLoadingVistoriasFinais(true);
        }

        axios({
            url: window.host + "/systems/integration-react/api/list.php?do=get_select&token=" + window.token,
            method: "get",
            params: {
                filter_id_group: props?.id,
                filter_type_registry: 'vistory_initial'
            }
        }).then((response) => {
            setVistoriasIniciais(response.data);
            setLoadingVistoriasIniciais(false);
            categoriaInicial = '';
        });

        axios({
            url: window.host + "/systems/integration-react/api/list.php?do=get_select&token=" + window.token,
            method: "get",
            params: {
                filter_id_group: props?.id,
                filter_type_registry: 'vistory_intermediate'
            }
        }).then((response) => {
            setVistoriasIntermediarias(response.data);
            setLoadingVistoriasIntermediarias(false);
            categoriaIntermediaria = '';
        });

        axios({
            url: window.host + "/systems/integration-react/api/list.php?do=get_select&token=" + window.token,
            method: "get",
            params: {
                filter_id_group: props?.id,
                filter_type_registry: 'vistory_final'
            }
        }).then((response) => {
            setVistoriasFinais(response.data);
            setLoadingVistoriasFinais(false);
            categoriaFinal = '';
        })

        if(props?.callback && reload){
            props.callback(true);
        }
    }

    // RECARREGA LISTA DE VISTORIAS
    const refreshVistorias = (e) => {
        if(e){
            get_vistorias(true, true);
        }
    }

    // ABRE MODAL DE ENVIAR DOCUMENTOS
    function handleModalEnvioDocumentos(job, interaction){
        setJob(job);
        setInteraction(interaction)
        setShowModalEnvioDocumentos(!showModalEnvioDocumentos);
    }

    // ABRE MODAL DE MOTIVO
    function handleShowMotivo(job, status, documento, vistoria){
        setJob(job);
        setStatus(status);
        setDocumento(documento);
        setVistoria(vistoria)
        setShowMotivo(true);
    }

    // ABRE MODAL DE COMENTÁRIOS
    function handleShowCometarios(job, interaction = undefined){
        setShowCometarios(!showCometarios);
        setInteraction(interaction)
        setJob(job);
    }

    // AVALIAÇÃO SUPERVISOR
    function changeStatusSupervisor(ativ_desc, id_job, id_job_status, status, msg = undefined, documento = false, vistoria = false) {
        let title_communication;

        if(status == 1){
            if(vistoria){
                title_communication = 'Vistoria aprovada';
            }else{
                title_communication = 'Documento aprovado';
            }
        }else if(status == 2){
            if(vistoria){
                title_communication = 'Vistoria reprovada';
            }else{
                title_communication = 'Documento reprovado';
            }
        }else if(status == 4){
            if(vistoria){
                title_communication = 'Vistoria aprovada com ressalva';
            }else{
                title_communication = 'Documento aprovado com ressalva';
            }
        }

        axios({
            method: 'post',
            url: window.host+"/systems/integration-react/api/list.php?do="+(vistoria ? 'set_status' : 'set_statusSupervisor')+"&token="+window.token,
            data: {
                ativ_desc: ativ_desc,
                id_job: id_job,
                id_mov: id_job_status,
                status: status,
                msg: msg,
                data_aux: '',
                tipo: '',
                tipo_fase: '',
                title_communication: title_communication
            },
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(() => {
            if(documento){
                get_documentos(false, true);
            }

            if(vistoria){
                get_vistorias(false, true);
            }
            set_updates(msg);

            if(documento){
                toast('Documento avaliado com sucesso');
            }else if(vistoria){
                toast('Vistoria realizada com sucesso');
            }

            if(props?.callback){
                props.callback(true);
            }
        });
    }

    // GET ATUALIZAÇÕES
    function get_updates(){
        if(props?.id_job_status){
            setLoadingAtualizacoes(true);
            axios({
                method: 'get',
                url: window.host+'/systems/integration-react/api/list.php?do=get_msg&id='+props?.id_job_status+'&token='+window.token ,
                params: {
                    order: 'desc'
                }
            }).then((response) => {
                setLoadingAtualizacoes(false);
                if(response.data){
                    setAtualizacoes(response.data);
                }                
            });
        }
    }

    // SET ATUALIZAÇÕES
    function set_updates(message){
        if(props?.id_job_status){
            axios({
                method: 'post',
                url: window.host+'/systems/integration-react/api/list.php?do=set_msg&token='+window.token,
                data: {
                    ativ: 'Enviou uma mensagem',
                    id_job: props?.obra?.id_job,
                    id_mov: props?.id_job_status,
                    ativ_desc: message,
                    // anexos: anexos,
                    // nivel_msg: (props?.data?.nivel_msg ? props.data.nivel_msg : '' )
                },
                headers: {'Content-Type': 'multipart/form-data'}
            }).then(() => {
                get_updates();
            });
        }
    }

    // CHAMADAS AO ABRIR DETALHES
    useEffect(() => {        
        if(props?.lote && props?.id && props?.show){
            get_arquivos();
            get_documentos();
            get_vistorias();
            get_updates();
            setCloseAlert(false);
        }
    },[props.show]);

    // CORES
    let colorClass;
    if(props?.progress?.color == 'bg-success'){
        colorClass = style.bgSuccess;
    }else if(props?.progress?.color == 'bg-warning'){
        colorClass = style.bgWarning;
    }else if(props?.progress?.color == 'bg-danger'){
        colorClass = style.bgDanger;
    }else{
        colorClass = '';
    }

    // SETA TAMANHO DA COLUNAS DE ATUALIZAÇÕES
    let colDoc = 5000;
    if(props?.obra?.id_job_status){
        if(document.getElementById('documentos_'+props?.obra?.id_job_status)){
            colDoc = document.getElementById('documentos_'+props?.obra?.id_job_status).offsetHeight - 95;
        }
    }

    // DOWNLOAD ARQUIVOS
    function handleDownload(id_job, id_job_status, file, file_name, visualizado){
        axios({
            method: 'post',
            url: window.host+'/systems/integration-react/api/list.php?do=set_visualized&token='+window.token,
            data: {
                id_job: id_job,
                id_mov: id_job_status,
                ativ_desc: 'Fez o download do arquivo'
            },
            headers: {'Content-Type': 'multipart/form-data'}
        }).then(() => {
            downloadFile(file, file_name);
            set_updates('Fez o download do arquivo '+file_name);
            setVisualizados(visualizados => [...visualizados, id_job]);
        });   
    }

    let vistoriasLength = false;

    if((vistoriasIniciais.length > 0 || vistoriasIntermediarias.length > 0 || vistoriasFinais.length > 0) && !loadingVistoriasIniciais && !loadingVistoriasIntermediarias && !loadingVistoriasFinais){
        vistoriasLength = true;
    }else{
        vistoriasLength = false;
    }

    {/* COLUNA DE ARQUIVOS */}
    let colFiles = cloneElement(
        <div className={'d-flex flex-column w-100 ' + (vistoriasLength ? 'mb-3' : 'h-100')} id={'arquivos_'+props?.obra?.id_job_status}>
            <div className={style.table_container}>
                <div className={style.table_container_title}>
                    <span>
                        Arquivos para consulta
                    </span>
                </div>
                <div className={style.table_container_body}>
                    <Table>
                        <Tbody>
                            {(loadingArquivos ?
                                <Tr>
                                    <Td>
                                        <Loader />
                                    </Td>
                                </Tr>
                            :
                                (arquivos.length > 0 ?
                                    (arquivos.filter((elem) => elem.mov_ativo == 1).length > 0 ?
                                        arquivos.filter((elem) => elem.mov_ativo == 1).map((arquivo, i) => {
                                            return(
                                                <Tr
                                                    key={'arquivo_'+arquivo.value}
                                                >
                                                    <Td>{arquivo.subcategoria}</Td>
                                                    <Td width={1} align="end">
                                                        <Icon
                                                            type="download"
                                                            animated
                                                            title="Baixar arquivo"
                                                            onClick={() => handleDownload(arquivo.id_job, arquivo.id_job_status, JSON.parse(arquivo.subcategoria_arquivo)[0].id, arquivo.subcategoria, arquivo.visualizado)}
                                                        />
                                                    </Td>
                                                </Tr>
                                            )
                                        })                                                        
                                    :
                                        <Tr>
                                            <Td colspan="100%">
                                                Nenhum arquivo cadastrado
                                            </Td>
                                        </Tr>
                                    )
                                :
                                    <Tr>
                                        <Td colspan="100%">
                                            Nenhum arquivo disponível para consulta
                                        </Td>
                                    </Tr>
                                )
                            )}
                        </Tbody>
                    </Table>
                </div>
            </div>
        </div>
    )

    // FECHA ALERTA DE DOCUMENTOS REPROVADOS
    const handleCloseAlert = () => {
        setAlertActived(false);
        setCloseAlert(true);
    }

    return(
        <>
            <ModalCadastroDocumentos
                show={showModalEnvioDocumentos}
                handleShow={handleModalEnvioDocumentos}
                onHide={handleCloseCadastro}
                job={job}
                interaction={interaction}
                callback={refreshDocumentos}
            />

            <ModalMotivo
                show={showMotivo}
                handleShow={handleShowMotivo}
                onHide={handleCloseMotivo}
                job={job}
                status={status}
                callback={(vistoria ? refreshVistorias : refreshDocumentos)}
                documento={documento}
                vistoria={vistoria}
            />

            <ModalComentarios
                show={showCometarios}
                handleShow={handleShowCometarios}
                onHide={handleCloseCometarios}
                interaction={interaction}
                job={job}
            />

            <Tr className={style.detalhes} id={'detalhes_'+props?.obra?.id_job_status}>
                <Td colspan="100%">            
                    <div className={style.container_timeline + ' w-100 ' +colorClass}>    
                        <Tippy content={'Progresso: '+Math.round(props?.progress?.percentage)+'% (Baseado no início e previsão)'}>
                            <div className={style.progressbar}>
                                <div className={style.bar + ' ' + (activeProgress ? style.active : '') + ' ' + props?.progress?.color} style={{width:props?.progress?.percentage+'%'}}></div>
                            </div>
                        </Tippy>

                        <div className={style.info_2}>
                            <div>
                                <span>Início: {(dataInicio ? dataInicio : 'Sem data definida')}</span>
                                <span>Início da Carência: {(dataCarencia ? dataCarencia : 'Sem data definida')}</span>
                            </div>
                            <div>
                                <span>Previsão de Inauguração: {(dataPrevisao ? dataPrevisao : 'Sem data definida')}</span>
                                <span>Inauguração efetiva: {(dataInauguracao ? dataInauguracao : 'Não inaugurado')}</span>
                            </div>
                        </div>
                    </div>

                    <div className={style.dash}>
                        <Row>
                            <Col lg={12}>                         
                                <div className={style.info_3}>
                                    <div>
                                        {/* {(window.rs_permission_apl === 'operador' ?
                                            <button
                                                className="dash-obras-button"
                                                onClick={handleShowCadastro}
                                            >
                                                + Adicionar Documentos
                                            </button>
                                        :
                                            ''
                                        )} */}
                                    </div>

                                    <div>
                                        {/* <div>
                                            <span className="me-1">
                                                Informações da Obra                                            
                                            </span>
                                            <Icon type="checklist" onClick={handleShowInformacoesObra} />                                            
                                        </div> */}
                                        <div>
                                            <span className="me-1">
                                                Responsável: {props?.obra?.cad_usr_nome}
                                            </span>

                                            {(props?.obra?.cad_usr_whatsapp && props?.obra?.cad_usr_whatsapp != 0 ?
                                                <a href={'https://api.whatsapp.com/send/?phone='+props?.obra?.cad_usr_whatsapp.replace('+','').replaceAll(' ','')} target="_blank" rel="noreferrer" >
                                                    <Icon type="whatsapp" />
                                                </a>
                                            :'')}

                                            {(props?.obra?.cad_usr_email ?
                                                <a href={'mailto:'+props?.obra?.cad_usr_email}>
                                                    <Icon
                                                        type="envelope"
                                                        animated
                                                    />
                                                </a>
                                            :'')}
                                        </div>
                                    </div>
                                </div>           
                            </Col>
                        </Row>

                        <Row className="justify-content-between">   
                            {/* SE NÃO TIVER NENHUMA VISTORIA HABILITADA NO GERENCIADOR CRIA UMA COLUNA A PARTE, CASO CONTRÁRIO FICA JUNTO COM DOCUMENTOS */}
                            {(!vistoriasLength ? 
                                <Col xl={4} className="mb-3">
                                    {colFiles}
                                </Col>
                            :'')}

                            <Col xl={4} className={'mb-3 ' + (!vistoriasLength ? '' : 'd-flex flex-column')}>
                                {/* SE TIVER VISTORIA HABILITADA NO GERENCIADOR INSERE OS ARQUIVOS JUNTO COM DOCUMENTOS, CASO CONTRÁRIO FICA SEPARADO */}
                                {(vistoriasLength ? 
                                    colFiles
                                :'')}

                                <div className={'d-flex flex-column w-100 position-relative ' + (!vistoriasLength ? 'h-100' : 'flex-1')} id={'documentos_'+props?.obra?.id_job_status}>
                                    {/* SE O DOCUMENTO TIVER SIDO REPROVADO */}
                                    <div className={style.float_alert + ' ' + (alertActived && reprovados > 0 ? style.active : '')}>
                                        <div className={style.float_alert_header}>
                                            <span>Atenção</span>
                                            <Icon
                                                type="reprovar"
                                                title="Fechar"
                                                onClick={handleCloseAlert}
                                            />
                                        </div>
                                        <div className={style.float_alert_body}>
                                            Você possui documento(s) reprovado(s).<br />
                                            Necessário novo envio.
                                        </div>
                                    </div>

                                    <div className={style.table_container + ' flex-1'}>
                                        <div className={style.table_container_title}>
                                            <span>
                                                Documentos
                                            </span>
                                            {(!loadingDocumentos ?
                                                <span>
                                                    Enviados
                                                    {(documentos ? ' ' +documentos.filter((elem) => elem.mov_ativo == 1).filter((elem) => elem.status != 0).length : '')}
                                                    /
                                                    {(documentos ? documentos.filter((elem) => elem.mov_ativo == 1).length : '')}
                                                </span>
                                            :'')}
                                        </div>
                                        <div className={style.table_container_body}>
                                            <Table>
                                                <Tbody>
                                                    {(loadingDocumentos ?
                                                        <Tr>
                                                            <Td>
                                                                <Loader />
                                                            </Td>
                                                        </Tr>
                                                    :
                                                        (documentos ?
                                                            (documentos.filter((elem) => elem.mov_ativo == 1).length > 0 ?
                                                                documentos.filter((elem) => elem.mov_ativo == 1).map((documento, i) => {
                                                                    let permissao_documento = true;

                                                                    if(documento?.subcategoria_permissao_cargo){
                                                                        // console.log('Permissão: ',documento?.subcategoria_permissao_cargo);

                                                                        if(documento?.subcategoria_permissao_cargo?.split(',').includes(window.rs_id_office)){
                                                                            permissao_documento = true;
                                                                        }else{
                                                                            permissao_documento = false;
                                                                        }
                                                                    }

                                                                    if(window.rs_permission_apl === 'operador' && documento.status_supervisor == 2){
                                                                        reprovados_aux = reprovados_aux + 1;
                                                                    }                                                                    

                                                                    return(
                                                                        <>
                                                                            {(() => {                          
                                                                                if(categoria != documento.categoria){                                                                       
                                                                                    return(
                                                                                        <Tr
                                                                                            key={'categoria_'+documento.id_categoria}
                                                                                        >
                                                                                            <Td colspan="100%">
                                                                                                <b>{documento.categoria}</b>
                                                                                            </Td>
                                                                                        </Tr>
                                                                                    )
                                                                                }                                                                                                         
                                                                            })()}

                                                                            {(() => {    
                                                                                categoria = documento.categoria  
                                                                            })()}
                                                                            
                                                                            <Tr
                                                                                key={'documento_'+documento.value}
                                                                            >
                                                                                <Td>
                                                                                    {documento.subcategoria}

                                                                                    {((window.rs_permission_apl === 'aprovador' || window.rs_permission_apl === 'master') && documento.status == 1 && !documento.status_supervisor ?
                                                                                        <span className={style.badge}>
                                                                                            Novo
                                                                                        </span>
                                                                                    :'')}

                                                                                    {(window.rs_permission_apl === 'operador' && documento.status_supervisor == 2 ?
                                                                                        <span className={style.badge}>
                                                                                            Reprovado
                                                                                        </span>
                                                                                    :'')}
                                                                                </Td>

                                                                                <Td width={1} align="end">
                                                                                    <Icon
                                                                                        type="message"
                                                                                        animated
                                                                                        title={'Mensagens'}
                                                                                        onClick={() => handleShowCometarios(documento, permissao_documento)}
                                                                                    />

                                                                                    {(window.rs_permission_apl === 'operador' ?
                                                                                        (documento.status == 0 ? // VERIFICA SE O JOB AINDA NÃO FOI CONCLUÍDO PELO LOJISTA
                                                                                            <Icon
                                                                                                type="file"
                                                                                                title="Anexar arquivos"
                                                                                                animated
                                                                                                onClick={() => handleModalEnvioDocumentos(documento, true)}
                                                                                            />
                                                                                        : 
                                                                                            <>
                                                                                                {(documento.status_supervisor == 2 || documento.status_supervisor == 3 ?
                                                                                                    <Icon
                                                                                                        type="file"
                                                                                                        title="Anexar arquivos"
                                                                                                        animated
                                                                                                        onClick={() => handleModalEnvioDocumentos(documento, true)}
                                                                                                    />
                                                                                                :'')}

                                                                                                <Icon
                                                                                                    type="view"
                                                                                                    animated
                                                                                                    title="Visualizar arquivos"
                                                                                                    onClick={() => handleModalEnvioDocumentos(documento, false)}
                                                                                                />

                                                                                                {(window.rs_permission_apl === 'operador' && !documento.status_supervisor ?
                                                                                                    <Icon
                                                                                                        type="hourglass"
                                                                                                        readonly={true}
                                                                                                        title={'Aguardando avaliação do setor responsável'}
                                                                                                        animated
                                                                                                    />
                                                                                                :'')}

                                                                                                {(documento.status_supervisor == 1 ?
                                                                                                    <Icon
                                                                                                        type="check"
                                                                                                        title={'Aprovado'}
                                                                                                        className={'text-success'}
                                                                                                        readonly={true}
                                                                                                        animated
                                                                                                    />
                                                                                                :'')}

                                                                                                {(documento.status_supervisor == 4 ?
                                                                                                    <Icon
                                                                                                        type="exclamation"
                                                                                                        title={'Aprovado com ressalva'}
                                                                                                        className={'text-warning'}
                                                                                                        readonly={true}
                                                                                                        animated
                                                                                                    />
                                                                                                :'')}

                                                                                                {(documento.status_supervisor == 2 ?
                                                                                                    <Icon
                                                                                                        type="reprovar2"
                                                                                                        title={'Reprovado'}
                                                                                                        className={'text-danger'}
                                                                                                        readonly={true}
                                                                                                        animated
                                                                                                    />
                                                                                                :'')}
                                                                                            </>
                                                                                        )
                                                                                    :
                                                                                        <>
                                                                                            {(documento.status == 1 ? // VERIFICA SE O JOB JÁ FOI FINALIZADO PELO LOJISTA
                                                                                                <>
                                                                                                    <Icon
                                                                                                        type="view"
                                                                                                        title="Visualizar arquivos"
                                                                                                        animated
                                                                                                        onClick={() => handleModalEnvioDocumentos(documento, false)}
                                                                                                    />

                                                                                                    <Icon
                                                                                                        type="check"
                                                                                                        title={(documento.status_supervisor == 1 ? 'Aprovado' : (window.rs_id_office == 6113 || window.rs_id_office == 1559 || window.rs_id_office == 1429 || !permissao_documento ? 'Aguardando avaliação do setor responsável' : (documento.status_supervisor == 4 || documento.status_supervisor == 2 ? 'Não é possível alterar a avaliação' : 'Aprovar')))} // SE FOR CARGO "GESTÃO (8512)" OU "COMERCIAL (1559) OU "OPERAÇÕES (1429)"
                                                                                                        className={(documento.status_supervisor == 1 ? 'text-success' : '')}
                                                                                                        readonly={(documento.status_supervisor == 1 || (window.rs_id_office == 6113 || window.rs_id_office == 1559 || window.rs_id_office == 1429 || !permissao_documento ) ? true : false)} // SE JÁ TIVER SIDO AVALIADO OU O CARGO FOR "GESTÃO (8512)" OU "COMERCIAL (1559)" OU "OPERAÇÕES (1429)"
                                                                                                        disabled={(documento.status_supervisor == 4 || documento.status_supervisor == 2 ? true : false)}
                                                                                                        animated
                                                                                                        onClick={() => 
                                                                                                            changeStatusSupervisor(
                                                                                                                'Avaliou um documento',
                                                                                                                documento.id_job,
                                                                                                                documento.id_job_status,
                                                                                                                1,
                                                                                                                'Aprovou o documento '+documento.subcategoria,
                                                                                                                true,
                                                                                                                false
                                                                                                            )
                                                                                                        }
                                                                                                    />

                                                                                                    <Icon
                                                                                                        type="exclamation"
                                                                                                        title={(documento.status_supervisor == 4 ? 'Aprovado com ressalva' : (window.rs_id_office == 6113 || window.rs_id_office == 1559 || window.rs_id_office == 1429 || !permissao_documento ? 'Aguardando avaliação do setor responsável' : (documento.status_supervisor == 1 || documento.status_supervisor == 2 ? 'Não é possível alterar a avaliação' : 'Aprovar com ressalva')))} // SE FOR CARGO "GESTÃO (8512)" OU "COMERCIAL (1559)" OU "OPERAÇÕES (1429)"
                                                                                                        className={(documento.status_supervisor == 4 ? 'text-warning' : '')}
                                                                                                        readonly={(documento.status_supervisor == 4 || (window.rs_id_office == 6113 || window.rs_id_office == 1559 || window.rs_id_office == 1429 || !permissao_documento) ? true : false)} // SE JÁ TIVER SIDO AVALIADO OU O CARGO FOR "GESTÃO (8512)" OU "COMERCIAL (1559)" OU "OPERAÇÕES (1429)"
                                                                                                        disabled={(documento.status_supervisor == 1 || documento.status_supervisor == 2 ? true : false)}
                                                                                                        animated
                                                                                                        onClick={() => handleShowMotivo(documento, 4, true, false)}
                                                                                                    />

                                                                                                    <Icon
                                                                                                        type="reprovar2"
                                                                                                        title={(documento.status_supervisor == 2 ? 'Reprovado' : (window.rs_id_office == 6113 || window.rs_id_office == 1559 || window.rs_id_office == 1429 || !permissao_documento ? 'Aguardando avaliação do setor responsável' : (documento.status_supervisor == 1 || documento.status_supervisor == 2 ? 'Não é possível alterar a avaliação' : 'Reprovar')))} // SE FOR CARGO "GESTÃO (8512)" OU "COMERCIAL (1559)" OU "OPERAÇÕES (1429)"
                                                                                                        className={(documento.status_supervisor == 2 ? 'text-danger' : '')}
                                                                                                        readonly={(documento.status_supervisor == 2 || (window.rs_id_office == 6113 || window.rs_id_office == 1559 || window.rs_id_office == 1429 || !permissao_documento) ? true : false)} // SE JÁ TIVER SIDO AVALIADO OU O CARGO FOR "GESTÃO (8512)" OU "COMERCIAL (1559)" OU "OPERAÇÕES (1429)"
                                                                                                        disabled={(documento.status_supervisor == 1 || documento.status_supervisor == 4 ? true : false)}
                                                                                                        onClick={() => handleShowMotivo(documento, 2, true, false)}
                                                                                                        animated
                                                                                                    />
                                                                                                </>
                                                                                            :
                                                                                                <Icon
                                                                                                    type="hourglass"
                                                                                                    readonly={true}
                                                                                                    title={'Aguardando envio do lojista'}
                                                                                                    animated
                                                                                                />
                                                                                            )}
                                                                                        </>
                                                                                    )}
                                                                                </Td>
                                                                            </Tr>
                                                                        </>
                                                                    )
                                                                })                                                        
                                                            :
                                                                <Tr>
                                                                    <Td colspan="100%">
                                                                        Nenhum documento cadastrado
                                                                    </Td>
                                                                </Tr>
                                                            )
                                                        :
                                                            <></>
                                                        )
                                                    )}
                                                </Tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </div>
                            </Col>

                            {/* SE TIVER ALGUMA VISTORIA HABILITADA NO GERENCIADOR EXIBE A COLOUNA DE VISTORIAS */}
                            {(vistoriasIniciais.length > 0 || vistoriasIntermediarias.length > 0 || vistoriasFinais.length > 0 ? 
                                <Col lg={4} className="mb-3">
                                    <div className="d-flex flex-column h-100 w-100">
                                        <div className={style.table_container}>
                                            <div className={style.table_container_title}>
                                                <span>
                                                    Vistorias
                                                </span>
                                                {(!loadingVistoriasIniciais && !loadingVistoriasIntermediarias && !loadingVistoriasFinais ?
                                                    <span>
                                                        Finalizadas
                                                        {(vistoriasIniciais.length > 0 && vistoriasIntermediarias.length > 0 && vistoriasFinais.length > 0 ?
                                                            ' ' +
                                                            (
                                                                vistoriasIniciais.filter((elem) => elem.mov_ativo == 1).filter((elem) => elem.status == 1).length + 
                                                                vistoriasIntermediarias.filter((elem) => elem.mov_ativo == 1).filter((elem) => elem.status == 1).length + 
                                                                vistoriasFinais.filter((elem) => elem.mov_ativo == 1).filter((elem) => elem.status == 1).length +
                                                                vistoriasIniciais.filter((elem) => elem.mov_ativo == 1).filter((elem) => elem.status == 5).length + 
                                                                vistoriasIntermediarias.filter((elem) => elem.mov_ativo == 1).filter((elem) => elem.status == 5).length + 
                                                                vistoriasFinais.filter((elem) => elem.mov_ativo == 1).filter((elem) => elem.status == 5).length
                                                            )
                                                        :
                                                            ''
                                                        )}
                                                        /
                                                        {(vistoriasIniciais && vistoriasIntermediarias && vistoriasFinais ?
                                                            vistoriasIniciais.filter((elem) => elem.mov_ativo == 1).length + 
                                                            vistoriasIntermediarias.filter((elem) => elem.mov_ativo == 1).length + 
                                                            vistoriasFinais.filter((elem) => elem.mov_ativo == 1).length
                                                        : '')}
                                                    </span>
                                                :'')}
                                            </div>
                                            <div className={style.table_container_body}>
                                                {(loadingVistoriasIniciais || loadingVistoriasIntermediarias || loadingVistoriasFinais ?
                                                    <Table>
                                                        <Tr>
                                                            <Td colspan="100%">
                                                                <Loader show={true} />
                                                            </Td>
                                                        </Tr>
                                                    </Table>
                                                :
                                                    <></>
                                                )}

                                                {(vistoriasIniciais.length > 0 && (!loadingVistoriasIniciais && !loadingVistoriasIntermediarias && !loadingVistoriasFinais) ?
                                                    <Table className={(collapseVistoriasIniciais ? 'mb-4' : '')}>
                                                        <Tbody>
                                                            <Tr>
                                                                <Td>
                                                                    <b>Inicial</b>
                                                                </Td>
                                                                <Td width={1} align="end">
                                                                    <Icon
                                                                        type={collapseVistoriasIniciais ? 'collapseOut' : 'collapseIn'}
                                                                        title={collapseVistoriasIniciais ? 'Minimizar' : 'Expandir'}
                                                                        onClick={() => setCollapseVistoriasIniciais(!collapseVistoriasIniciais)}
                                                                    />
                                                                </Td>
                                                            </Tr>

                                                            {(collapseVistoriasIniciais ?
                                                                vistoriasIniciais.filter((elem) => elem.mov_ativo == 1).map((vistoria, i) => {
                                                                    return(
                                                                        <>
                                                                            {(() => {                          
                                                                                if(categoriaInicial != vistoria.categoria){                                                                       
                                                                                    return(
                                                                                        <Tr
                                                                                            key={'vistoria_inicial_categoria_'+i}
                                                                                            className={'bg_light ' + style.bg_light}
                                                                                        >
                                                                                            <Td colspan="100%">
                                                                                                <b>{vistoria.categoria}</b>
                                                                                            </Td>
                                                                                        </Tr>
                                                                                    )
                                                                                }                                                                                                         
                                                                            })()}

                                                                            {(() => {    
                                                                                categoriaInicial = vistoria.categoria  
                                                                            })()}

                                                                            <Tr
                                                                                key={'vistoria_inicial_categoria_'+vistoria.value}
                                                                            >
                                                                                <Td>
                                                                                    {vistoria.subcategoria}
                                                                                </Td>
                                                                                <Td width={1}>
                                                                                    <Icon
                                                                                        type="message"
                                                                                        animated
                                                                                        onClick={() => handleShowCometarios(vistoria)}
                                                                                    />

                                                                                    {(window.rs_permission_apl === 'operador' ?
                                                                                        <>
                                                                                            {(vistoria.status == 1 ?
                                                                                                <Icon
                                                                                                    type="check"
                                                                                                    title={'Aprovada'}
                                                                                                    className={'text-success'}
                                                                                                    readonly={true}
                                                                                                    animated
                                                                                                />
                                                                                            :'')}

                                                                                            {(vistoria.status == 5 ?
                                                                                                <Icon
                                                                                                    type="exclamation"
                                                                                                    title={'Aprovada com ressalva'}
                                                                                                    className={'text-warning'}
                                                                                                    readonly={true}
                                                                                                    animated
                                                                                                />
                                                                                            :'')}

                                                                                            {(vistoria.status == 2 ?
                                                                                                <Icon
                                                                                                    type="reprovar2"
                                                                                                    title={'Reprovada'}
                                                                                                    className={'text-danger'}
                                                                                                    readonly={true}
                                                                                                    animated
                                                                                                />
                                                                                            :'')}
                                                                                        </>
                                                                                    :'')}

                                                                                    {(window.rs_permission_apl === 'aprovador' || window.rs_permission_apl === 'master' ?
                                                                                        <>
                                                                                            {(!vistoria.status || vistoria.status == 1 ?
                                                                                                <Icon
                                                                                                    type="check"
                                                                                                    title={(vistoria.status == 1 ? 'Aprovada' : (window.rs_id_office == 6113 || window.rs_id_office == 1559 ? 'Aguardando avaliação do setor responsável' : 'Aprovar'))} // SE FOR CARGO "GESTÃO (8512)" OU "COMERCIAL (1559)"
                                                                                                    className={(vistoria.status == 1 ? 'text-success' : '')}
                                                                                                    readonly={(vistoria.status == 1 || (window.rs_id_office == 6113 || window.rs_id_office == 1559) ? true : false)}
                                                                                                    animated
                                                                                                    onClick={() => 
                                                                                                        changeStatusSupervisor(
                                                                                                            'Realizou uma vistoria',
                                                                                                            vistoria.id_job,
                                                                                                            vistoria.id_job_status,
                                                                                                            1,
                                                                                                            'Vistoria "'+vistoria.subcategoria+'"' + ' aprovada por ' + window.rs_name_usr,
                                                                                                            false,
                                                                                                            true
                                                                                                        )
                                                                                                    }
                                                                                                />
                                                                                            :'')}

                                                                                            {(!vistoria.status || vistoria.status == 5 ?
                                                                                                <Icon
                                                                                                    type="exclamation"
                                                                                                    title={(vistoria.status == 5 ? 'Aprovada com ressalva' : (window.rs_id_office == 6113 || window.rs_id_office == 1559 ? 'Aguardando avaliação do setor responsável' : 'Aprovar com ressalva'))} // SE FOR CARGO "GESTÃO (8512)" OU "COMERCIAL (1559)"
                                                                                                    className={(vistoria.status == 5 ? 'text-warning' : '')}
                                                                                                    readonly={(vistoria.status == 5 || (window.rs_id_office == 6113 || window.rs_id_office == 1559) ? true : false)}
                                                                                                    onClick={() => handleShowMotivo(vistoria, 5, false, true)}
                                                                                                    animated
                                                                                                />
                                                                                            :'')}

                                                                                            {(!vistoria.status || vistoria.status == 2 ?
                                                                                                <Icon
                                                                                                    type="reprovar2"
                                                                                                    title={(vistoria.status == 2 ? 'Reprovada' : (window.rs_id_office == 6113 || window.rs_id_office == 1559 ? 'Aguardando avaliação do setor responsável' : 'Reprovar'))} // SE FOR CARGO "GESTÃO (8512)" OU "COMERCIAL (1559)"
                                                                                                    className={(vistoria.status == 2 ? 'text-danger' : '')}
                                                                                                    readonly={(vistoria.status == 2 || (window.rs_id_office == 6113 || window.rs_id_office == 1559) ? true : false)}
                                                                                                    onClick={() => handleShowMotivo(vistoria, 2, false, true)}
                                                                                                    animated
                                                                                                />
                                                                                            :'')}
                                                                                        </>
                                                                                    :'')}
                                                                                </Td>
                                                                            </Tr>
                                                                        </>
                                                                    )
                                                                })
                                                            :
                                                                <></>
                                                            )}
                                                        </Tbody>
                                                    </Table>
                                                :<></>)}

                                                {(vistoriasIntermediarias.length > 0 && (!loadingVistoriasIniciais && !loadingVistoriasIntermediarias && !loadingVistoriasFinais) ?
                                                    <Table className={(collapseVistoriasIntermediarias ? 'mb-4' : '')}>
                                                        <Tbody>                    
                                                            <Tr>
                                                                <Td>
                                                                    <b>Intermediária</b>
                                                                </Td>
                                                                <Td width={1} align="end">
                                                                    <Icon
                                                                        type={collapseVistoriasIntermediarias ? 'collapseOut' : 'collapseIn'}
                                                                        title={collapseVistoriasIntermediarias ? 'Minimizar' : 'Expandir'}
                                                                        onClick={() => setCollapseVistoriasIntermediarias(!collapseVistoriasIntermediarias)}
                                                                    />
                                                                </Td>
                                                            </Tr>

                                                            {(collapseVistoriasIntermediarias ?
                                                                vistoriasIntermediarias.filter((elem) => elem.mov_ativo == 1).map((vistoria, i) => {
                                                                    return(
                                                                        <>
                                                                            {(() => {                          
                                                                                if(categoriaIntermediaria != vistoria.categoria){                                                                       
                                                                                    return(
                                                                                        <Tr
                                                                                            key={'vistoria_intermediaria_categoria_'+i}
                                                                                            className={'bg_light ' + style.bg_light}
                                                                                        >
                                                                                            <Td colspan="100%">
                                                                                                <b>{vistoria.categoria}</b>
                                                                                            </Td>
                                                                                        </Tr>
                                                                                    )
                                                                                }                                                                                                         
                                                                            })()}

                                                                            {(() => {    
                                                                                categoriaIntermediaria = vistoria.categoria  
                                                                            })()}

                                                                            <Tr
                                                                                key={'vistoria_intermediaria_categoria_'+vistoria.value}
                                                                            >
                                                                                <Td>
                                                                                    {vistoria.subcategoria}
                                                                                </Td>
                                                                                <Td width={1}>
                                                                                    <Icon
                                                                                        type="message"
                                                                                        animated
                                                                                        onClick={() => handleShowCometarios(vistoria)}
                                                                                    />

                                                                                    {(window.rs_permission_apl === 'operador' ?
                                                                                        <>
                                                                                            {(vistoria.status == 1 ?
                                                                                                <Icon
                                                                                                    type="check"
                                                                                                    title={'Aprovada'}
                                                                                                    className={'text-success'}
                                                                                                    readonly={true}
                                                                                                    animated
                                                                                                />
                                                                                            :'')}

                                                                                            {(vistoria.status == 5 ?
                                                                                                <Icon
                                                                                                    type="exclamation"
                                                                                                    title={'Aprovada com ressalva'}
                                                                                                    className={'text-warning'}
                                                                                                    readonly={true}
                                                                                                    animated
                                                                                                />
                                                                                            :'')}

                                                                                            {(vistoria.status == 2 ?
                                                                                                <Icon
                                                                                                    type="reprovar2"
                                                                                                    title={'Reprovada'}
                                                                                                    className={'text-danger'}
                                                                                                    readonly={true}
                                                                                                    animated
                                                                                                />
                                                                                            :'')}
                                                                                        </>
                                                                                    :'')}
                                                                                    
                                                                                    {(window.rs_permission_apl === 'aprovador' || window.rs_permission_apl === 'master' ?
                                                                                        <>
                                                                                            {(!vistoria.status || vistoria.status == 1 ?
                                                                                                <Icon
                                                                                                    type="check"
                                                                                                    title={(vistoria.status == 1 ? 'Aprovada' : (window.rs_id_office == 6113 || window.rs_id_office == 1559 ? 'Aguardando avaliação do setor responsável' : 'Aprovar'))} // SE FOR CARGO "GESTÃO (8512)" OU "COMERCIAL (1559)"
                                                                                                    className={(vistoria.status == 1 ? 'text-success' : '')}
                                                                                                    readonly={(vistoria.status == 1 || (window.rs_id_office == 6113 || window.rs_id_office == 1559) ? true : false)}
                                                                                                    onClick={() => 
                                                                                                        changeStatusSupervisor(
                                                                                                            'Realizou uma vistoria',
                                                                                                            vistoria.id_job,
                                                                                                            vistoria.id_job_status,
                                                                                                            1,
                                                                                                            'Vistoria "'+vistoria.subcategoria+'"' + ' aprovada por ' + window.rs_name_usr,
                                                                                                            false,
                                                                                                            true
                                                                                                        )
                                                                                                    }
                                                                                                    animated
                                                                                                />
                                                                                            :'')}

                                                                                            {(!vistoria.status || vistoria.status == 5 ?
                                                                                                <Icon
                                                                                                    type="exclamation"
                                                                                                    title={(vistoria.status == 5 ? 'Aprovada com ressalva' : (window.rs_id_office == 6113 || window.rs_id_office == 1559 ? 'Aguardando avaliação do setor responsável' : 'Aprovar com ressalva'))} // SE FOR CARGO "GESTÃO (8512)" OU "COMERCIAL (1559)"
                                                                                                    className={(vistoria.status == 5 ? 'text-warning' : '')}
                                                                                                    readonly={(vistoria.status == 5 || (window.rs_id_office == 6113 || window.rs_id_office == 1559) ? true : false)}
                                                                                                    onClick={() => handleShowMotivo(vistoria, 5, false, true)}
                                                                                                    animated
                                                                                                />
                                                                                            :'')}

                                                                                            {(!vistoria.status || vistoria.status == 2 ?
                                                                                                <Icon
                                                                                                    type="reprovar2"
                                                                                                    title={(vistoria.status == 2 ? 'Reprovada' : (window.rs_id_office == 6113 || window.rs_id_office == 1559 ? 'Aguardando avaliação do setor responsável' : 'Reprovar'))} // SE FOR CARGO "GESTÃO (8512)" OU "COMERCIAL (1559)"
                                                                                                    className={(vistoria.status == 2 ? 'text-danger' : '')}
                                                                                                    readonly={(vistoria.status == 2 || (window.rs_id_office == 6113 || window.rs_id_office == 1559) ? true : false)}
                                                                                                    onClick={() => handleShowMotivo(vistoria, 2, false, true)}
                                                                                                    animated
                                                                                                />
                                                                                            :'')}
                                                                                        </>
                                                                                    :'')}
                                                                                </Td>
                                                                            </Tr>
                                                                        </>
                                                                    )
                                                                })
                                                            :
                                                                <></>
                                                            )}
                                                        </Tbody>
                                                    </Table>
                                                :<></>)}

                                                {(vistoriasFinais.length > 0 && (!loadingVistoriasIniciais && !loadingVistoriasIntermediarias && !loadingVistoriasFinais) ?
                                                    <Table>
                                                        <Tbody>
                                                            <Tr>
                                                                <Td>
                                                                    <b>Final</b>
                                                                </Td>
                                                                <Td width={1} align="end">
                                                                    <Icon
                                                                        type={collapseVistoriasFinais ? 'collapseOut' : 'collapseIn'}
                                                                        title={collapseVistoriasFinais ? 'Minimizar' : 'Expandir'}
                                                                        onClick={() => setCollapseVistoriasFinais(!collapseVistoriasFinais)}
                                                                    />
                                                                </Td>
                                                            </Tr>

                                                            {(collapseVistoriasFinais ?
                                                                vistoriasFinais.filter((elem) => elem.mov_ativo == 1).map((vistoria, i) => {
                                                                    return(
                                                                        <>
                                                                            {(() => {                          
                                                                                if(categoriaFinal != vistoria.categoria){                                                                       
                                                                                    return(
                                                                                        <Tr
                                                                                            key={'vistoria_final_categoria_'+i}
                                                                                            className={'bg_light ' + style.bg_light}
                                                                                        >
                                                                                            <Td colspan="100%">
                                                                                                <b>{vistoria.categoria}</b>
                                                                                            </Td>
                                                                                        </Tr>
                                                                                    )
                                                                                }                                                                                                         
                                                                            })()}

                                                                            {(() => {    
                                                                                categoriaFinal = vistoria.categoria  
                                                                            })()}

                                                                            <Tr
                                                                                key={'vistoria_final_subcategoria_'+vistoria.value}
                                                                            >
                                                                                <Td>
                                                                                    {vistoria.subcategoria}
                                                                                </Td>
                                                                                <Td width={1}>
                                                                                    <Icon
                                                                                        type="message"
                                                                                        animated
                                                                                        onClick={() => handleShowCometarios(vistoria)}
                                                                                    />

                                                                                    {(window.rs_permission_apl === 'operador' ?
                                                                                        <>
                                                                                            {(vistoria.status == 1 ?
                                                                                                <Icon
                                                                                                    type="check"
                                                                                                    title={'Aprovada'}
                                                                                                    className={'text-success'}
                                                                                                    readonly={true}
                                                                                                    animated
                                                                                                />
                                                                                            :'')}

                                                                                            {(vistoria.status == 5 ?
                                                                                                <Icon
                                                                                                    type="exclamation"
                                                                                                    title={'Aprovada com ressalva'}
                                                                                                    className={'text-warning'}
                                                                                                    readonly={true}
                                                                                                    animated
                                                                                                />
                                                                                            :'')}

                                                                                            {(vistoria.status == 2 ?
                                                                                                <Icon
                                                                                                    type="reprovar2"
                                                                                                    title={'Reprovada'}
                                                                                                    className={'text-danger'}
                                                                                                    readonly={true}
                                                                                                    animated
                                                                                                />
                                                                                            :'')}
                                                                                        </>
                                                                                    :'')}
                                                                                    
                                                                                    {(window.rs_permission_apl === 'aprovador' || window.rs_permission_apl === 'master' ?
                                                                                        <>
                                                                                            {(!vistoria.status || vistoria.status == 1 ?
                                                                                                <Icon
                                                                                                    type="check"
                                                                                                    title={(vistoria.status == 1 ? 'Aprovada' : (window.rs_id_office == 6113 || window.rs_id_office == 1559 ? 'Aguardando avaliação do setor responsável' : 'Aprovar'))} // SE FOR CARGO "GESTÃO (8512)" OU "COMERCIAL (1559)"
                                                                                                    className={(vistoria.status == 1 ? 'text-success' : '')}
                                                                                                    readonly={(vistoria.status == 1 || (window.rs_id_office == 6113 || window.rs_id_office == 1559) ? true : false)}
                                                                                                    onClick={() => 
                                                                                                        changeStatusSupervisor(
                                                                                                            'Realizou uma vistoria',
                                                                                                            vistoria.id_job,
                                                                                                            vistoria.id_job_status,
                                                                                                            1,
                                                                                                            'Vistoria "'+vistoria.subcategoria+'"'  + ' aprovada por ' + window.rs_name_usr,
                                                                                                            false,
                                                                                                            true
                                                                                                        )
                                                                                                    }
                                                                                                    animated
                                                                                                />
                                                                                            :'')}

                                                                                            {(!vistoria.status || vistoria.status == 5 ?
                                                                                                <Icon
                                                                                                    type="exclamation"
                                                                                                    title={(vistoria.status == 5 ? 'Aprovada com ressalva' : (window.rs_id_office == 6113 || window.rs_id_office == 1559 ? 'Aguardando avaliação do setor responsável' : 'Aprovar com ressalva'))} // SE FOR CARGO "GESTÃO (8512)" OU "COMERCIAL (1559)"
                                                                                                    className={(vistoria.status == 5 ? 'text-warning' : '')}
                                                                                                    readonly={(vistoria.status == 5 || (window.rs_id_office == 6113 || window.rs_id_office == 1559) ? true : false)}
                                                                                                    onClick={() => handleShowMotivo(vistoria, 5, false, true)}
                                                                                                    animated
                                                                                                />
                                                                                            :'')}

                                                                                            {(!vistoria.status || vistoria.status == 2 ?
                                                                                                <Icon
                                                                                                    type="reprovar2"
                                                                                                    title={(vistoria.status == 2 ? 'Reprovada' : (window.rs_id_office == 6113 || window.rs_id_office == 1559 ? 'Aguardando avaliação do setor responsável' : 'Reprovar'))} // SE FOR CARGO "GESTÃO (8512)" OU "COMERCIAL (1559)"
                                                                                                    className={(vistoria.status == 2 ? 'text-danger' : '')}
                                                                                                    readonly={(vistoria.status == 2 || (window.rs_id_office == 6113 || window.rs_id_office == 1559) ? true : false)}
                                                                                                    onClick={() => handleShowMotivo(vistoria, 2, false, true)}
                                                                                                    animated
                                                                                                />
                                                                                            :'')}
                                                                                        </>
                                                                                    :'')}
                                                                                </Td>
                                                                            </Tr>
                                                                        </>
                                                                    )
                                                                })
                                                            :
                                                                <></>
                                                            )}
                                                        </Tbody>
                                                    </Table>
                                                :'')}

                                                {(vistoriasIniciais && vistoriasIntermediarias && vistoriasFinais ? 
                                                    (vistoriasIniciais.filter((elem) => elem.mov_ativo == 1).length == 0 &&
                                                    vistoriasIntermediarias.filter((elem) => elem.mov_ativo == 1).length == 0 &&
                                                    vistoriasFinais.filter((elem) => elem.mov_ativo == 1).length == 0 && 
                                                    !loadingVistoriasIniciais && !loadingVistoriasIntermediarias && !loadingVistoriasFinais ?
                                                        <Table>
                                                            <Tbody>
                                                                <Tr>
                                                                    <Td colspan="100%">
                                                                        Nenhuma vistoria cadastrada
                                                                    </Td>
                                                                </Tr>
                                                            </Tbody>
                                                        </Table>
                                                    :
                                                        <></>
                                                    )
                                                : <></>)}
                                            </div>
                                        </div>
                                    </div>
                                </Col>
                            :'')}

                            <Col xl={4}>
                                <div className="d-flex flex-column h-100 w-100" id={'atualizacoes_'+props?.obra?.id_job_status}>
                                    <div className={style.table_container + ' ' + style.no_border}>
                                        <div className={style.table_container_title}>
                                            <span>
                                                Atualizações
                                            </span>
                                            {(!loadingAtualizacoes ?
                                                <span>
                                                    <span className="span-obras-novos">
                                                        {atualizacoes.length}
                                                    </span>
                                                </span>
                                            :'')}
                                        </div>
                                        <div className={style.table_container_body}>
                                            {(loadingAtualizacoes ?
                                                <Table>
                                                    <Tr>
                                                        <Td colspan="100%">
                                                            <Loader />
                                                        </Td>
                                                    </Tr>
                                                </Table>
                                            :
                                                <Chat
                                                    send={false}
                                                    className={style.chat}
                                                    maxHeight={colDoc}
                                                >
                                                    {(atualizacoes.length > 0 ?
                                                        atualizacoes.map((atualizacao, i) => {
                                                            return(
                                                                <Message
                                                                    key={'atualizacao_'+atualizacao.id}
                                                                    sender={atualizacao.name_usr}
                                                                    date={(atualizacao?.cad ? cdh(atualizacao?.cad) : '')}
                                                                    text={atualizacao?.mensagem}
                                                                    align={(window.rs_id_usr == atualizacao.id_usr?'right':'left')}
                                                                />
                                                            )
                                                        })
                                                    :
                                                        <CardAtualizacoes
                                                            text_style="disabled"
                                                            paragraph={'Nenhuma atualização recente'}
                                                            empty={true}
                                                        />
                                                    )}
                                                </Chat>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Td>
            </Tr>
        </>
    )
}
