import { useState, useEffect } from "react";
import { cdh, get_date } from "../../../_assets/js/global";
import Icon from "../../../components/body/icon"
import SelectReact from "../../../components/body/select";
import Input from "../../../components/body/form/input";
import FilterCheckbox from "../../../components/body/filterCheckbox";
import Semanal from "./semanal";
import Mensal from "./mensal";
// import axios from '../lib/axios'
import axios from "axios";
import { cd } from "../../../_assets/js/global";



export default function Lancamento({ filters, icons }) {

    //ESTADOS FILTRO
    const [status, setStatus] = useState(1);
    const [funcionarios, setFuncionarios] = useState('');
    const [filterMonth, setFilterMonth] = useState(new Date(window.currentYear, window.currentMonth - 1, '01'));
    const [mes, setMes] = useState(window.currentMonth);
    const [ano, setAno] = useState(window.currentYear)
    const [loja, setLoja] = useState(10567);
    const [unidade, setUnidade] = useState('');
    const [tipo, setTipo] = useState(1);
    const [filterDataInicio, setFilterDataInicio] = useState(new Date(window.currentYear, window.currentMonth - 1, '01'));
    const [filterDataFim, setFilterDataFim] = useState(new Date(window.currentYear, window.currentMonth - 1, get_date('last_date', new Date(window.currentYear, window.currentMonth - 1, window.currentDay)).slice(0, 2)));
    //API
    const [lancamento, setLancamento] = useState('');
    const [loading, setLoading] = useState(true)


    // ->
    const handleLoja = (e) => {
        setLoja(e)
    }

    //FILTRO DE MÊS
    const handleMes = (e) => {
        setFilterMonth(e);
        let arr_aux = cd(e).split("/");
        setMes(arr_aux[1]);
        setAno(arr_aux[2]);
    }

    //PEGAR LISTA DE LANÇAMENTO
    function getLancamento() {
        // const token = localStorage.getItem('token');
        axios({
            url: "https://backend.madnezz.com.br/api/v1/gis/lancamento",
            params: {
                loja_id: loja,
                month: mes ? mes : window.currentMonth,
                year: ano ? ano : window.currentYear,
            },
            // headers: {
            //     Authorization: `Bearer ${token}`,
            // },
        }).then((response) => {
            setLancamento(response?.data?.data);
            setLoading(false)
        })
    }
    const callback = () => { getLancamento(); }
    useEffect(() => {
        getLancamento();
    }, [mes, loja]);

    // FILTROS E ÍCONES 
    useEffect(() => {
        if (icons) {
            icons(
                <Icon type="print" />
            )
        }
        if (filters) {
            filters(
                <>
                    {/*  precisa de permissão */}
                    <SelectReact
                        placeholder="Período"
                        value={tipo}
                        onChange={(e) => setTipo(e.value)}
                        options={[
                            { value: 1, label: "Mensal" },
                            { value: 2, label: "Semanal" },
                        ]}
                    />
                    {(
                        window.rs_permission_apl === '' ?
                            // <FilterCheckbox
                            //     id="filter_store"
                            //     name="filter_store"
                            //     api={{
                            //         url: window.host + '/systems/integration-react/api/list.php?do=headerFilter&token=' + window.token,
                            //         params: {
                            //             filters: [{ filter: 'store' }],
                            //             // empreendimento_id: filterEmpreendimento,
                            //             limit: 50,
                            //             np: true
                            //         },
                            //         key: 'store',
                            //         //    reload: filterEmpreendimento
                            //     }}
                            //     onChangeClose={handleLoja}
                            //     //  hide={(tipoCalendario == 3 && subTipoCalendario == 'store') || tipoCalendario == 2 ? false : (((window.rs_permission_apl === 'lojista' || window.rs_permission_apl === 'operador' || window.rs_permission_apl === 'gerente' || window.rs_permission_apl === 'checker')) || (tipoCalendario != 3 && tipoCalendario != 2) || subTipoCalendario == 'user' ? true : false)}
                            //     value={loja}
                            // >
                            //     Lojas
                            // </FilterCheckbox>
                            <SelectReact
                                // label="Loja"
                                value={loja}
                                onChange={(e) => setLoja(e.value)}
                                options={[
                                    { value: 58124, label: "Loja 39" }, { value: 58125, label: "Loja 40" }, { value: 60115, label: "Loja 1000" }, { value: 60117, label: "Loja 1001" }, { value: 10567, label: "Hope" },
                                    { value: 60209, label: "Camicado" }, { value: 75769, label: "Reserva" }, { value: 75772, label: "Fastshop" }, { value: 75772, label: "Metria" },
                                    { value: 76198, label: "Américas" }, { value: 76199, label: "Spoleto" }, { value: 76200, label: "Ofner" }, { value: 76203, label: "China in Box" }, { value: 78226, label: "Vivo" }
                                ]}
                            />
                            : <></>
                    )}
                    {/*  precisa de permissão */}
                    {(
                        window.rs_permission_apl === '' ?
                            <FilterCheckbox>
                                Unidade
                            </FilterCheckbox>
                            : <></>
                    )}
                    {/*  precisa de permissão */}
                    {(
                        window.rs_permission_apl === '' ?
                            <SelectReact
                                placeholder="Status"
                                value={status}
                                onChange={(e) => setStatus(e.value)}
                                options={[
                                    { value: 1, label: "Todos" },
                                    { value: 2, label: "Enviados" },
                                    { value: 3, label: "Pendentes" },
                                ]}
                            />
                            : <></>
                    )}
                    <Input
                        type="date"
                        format="mm/yyyy"
                        name="filter_date"
                        required={false}
                        value={filterMonth}
                        onChange={(e) => handleMes(e)}
                    />
                </>
            )
        }
    }, [status, funcionarios, filterDataInicio, filterDataFim, tipo, loja, filterMonth]);

    // filtrar por status
    return (
        <>
            {
                tipo == 2 ?
                    <Semanal
                        ano={ano}
                        mes={mes}
                        lancamento={lancamento}
                        loja={loja}
                        callback={callback}
                        loading={loading}
                        status={status}
                    />
                    :
                    <Mensal
                        ano={ano}
                        mes={mes}
                        lancamento={lancamento}
                        loja={loja}
                        callback={callback}
                        loading={loading}
                        status={status}
                    />
            }
        </>
    );
}
