import style from './item.module.scss';

import { useState } from "react";
import Tr from "../../../../components/body/table/tr";
import Td from "../../../../components/body/table/tbody/td";
import Icon from "../../../../components/body/icon";
import Detalhes from "./Detalhes";
import { cd, diffDays, get_date } from "../../../../_assets/js/global";
import { useNavigate } from 'react-router-dom';
import Historico from './Historico';
import { useEffect } from 'react';
import axios from 'axios';
import SelectReact from '../../../../components/body/select';
import { toast } from 'react-hot-toast';
import Tippy from '@tippyjs/react';

export default function Item(props){
    // ESTADOS
    const [active, setActive] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [showHistory, setShowHistory] = useState(false);
    const [id, setId] = useState('');
    const [idJobStatus, setIdJobStatus] = useState('');
    const [lote, setLote] = useState('');
    const [obra, setObra] = useState('');
    const [reprovados, setReprovados] = useState(props?.obra?.qtd_doc_reprovado_sup);
    const [aprovadosRessalva, setAprovadosRessalva] = useState(props?.obra?.qtd_doc_aprovado_com_ressalva_sup);
    const [aprovados, setAprovados] = useState(props?.obra?.qtd_doc_aprovado_sup);
    const [pendentes, setPendentes] = useState(props?.obra?.qtd_doc_pendente_sup);
    const [total, setTotal] = useState(props?.obra?.qtd_doc_total);
    const [status, setStatus] = useState(props?.obra?.mov_id_status);

    // FUNÇÃO PARA ABRIR DETALHES
    function handleShowDetails(id, lote, id_job_status){
        setShowDetails(!showDetails);
        setShowHistory(false);     
        setId(id);
        setLote(lote);
        setIdJobStatus(id_job_status);

        if(showDetails){ // VERIFICA SE A OBRA ESTÁ ABERTA E MANDA O RETORNO DE QUE FOI FECHADA PARA O COMPONENTE PAI
            if(props?.onClose){
                props?.onClose();
            }
        }
    }

    // FUNÇÃO PARA ABRIR HISTÓRICO DE DOCUMENTOS
    function handleShowHistory(id){
        setShowHistory(!showHistory);
        setId(id);
        setShowDetails(false);
    }

    useEffect(() => {
        if(showDetails || showHistory){
            setActive(true);
            props.active(true);
        }

        if(!showDetails && !showHistory){
            setActive(false);
            props.active(false);
        }
    },[showDetails, showHistory]);
    
    // NAVIGATE
    const navigate = useNavigate();

    var qualiColor = props?.obra?.qualificacao;
    switch (qualiColor) {
        case "Crítica":
            qualiColor = "btn-quali red";
            break;
        case "Loja Estruturada":
            qualiColor = "btn-quali blue";
            break;
        case "Iniciada":
            qualiColor = "btn-quali green";
            break;
        case "Mínima":
            qualiColor = "btn-quali gray";
            break
        default:
            qualiColor = "btn-quali gray";
    }

    // OPTIONS VISTORIAS
    const optionsVistorias = [
        {value: 1, label: 'Inicial'},
        {value: 2, label: 'Intermediária'},
        {value: 3, label: 'Final'}
    ]

    // CALCULO DIAS INICIADO
    let dias = diffDays(window.currentDateWithoutHour, props?.obra?.data_job);

    if(dias == 0){
        if(window.currentDateWithoutHour == props?.obra?.data_job){
            dias = 'Hoje';
        }else{
            dias = 'Ontem';
        }
    }else if(dias == 1){
        dias = 'Ontem';
    }else if(dias < 0){
        dias = 'Há '+(dias * -1)+' dias';
    }else if(dias > 1){
        dias = 'Há '+dias+' dias';
    }

    // CALCULA DIAS ATRASADOS
    let atrasado
    if(props?.obra?.data_previsao){
        atrasado = diffDays(window.currentDate, props?.obra?.data_previsao);
 
        if(atrasado < 0){
            atrasado = 'Faltam ' + (atrasado * -1) + ' dia(s)';
        }else if(atrasado == 0){
            atrasado = 'Previsão para hoje';
        }else if(atrasado > 0){
            atrasado = atrasado + ' dias atrasado';
        }
    }else{
        atrasado = 'Sem previsão';
    }

    // DEFINE COR DA QUALIFICAÇÃO
    let corQualificacao;
    if(props?.obra?.mov_qualificacao == 'Crítica'){
        corQualificacao = '#E55050';
    }else if(props?.obra?.mov_qualificacao == 'Evento Temporário'){
        corQualificacao = '#3186C3';
    }else if(props?.obra?.mov_qualificacao == 'Inicial'){
        corQualificacao = '#51C07D';
    }else if(props?.obra?.mov_qualificacao == 'Loja estruturada'){
        corQualificacao = '#94A3B8';
    }else{
        corQualificacao = '#94A3B8';
    }

    // RECARREGAR ITEM
    const refreshItem = () => {
        axios({
            method: 'get',
            url: window.host+"/systems/integration-react/api/list.php?do=get_select&token="+window.token,
            params: {
                filter_type: '5',
                id_job_status: props?.obra?.id_job_status
            }
        }).then((response) => {
            if(response.data){
                setReprovados(response.data[0].qtd_doc_reprovado_sup);
                setAprovadosRessalva(response.data[0].qtd_doc_aprovado_com_ressalva_sup);
                setAprovados(response.data[0].qtd_doc_aprovado_sup);
                setPendentes(response.data[0].qtd_doc_pendente_sup);
                setTotal(response.data[0].qtd_doc_total);
            }
        });
    }

    // TROCA STATUS
    function handleSetStatus(e){
        setStatus(e.value);

        axios({
            method: 'post',
            url: window.host+"/systems/integration-react/api/registry.php?do=set_status_construction&token=" + window.token,
            headers: {'Content-Type': 'application/x-www-form-urlencoded'},
            data: {
                lote: props?.obra?.lote,
                id_job: props?.obra?.id_job,
                id_status: e.value
            }
        }).then(() => {
            toast('Status alterado com sucesso');
        })
    }

    // EXCLUIR OBRA
    function handleDelete(){
        if(window.confirm('Tem certeza que deseja excluir a obra?')){
            axios({
                method: 'post',
                url: window.host+"/systems/integration-react/api/registry.php?do=set_registry_active&token=" + window.token,
                headers: {'Content-Type': 'application/x-www-form-urlencoded'},
                data: {
                    lote: props?.obra?.lote,
                    id_job: props?.obra?.id_job,
                    ativo: 0
                }
            }).then(() => {
                toast('Obra excluída com sucesso');
                if(props?.callback){
                    props.callback(true);
                }
            })
        }
    }

    let vistoria = 'Pendente';

    if(props?.obra?.qtd_vis_initial_realizada > 0){
        vistoria = 'Inicial';
    }
    if(props?.obra?.qtd_vis_intermediate_realizada > 0){
        vistoria = 'Intermediária';
    }
    if(props?.obra?.qtd_vis_final_realizada > 0){
        vistoria = 'Final';
    }

    // PROGRESSO
    let porcentagem, color, colorClass;

    if(props?.obra?.data_inicio && props?.obra?.data_previsao){
        let data_inicio = props?.obra?.data_inicio;
        let data_previsao = props?.obra?.data_previsao;
        let data_atual = window.currentDateWithoutHour;
        let dif_previsao = diffDays(data_previsao, data_inicio);
        let dif_atual = diffDays(data_atual, data_inicio);
        porcentagem = (dif_atual * 100) / dif_previsao;

        if(porcentagem > 100){
            porcentagem = 100;
        }

        if(porcentagem < 50){
            color = 'bg-success';
            colorClass = style.bgSuccess;
        }else if(porcentagem >= 50 && porcentagem < 75){
            color = 'bg-warning';
            colorClass = style.bgWarning;
        }else if(porcentagem >= 75){
            color = 'bg-danger';
            colorClass = style.bgDanger;
        }else{
            color = '';
        }
    }else{
        porcentagem = 0;
        color = '';
    }

    let nova = false;

    if(props?.obra?.data_job == window.currentDateWithoutHour){
        nova = true;
    }else{
        nova = false;
    }

    // ABRE POR PADRÃO A OBRA PASSADA NA PROPS OBRAACTIVED (PARA USUÁRIOS LOJISTAS JÁ TEREM A OBRA DELE ABERTA)
    useEffect(() => {
        if(props?.obraActived){
            if(props?.obraActived == props?.obra?.id_job_status){
                handleShowDetails(props?.obra?.id_job, props?.obra?.lote, props?.obra?.id_job_status);
                setObra(props?.obra);
            }
        }
    },[props?.obraActived]);

    return(
        <>
            {/* LISTA DE OBRAS */}            
            <Tr
                key={props?.obra?.id_job_status}
                id={'tr_'+props?.obra?.id_job_status}
                className={(active ? (style.active + ' ' + colorClass) : '') + ' ' + (props?.inactive && !active ? style.inactive : '') + ' ' + (porcentagem > 75 ? style.bg_alert : '')}                
            >
                <Td className="td-obras-bold">
                    {props?.obra?.loja} 
                    
                    {(nova ? 
                        <span className={style.badge}>
                            Nova
                        </span>
                    :'')}

                    <p className="p-obras">
                        {(props?.obra?.mov_tipo ? props.obra.mov_tipo : '')}
                        {(props?.obra?.mov_status ? ' - ' + props.obra.mov_status : '')}
                        {(props?.obra?.luc_loja ? ' - LUC ' + props.obra.luc_loja: '')}
                    </p>
                </Td>

                <Td className="td-obras" mobile={false}>
                    {dias}
                    <p className="p-obras">
                        {atrasado}    
                    </p>
                </Td>

                <Td className="td-obras" mobile={false}>
                    {props?.obra?.mov_formato}
                </Td>

                <Td className="td-obras" mobile={false}>
                    <span className={'btn-quali'} style={{backgroundColor:corQualificacao}}>
                        {(props?.obra?.mov_qualificacao ? props?.obra?.mov_qualificacao : 'Sem qualificação')}
                    </span>
                </Td>

                <Td className="td-obras" mobile={false}>
                    <Tippy content="Reprovados">
                        <span className={'btn-doc text-danger'}>
                            {reprovados}
                        </span>
                    </Tippy>

                    <Tippy content="Aprovados com ressalva">
                        <span className={'btn-doc text-warning'}>
                            {aprovadosRessalva}
                        </span>
                    </Tippy>

                    <Tippy content="Aprovados/Total">
                        <span className={'btn-doc text-success'}>
                            {aprovados}/{total}
                        </span>
                    </Tippy>

                    {(props?.obra?.qtd_doc_pendente_sup && pendentes != 0 && (window.rs_permission_apl === 'aprovador' || window.rs_permission_apl === 'master') ? 
                        <Tippy content="Novos documentos">
                            <span className={'obras-badge'}>
                                {pendentes}
                            </span>
                        </Tippy>
                    :'')}

                    {(props?.obra?.qtd_doc_reprovado_sup && reprovados != 0 && window.rs_permission_apl === 'operador' ? 
                        <Tippy content="Alguns documentos requer atenção">
                            <span className={style.badge}>
                                Documento(s) reprovado(s)
                            </span>
                        </Tippy>
                    :'')}
                </Td>

                {/* <Td className="td-obras">
                    {vistoria}
                </Td> */}
                
                <Td className="td-obras" mobile={false}>
                    {(window.rs_permission_apl === 'aprovador' || window.rs_permission_apl === 'master' ?
                        <SelectReact
                            options={props?.options?.status}
                            value={status}
                            onChange={(e) => handleSetStatus(e)}
                            className={style.select}
                        />
                    :
                        <span className={'obras-status-span'}>
                            {props?.obra?.mov_status}
                        </span>
                    )}
                </Td>

                <Td className="td-obras hide-print" width={1}>
                    <Icon
                        type="view"
                        animated
                        active={showDetails}
                        title={(showDetails ? 'Ocultar detalhes' : 'Ver detalhes')}
                        onClick={() => (
                            handleShowDetails(props?.obra?.id_job, props?.obra?.lote, props?.obra?.id_job_status),
                            setObra(props?.obra)
                        )}
                    />

                    <Icon
                        type="history"
                        title="Histórico"
                        animated
                        active={showHistory}
                        onClick={() => handleShowHistory(props?.obra?.id_job)}
                    />

                    {/* SE FOR DO CARGO "GESTÃO" OU MASTER E NÃO FOR LOJISTA */}
                    {(window.rs_id_office == 6113 || window.rs_permission_apl === 'master' ? 
                        <Icon
                            type="edit"
                            title="Editar"
                            animated
                            onClick={() => navigate('/systems/obras-react/gerenciador/'+window.token+'/'+props?.obra?.id_loja+'/'+props?.obra?.lote+'/'+props?.obra?.id_job)}
                        />
                    :'')}

                    {/* SE FOR DO CARGO "GESTÃO" OU MASTER E NÃO FOR LOJISTA */}
                    {(window.rs_id_office == 6113 || window.rs_permission_apl === 'master' ? 
                        <Icon
                            type="trash"
                            title="Excluir"
                            animated
                            onClick={() => handleDelete()}
                        />
                    :'')}
                </Td>
            </Tr>

            {/* DETALHES DA OBRA */}
            {(showDetails ?                 
                <Detalhes
                    id={id}
                    id_job_status={idJobStatus}
                    lote={lote}
                    show={showDetails}
                    obra={obra}
                    callback={refreshItem}
                    progress={{
                        percentage: porcentagem,
                        color: color
                    }}
                />        
            :'')}

            {/* HISTÓRICO DE DOCUMENTOS */}
            {(showHistory ?                 
                <Historico
                    id={id}
                    id_job_status={idJobStatus}
                    lote={lote}
                    show={showHistory}
                    obra={obra}
                    callback={refreshItem}
                    progress={{
                        percentage: porcentagem,
                        color: color
                    }}
                />        
            :'')}
        </>
    )
}
