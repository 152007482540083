import { useState } from 'react';
import { cdh } from '../../../../_assets/js/global';

import style from '../treinamento.module.scss';
import Button from '../../components/button';
import axios from 'axios';
import { toast } from 'react-hot-toast';
import { useEffect } from 'react';

export default function File(props) {
    // VARIAVEIS
    var pageTotal = 0;
    if(props.videos){
        pageTotal = props.videos.split(',').length;
    }else{
        pageTotal = props.images.length;
    }

    // ESTADOS
    const [page, setPage] = useState(0);
    const [finished, setFinished] = useState(false);
    const [dataConclusao, setDataConclusao] = useState('');
    const [questionario, setQuestionario] = useState(props.quiz);

    // CONCLUIR TREINAMENTO
    function handleConcluir(){
        axios({
            method: 'post',
            url: window.host+'/systems/treinamento/api/lista.php?token='+window.token,
            data: {
                do: 'respondeQuestionario',
                loja_id: (window.rs_id_lja && window.rs_id_lja != 0 ? window.rs_id_lja : ''),
                usuario_id: window.rs_id_usr,
                curso: props.id,
                visualizacao: props.reload
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then(() => {
            toast('Treinamento concluído com sucesso!');
            handleCheckUser();
        }).catch((error) => {
            toast('Não foi possível concluir o treinamento, tente novamente mais tarde');
        });
    }

    // CALLBACK PARA ABRIR QUESTIONÁRIO
    function handleQuestionario(){
        props.callback(true);
    }

    // VERIFICA SE USUÁRIO CONCLUIU TREINAMENTO
    function handleCheckUser(){    
        setFinished(false);

        axios({
            method: 'post',
            url: window.host+'/systems/treinamento/api/lista.php?token='+window.token,
            data: {
                do: 'questionarioUsuario',
                curso: props.id,
                usuario_id: window.rs_id_usr,
                loja_id: (window.rs_id_lja && window.rs_id_lja != 0 ? window.rs_id_lja : ''),
                data_fim: '',
                doAgain: props.reload
            },
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' }
        }).then((response) => {
            if(response.data){
                if(response.data.row_user[0]){
                    if(response.data.row_user[0].status == 'Finalizado'){
                        setFinished(true);
                        setDataConclusao(response.data.row_user[0].data_finalizado);
                    }else if(response.data.row_user[0].status == 'Pendente'){
                        setFinished(false);
                        setDataConclusao('');
                    }else{
                        setFinished(false);
                        setDataConclusao('');
                    } 
                }
            }
        }).catch((error) => {
            toast('Não foi possível concluir o treinamento, tente novamente mais tarde');
        });
    }

    useEffect(() => {
        handleCheckUser();
    },[props.id, props.reload]);

    return (
        <>
            <div>
                {/* VÍDEOS */}
                {(props.videos ?
                    (props.videos.split(',').map((video, i) => {
                        return(
                            <div className={style.video} style={(page != i ? {display:'none'} : {})}>
                                <iframe
                                    src={video}
                                ></iframe>
                            </div>
                        )
                    }))
                :'')}
                
                {/* PDF (IMAGENS) */}
                {(props.images && props.images.length > 0 ?
                    props.images.map((image, i) => {
                        return(
                            <div className={style.page} id={'page_'+i} style={(page != i ? {display:'none'} : {})}>
                                <img src={window.host+'/upload/Fastview/treinamento/'+props.id+'/'+image.anexo+'/'+image.imagem} className="w-auto mw-100" />
                            </div>
                        )
                    })
                :'')}

                {/* GOOGLE DOCS (PDF) */}
                {(props.anexos && (!props.images || props.images.length == 0) ?
                    (props.anexos.split(',').map((anexo, i) => {
                        return(
                            <>
                                <div className={style.docs} style={(page != i ? {display:'none'} : {})}>
                                    <iframe                                    
                                        src={'https://docs.google.com/gview?url='+process.env.REACT_APP_URL_UPLOAD+'/'+anexo+'&embedded=true'}
                                    ></iframe>
                                </div>
                            </>
                        )
                    }))
                :'')}
            </div>

            <div className="my-4">
                {/* DESCRIÇÃO DO TREINAMENTO */}
                {(props.description ? 
                    <p className={style.info + ' mt-3'}>
                        {props.description}
                    </p>
                :'')}

                {/* CASO O IFRAME NÃO ABRA COM O ANEXO, A MENSAGEM ABAIXO É UMA ALTERNATIVA DE VISUALIZAR O DOCUMENTO */}
                {(props.anexos && (!props.images || props.images.length == 0) ?
                    <p className={style.info + ' mb-0'}>
                        <a href={process.env.REACT_APP_URL_UPLOAD+'/'+props.anexos.split(',')[0]} target="new"><b>Clique aqui</b></a> caso o documento não seja exibido.
                    </p>
                :'')}

                {/* INFORMAÇÃO DE PÁGINA ATUAL CASO SEJA VÍDEO OU PDF */}
                {(props.videos || props.images.length > 0 ?
                    <p className={style.info + ' mt-3'}>
                        {(props.videos ? 'Vídeo ' : 'Página ')}
                        {(page+1) + '/' + pageTotal}
                    </p>
                :'')}

                {/* BOTÃO DE VOLTAR PÁGINA/VÍDEO */}
                {(page != 0 ?
                    <Button
                        onClick={() => (
                            window.scrollTo(0, 0),
                            setTimeout(() => {
                                setPage(page - 1)
                            },(props.videos ? 0 : (window.isMobile ? 0 : 500)))
                        )}
                    >
                        {(props.videos ? 'Anterior' : 'Voltar')}
                    </Button>
                :'')}

                {/* BOTÃO DE AVANÇAR PÁGINA/VÍDEO */}
                {((page + 1) < pageTotal ?
                    <Button
                        onClick={() => (
                            window.scrollTo(0, 0),
                            setTimeout(() => {
                                setPage(page + 1)
                            },(props.videos ? 0 : (window.isMobile ? 0 : 500)))
                        )}
                    >
                        {(props.videos ? 'Próximo' : 'Avançar')}
                    </Button>
                :'')}

                {/* BOTÃO DE CONCLUIR TREINAMENTO */}
                {((page + 1) == pageTotal && !questionario ? // QUANDO CHEGAR NA ÚLTIMA PÁGINA E NÃO TIVER QUESTIONÁRIO
                    <Button
                        finished={finished}
                        onClick={() => handleConcluir()}
                    >
                        {(finished ? 'Concluído' : 'Concluir')}
                    </Button>
                :'')}

                {/* BOTÃO DE INICIAR QUESTIONÁRIO */}
                {((page + 1) == pageTotal && questionario ? // QUANDO CHEGAR NA ÚLTIMA PÁGINA E TIVER QUESTIONÁRIO
                    <Button
                        onClick={() => handleQuestionario()}
                    >
                        Iniciar questionário
                    </Button>
                :'')}

                {/* INFORMAÇÃO DE DATA DE CONCLUSÃO */}
                {(finished && (page + 1) == pageTotal ? 
                    <p className={style.info + ' mt-3'}>
                        Concluído em: {cdh(dataConclusao)}
                    </p>
                :'')}
            </div>
        </>
    )
}