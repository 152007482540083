import { useState, useEffect, useContext, cloneElement } from "react"
import { GlobalContext } from "../../../context/Global";
import { ChecklistContext } from "../../../context/Checklist";
import { cd, cdh } from '../../../_assets/js/global.js';
import Title from '../../../components/body/title';
import Table from '../../../components/body/table';
import Thead from '../../../components/body/table/thead';
import Tbody from '../../../components/body/table/tbody';
import Tr from '../../../components/body/table/tr';
import Th from '../../../components/body/table/thead/th';
import Td from '../../../components/body/table/tbody/td';
import Icon from '../../../components/body/icon';
import Row from '../../../components/body/row';
import Col from '../../../components/body/col';
import Container from '../../../components/body/container';
import FilterCheckbox from "../../../components/body/filterCheckbox";

// MODAIS
import CadastroChecklist from "./cadastro";
import Respostas from "./respostas";
import Editar from "../../../components/body/card/editar";

export default function Lista({ icons, filters }) {

    // CONTEXT
    const {
        handleSetFilterEmpreendimento,
        filterEmpreendimento,
        filterModule,
    } = useContext(ChecklistContext);

    // NECESSÁRIO PARA FUNCIONAR O CARREGAMENTO DA LISTA AO ENTRAR NA TELA (PRECISA AJUSTAR)
    useEffect(() => {
        handleSetFilter(true);
    }, []);

    // CONTEXT
    const { handleSetFilter } = useContext(GlobalContext);

    //ESTADOS DA API
    const [check, setCheck] = useState([]);
    const [dados, setDados] = useState([]);
    //ESTADOS
    const [hide, setHide] = useState(true);
    const [showModalCadastro, setShowModalCadastro] = useState(false);
    const [showModalRespostas, setShowModalRespostas] = useState(false);
    const [reload, setReload] = useState(true);
    const [plano, setPlano] = useState(false);
    const [tituloPlano, setTituloPlano] = useState('');
    const [lojaPlano, setLojaPlano] = useState('');
    const [usuarioPlano, setUsuarioPlano] = useState('');

    // OPTIONS FILTROS
    const optionsStatus = [
        { value: -1, label: "Removido" },
        { value: 2, label: "Em Andamento" },
        { value: 1, label: "Finalizado" }
    ]
    const [relatorioId, setRelatorioId] = useState('');
    const [checklistId, setChecklistId] = useState('');
    const [checklistNome, setChecklistNome] = useState('');
    const [lojaId, setLojaId] = useState('');
    const [checklistStatus, setChecklistStatus] = useState('');
    const [respostaStatus, setRespostaStatus] = useState('');

    //ESTADOS DE BUSCA DO FILTRO
    const [statusValue, setStatusValue] = useState([]);
    const [empreendimentoValue, setEmpreendimentoValue] = useState([]);
    const [sistemaValue, setSistemaValue] = useState('');
    const [checkValue, setCheckValue] = useState('');
    const [respondidoValue, setRespondidoValue] = useState('');
    const [lojaValue, setLojaValue] = useState([]);
    const [dataInicio, setDataInicio] = useState('');
    const [dataFim, setDataFim] = useState('');
    const [finalDataInicio, setFinalDataInicio] = useState('');
    const [finalDataFim, setFinalDataFim] = useState('');
    const [avaliacao, setAvaliacao] = useState('');
    const [filterDepartamento, setFilterDepartamento] = useState([]);
    const [filterCategory, setFilterCategory] = useState([]);
    const [filterSubCategory, setFilterSubCategory] = useState([]);

    const [pergunta1, setPergunta1] = useState(null);
    const [resposta1, setResposta1] = useState(null);
    const [observacao1, setObservacao1] = useState(null);

    const [pergunta2, setPergunta2] = useState(null);
    const [resposta2, setResposta2] = useState(null);
    const [observacao2, setObservacao2] = useState(null);

    const [pergunta3, setPergunta3] = useState(null);
    const [resposta3, setResposta3] = useState(null);
    const [observacao3, setObservacao3] = useState(null);

    const [pergunta4, setPergunta4] = useState(null);
    const [resposta4, setResposta4] = useState(null);
    const [observacao4, setObservacao4] = useState(null);

    const [pergunta5, setPergunta5] = useState(null);
    const [resposta5, setResposta5] = useState(null);
    const [observacao5, setObservacao5] = useState(null);

    const [pergunta6, setPergunta6] = useState(null);
    const [resposta6, setResposta6] = useState(null);
    const [observacao6, setObservacao6] = useState(null);

    const [pergunta7, setPergunta7] = useState(null);
    const [resposta7, setResposta7] = useState(null);
    const [observacao7, setObservacao7] = useState(null);

    const [pergunta8, setPergunta8] = useState(null);
    const [resposta8, setResposta8] = useState(null);
    const [observacao8, setObservacao8] = useState(null);

    const [pergunta9, setPergunta9] = useState(null);
    const [resposta9, setResposta9] = useState(null);
    const [observacao9, setObservacao9] = useState(null);

    const [pergunta10, setPergunta10] = useState(null);
    const [resposta10, setResposta10] = useState(null);
    const [observacao10, setObservacao10] = useState(null);


    // console.log(check.map((item) => item.pergunta1))

    // LISTA ITENS
    const handleSetItems = (e) => {
        setCheck(e);
    }

    // FILTRO STATUS
    const handleStatus = (e) => {
        setStatusValue(e);
    }

    // FILTRO EMPREENDIMENTO
    const handleEmpreendimento = (e) => {
        setEmpreendimentoValue(e);
    }

    // FILTRO SISTEMA
    const handleSistema = (e) => {
        setSistemaValue(e);
    }

    // FILTRO CHECKLIST
    const handleChecklist = (e) => {
        setCheckValue(e);
    }

    // REPONDIDO POR
    const handleRespondidoPor = (e) => {
        setRespondidoValue(e);
    }

    //FILTRO LOJA
    const handleLoja = (e) => {
        setLojaValue(e);
    }
    // FILTRO DE DATA (INÍCIO)
    const handleSetDataInicio = (e) => {
        setDataInicio(e);
        handleSetFilter(true);
    }

    // FILTRO DE DATA (FIM)
    const handleSetDataFim = (e) => {
        setDataFim(e);
        handleSetFilter(true);
    }

    //FILTRO FINALIZAÇÃO DATA INÍCIO
    const handleSetFinalDataInicio = (e) => {
        setFinalDataInicio(e);
        handleSetFilter(true);
    }

    //FILTRO FINALIZAÇÃO DATA FIM
    const handleSetFinalDataFim = (e) => {
        setFinalDataFim(e);
        handleSetFilter(true);
    }

    // FILTRO AVALIAÇÃO
    const handleSetAvaliacao = (e) => {
        setAvaliacao(e);
        handleSetFilter(true);
    }

    const handleSetDepartamento = (e) => {
        setFilterDepartamento(e);
        handleSetFilter(e);
    }

    // FILTRA CATEGORIA
    const handleFilterCategory = (e) => {
        setFilterCategory(e);
    }

    // FILTRA SUBCATEGORIA
    const handleFilterSubcategory = (e) => {
        setFilterSubCategory(e);
    }

    //FILTRA PERGUNTAS / RESPOSTA
    const handlePerguntasResposta = (setState) => (e) => {
        setState(e)
    }

    //FILTRA PERGUNTAS / RESPOSTA
    const handleObservacao = (setState) => (e) => {
        setState(e.target.value)
    }

    // ABRE MODAL
    function handleShowRespostas(relatorio_id, checklist_id, checklist_status, resposta_status, loja_id, checklist_nome) {
        setShowModalRespostas(true);
        setRelatorioId(relatorio_id);
        setChecklistId(checklist_id);
        setChecklistNome(checklist_nome);
        setLojaId(loja_id);
        setChecklistStatus(checklist_status);
        setRespostaStatus(resposta_status);
    }

    // FECHA MODAL CADASTRO
    const handleCloseModalCadastro = (e) => {
        setShowModalCadastro(e);
        setReload(true);
        setTimeout(() => {
            setReload(false);
        }, 500);
    }

    // FECHA MODAL RESPOSTAS
    const handleCloseModalRespostas = (e) => {
        setShowModalRespostas(e);
    }

    // ABRE PLANO DE AÇÃO
    function handelSetPlano(titulo, loja, usuario) {
        setTituloPlano(titulo);
        setLojaPlano(loja);
        setUsuarioPlano(usuario);
        setPlano(true);
    }

    const options_resposta = [
        { value: 1, label: "Aprovado" },
        { value: 2, label: "Reprovado" },
        { value: 3, label: "Não se aplica" },
    ]

    //MONTAR THEAD
    const thead = [
        { enabled: true, label: "Status", id: "status", name: "status", items: optionsStatus, onChange: handleStatus, filtered: statusValue, export: true },
        { enabled: window.rs_id_grupo ? true : false, label: "Empreendimento", id: "empreendimento", name: "empreendimento", api: window.host + '/api/sql.php?do=select&component=grupo_empreendimento&token=' + window.token, onChange: handleEmpreendimento, export: true },
        { enabled: true, label: "Loja", id: "loja", name: "loja", api: window.host + '/api/sql.php?do=select&component=loja&np=true&filial=true&token=' + window.token, onChange: handleLoja, export: true },
        { enabled: true, label: "Sistema", id: "sistema", name: "sistema", api: window.host + '/systems/checklist/api/lista.php?do=sistema&token=' + window.token, onChange: handleSistema, export: true },
        { enabled: true, label: "Checklist", id: "checklist", name: "checklist", api: window.host + '/systems/checklist/api/lista.php?do=checklist&token=' + window.token, onChange: handleChecklist, export: true },

        //    { AQUI COMEÇA UM ARRAY DE PERGUNTAS }
        {
            enabled: hide ? false : true, label: "Pergunta 1", id: "pergunta1", name: "pergunta1",
            api: { url: window.host+"/systems/checklist/api/lista.php?do=perguntas_lista&posicao=1&filtro_checklist=" + checkValue + "&token=" + window.token }, reload: checkValue, limit: 20, onChange: handlePerguntasResposta(setPergunta1), export: true
        },
        { enabled: hide ? false : true, label: "Resposta 1", id: "resposta1", name: "resposta1", items: options_resposta, onChange: handlePerguntasResposta(setResposta1), export: true },
        { enabled: hide ? false : true, label: "Observação 1", id: "observacao1", name: "observacao1", onChange: handleObservacao(setObservacao1), export: true },

        {
            enabled: hide ? false : true, label: "Pergunta 2", id: "pergunta2", name: "pergunta2",
            api: { url: window.host+"/systems/checklist/api/lista.php?do=perguntas_lista&posicao=2&filtro_checklist=" + checkValue + "&token=" + window.token }, reload: checkValue, limit: 20, onChange: handlePerguntasResposta(setPergunta2), export: true
        },
        { enabled: hide ? false : true, label: "Resposta 2", id: "resposta2", name: "resposta2", items: options_resposta, onChange: handlePerguntasResposta(setResposta2), export: true },
        { enabled: hide ? false : true, label: "Observação 2", id: "observacao2", name: "observacao2", onChange: handleObservacao(setObservacao2), export: true },

        {
            enabled: hide ? false : true, label: "Pergunta 3", id: "pergunta3", name: "pergunta3",
            api: { url: window.host+"/systems/checklist/api/lista.php?do=perguntas_lista&posicao=3&filtro_checklist=" + checkValue + "&token=" + window.token }, reload: checkValue, limit: 20, onChange: handlePerguntasResposta(setPergunta3), export: true
        },
        { enabled: hide ? false : true, label: "Resposta 3", id: "resposta3", name: "resposta3", items: options_resposta, onChange: handlePerguntasResposta(setResposta3), export: true },
        { enabled: hide ? false : true, label: "Observação 3", id: "observacao3", name: "observacao3", onChange: handleObservacao(setObservacao3), export: true },

        {
            enabled: hide ? false : true, label: "Pergunta 4", id: "pergunta4", name: "pergunta4",
            api: { url: window.host+"/systems/checklist/api/lista.php?do=perguntas_lista&posicao=4&filtro_checklist=" + checkValue + "&token=" + window.token }, reload: checkValue, limit: 20, onChange: handlePerguntasResposta(setPergunta4), export: true
        },
        { enabled: hide ? false : true, label: "Resposta 4", id: "resposta4", name: "resposta4", items: options_resposta, onChange: handlePerguntasResposta(setResposta4), export: true },
        { enabled: hide ? false : true, label: "Observação 4", id: "observacao4", name: "observacao4", onChange: handleObservacao(setObservacao4), export: true },


        {
            enabled: hide ? false : true, label: "Pergunta 5", id: "pergunta5", name: "pergunta5",
            api: { url: window.host+"/systems/checklist/api/lista.php?do=perguntas_lista&posicao=5&filtro_checklist=" + checkValue + "&token=" + window.token }, reload: checkValue, limit: 20, onChange: handlePerguntasResposta(setPergunta5), export: true
        },
        { enabled: hide ? false : true, label: "Resposta 5", id: "resposta5", name: "resposta5", items: options_resposta, onChange: handlePerguntasResposta(setResposta5), export: true },
        { enabled: hide ? false : true, label: "Observação 5", id: "observacao5", name: "observacao5", onChange: handleObservacao(setObservacao5), export: true },


        {
            enabled: hide ? false : true, label: "Pergunta 6", id: "pergunta6", name: "pergunta6",
            api: { url: window.host+"/systems/checklist/api/lista.php?do=perguntas_lista&posicao=6&filtro_checklist=" + checkValue + "&token=" + window.token }, reload: checkValue, limit: 20, onChange: handlePerguntasResposta(setPergunta6), export: true
        },
        { enabled: hide ? false : true, label: "Resposta 6", id: "resposta6", name: "resposta6", items: options_resposta, onChange: handlePerguntasResposta(setResposta6), export: true },
        { enabled: hide ? false : true, label: "Observação 6", id: "observacao6", name: "observacao6", onChange: handleObservacao(setObservacao6), export: true },


        {
            enabled: hide ? false : true, label: "Pergunta 7", id: "pergunta7", name: "pergunta7",
            api: { url: window.host+"/systems/checklist/api/lista.php?do=perguntas_lista&posicao=7&filtro_checklist=" + checkValue + "&token=" + window.token }, reload: checkValue, limit: 20, onChange: handlePerguntasResposta(setPergunta7), export: true
        },
        { enabled: hide ? false : true, label: "Resposta 7", id: "resposta7", name: "resposta7", items: options_resposta, onChange: handlePerguntasResposta(setResposta7), export: true },
        { enabled: hide ? false : true, label: "Observação 7", id: "observacao7", name: "observacao7", onChange: handleObservacao(setObservacao7), export: true },


        {
            enabled: hide ? false : true, label: "Pergunta 8", id: "pergunta8", name: "pergunta8",
            api: { url: window.host+"/systems/checklist/api/lista.php?do=perguntas_lista&posicao=8&filtro_checklist=" + checkValue + "&token=" + window.token }, reload: checkValue, limit: 20, onChange: handlePerguntasResposta(setPergunta8), export: true
        },
        { enabled: hide ? false : true, label: "Resposta 8", id: "resposta8", name: "resposta8", items: options_resposta, onChange: handlePerguntasResposta(setResposta8), export: true },
        { enabled: hide ? false : true, label: "Observação 8", id: "observacao8", name: "observacao8", onChange: handleObservacao(setObservacao8), export: true },


        {
            enabled: hide ? false : true, label: "Pergunta 9", id: "pergunta9", name: "pergunta9",
            api: { url: window.host+"/systems/checklist/api/lista.php?do=perguntas_lista&posicao=9&filtro_checklist=" + checkValue + "&token=" + window.token }, reload: checkValue, limit: 20, onChange: handlePerguntasResposta(setPergunta9), export: true
        },
        { enabled: hide ? false : true, label: "Resposta 9", id: "resposta9", name: "resposta9", items: options_resposta, onChange: handlePerguntasResposta(setResposta9), export: true },
        { enabled: hide ? false : true, label: "Observação 9", id: "observacao9", name: "observacao9", onChange: handleObservacao(setObservacao9), export: true },


        {
            enabled: hide ? false : true, label: "Pergunta 10", id: "pergunta10", name: "pergunta10",
            api: { url: window.host+"/systems/checklist/api/lista.php?do=perguntas_lista&posicao=10&filtro_checklist=" + checkValue + "&token=" + window.token }, reload: checkValue, limit: 20, onChange: handlePerguntasResposta(setPergunta10), export: true
        },
        { enabled: hide ? false : true, label: "Resposta 10", id: "resposta10", name: "resposta10", items: options_resposta, onChange: handlePerguntasResposta(setResposta10), export: true },
        { enabled: hide ? false : true, label: "Observação 10", id: "observacao10", name: "observacao10", onChange: handleObservacao(setObservacao10), export: true },

        //    { AQUI ACABA ARRAY DE PERGUNTAS }

        { enabled: true, label: "Data início", id: "data", name: "data", type: "date", start: { value: dataInicio, onChange: handleSetDataInicio }, end: { value: dataFim, onChange: handleSetDataFim }, export: true },
        { enabled: true, label: "Data finaliz.", id: "data_finalizacao", name: "data_finalizacao", type: "date", start: { value: finalDataInicio, onChange: handleSetFinalDataInicio }, end: { value: finalDataFim, onChange: handleSetFinalDataFim }, export: true },
        { enabled: true, label: "Respondido por", id: "respondido_por", name: "respondido_por", api: window.host + '/api/sql.php?do=select&component=usuario&np=true&ns=false&filial=true&token=' + window.token, onChange: handleRespondidoPor, export: true },
        { enabled: true, label: "Departamento", id: "departamento", name: "departamento", api: window.host + '/api/sql.php?do=select&component=departamento&token=' + window.token, onChange: handleSetDepartamento, export: true },
        { enabled: true, label: "Categoria", id: "categoria", name: "categoria", api: window.host + "/systems/integration-react/api/registry.php?do=get_category&grupo_id=true&empreendimento_id=" + filterEmpreendimento + "&filter_id_module=" + (filterModule == undefined ? '' : filterModule) + "&filter_id_apl_default=0&token=" + window.token, onChange: handleFilterCategory, export: true },
        { enabled: true, label: "Subcategoria", id: "subcategoria", name: "subcategoria", api: window.host + "/systems/integration-react/api/registry.php?do=get_subcategory&grupo_id=true&empreendimento_id=" + filterEmpreendimento + "&filter_id_category" + filterCategory + "&filter_id_apl_default=0&token=" + window.token, onChange: handleFilterSubcategory, export: true },
        { enabled: hide ? false : true, label: "Qtd.", hide: hide, export: true },//export: hide ? false : true },
        { enabled: hide ? false : true, label: "Urgente.", hide: hide, export: true },//export: hide ? false : true },
        { enabled: hide ? false : true, label: "Médio.", hide: hide, export: true },//export: hide ? false : true },
        { enabled: hide ? false : true, label: "Melhoria.", hide: hide, export: true },//export: hide ? false : true },
        { enabled: hide ? false : true, label: "Outro", hide: hide, export: true },//export: hide ? false : true },
        { enabled: true, label: "Pontos", export: true },
        { enabled: window.rs_permission_apl > 2 ? true : false, label: "Ações", export: false }
    ];

    // TITLES EXPORT
    let thead_export = {};
    thead.map((item, i) => {
        if (item?.export !== false) {
            thead_export[item?.name] = item?.label;
        }
    })

    // URL API TABLE
    const url = window.host + "/systems/checklist/api/lista.php?token=" + window.token;

    // PARAMS API TABLE
    const params = {
        do: "get_checklist_personalizado",
        status: statusValue,
        empreendimento: empreendimentoValue,
        sistema: sistemaValue,
        checklist: checkValue,
        data_inicio: (dataInicio ? cd(dataInicio) : ''),
        data_fim: (dataFim ? cd(dataFim) : ''),
        df_data_inicio: (finalDataInicio ? cd(finalDataInicio) : ''),
        df_data_fim: (finalDataFim ? cd(finalDataFim) : ''),
        respondido_por: respondidoValue,
        avaliacao: avaliacao,
        page: 0,
        limit: 50,
        reload: true,
        loja: lojaValue,
        filter_id_categoria: filterCategory,
        filter_id_subcategoria: filterSubCategory,
        filter_id_departamento: filterDepartamento,
        mandatory_limit: true,

        pergunta1: pergunta1,
        pergunta2: pergunta2,
        pergunta3: pergunta3,
        pergunta4: pergunta4,
        pergunta5: pergunta5,
        pergunta6: pergunta6,
        pergunta7: pergunta7,
        pergunta8: pergunta8,
        pergunta9: pergunta9,
        pergunta10: pergunta10,

        resposta1: resposta1,
        resposta2: resposta2,
        resposta3: resposta3,
        resposta4: resposta4,
        resposta5: resposta5,
        resposta6: resposta6,
        resposta7: resposta7,
        resposta8: resposta8,
        resposta9: resposta9,
        resposta10: resposta10,

        observacao1: observacao1,
        observacao2: observacao2,
        observacao3: observacao3,
        observacao4: observacao4,
        observacao5: observacao5,
        observacao6: observacao6,
        observacao7: observacao7,
        observacao8: observacao8,
        observacao9: observacao9,
        observacao10: observacao10,
    };

    // BODY DO EXPORTADOR
    const body = {
        titles: thead_export,
        url: url,
        name: 'Checklist',
        filters: params,
        orientation: 'L',
        and: false,
        custom_values: {
            status: {
                1: 'Finalizado',
                2: 'Pendente',
                3: 'Em andamento'
            },
            resposta1: {
                0: 'Não respondido',
                1: 'Aprovado',
                2: 'Reprovado',
                3: 'Não se aplica'
            },
            resposta2: {
                0: 'Não respondido',
                1: 'Aprovado',
                2: 'Reprovado',
                3: 'Não se aplica'
            },
            resposta3: {
                0: 'Não respondido',
                1: 'Aprovado',
                2: 'Reprovado',
                3: 'Não se aplica'
            }
        }
        // header: "Bearer" + window.token
    }

    // DEFINE VARIÁVEIS DOS FILTROS E ÍCONES DA NAVBAR
    useEffect(() => {
        if (icons) {
            icons(
                <>
                    <Icon type="expandir" expanded={!hide} onClick={() => { setHide(!hide) }} />
                    
                    <Icon type="excel" api={{ body: body }} />

                    <Icon
                        type="new"
                        onClick={() => { setShowModalCadastro(true) }}
                    />
                </>
            )
        }

        if (filters) {
            filters(<></>);
        }
    }, [hide, filterCategory, filterSubCategory, check, checkValue]);


    // useEffect(() => {

    //     const dadosDaApi = check;
    //     const qtdPerguntas = dadosDaApi.map(({ qtd_perguntas }) => qtd_perguntas) || 0;

    //     const perguntas = {};
    //     const respostas = {};
    //     const observacoes = {};

    //     // Iterar sobre cada objeto no array
    //     dadosDaApi.forEach((obj, index) => {
    //         // Criar dinamicamente as chaves com base na quantidade de perguntas
    //         for (let i = 1; i <= qtdPerguntas[index]; i++) {
    //             perguntas[`pergunta${i}_${index + 1}`] = obj[`pergunta${i}`];
    //             respostas[`resposta${i}_${index + 1}`] = obj[`resposta${i}`];
    //             observacoes[`observacao${i}_${index + 1}`] = obj[`observacao${i}`];
    //         }
    //     });

    //     // Atualizar o estado com os novos dados filtrados
    //     setDados({ perguntas, respostas, observacoes });
    // }, [check]);

    // console.log(dados)

    return (
        <Container>
            {/* MODAL DE CADASTRO */}
            <CadastroChecklist
                show={showModalCadastro}
                onHide={handleCloseModalCadastro}
            />

            {/* MODAL DE RESPOSTAS */}
            <Respostas
                show={showModalRespostas}
                onHide={handleCloseModalRespostas}
                relatorio_id={relatorioId}
                checklist_id={checklistId}
                checklist_nome={checklistNome}
                loja_id={lojaId}
                checklist_status={checklistStatus}
                resposta_status={respostaStatus}
            />

            <Row>
                <Col lg={12}>
                    <Table
                        id="checklist"
                        api={url}
                        params={params}
                        onLoad={handleSetItems}
                        reload={true}
                        thead={thead}
                    >
                        <Tbody>
                            {(check.length > 0 ?
                                check.map((checklist, i) => {
                                    var status;
                                    var color;
                                    var background;

                                    if (checklist.status === 1) {
                                        status = "Finalizado";
                                        color = "text-success"
                                        background = 'primary-dark';
                                    } else if (checklist.status === 2) {
                                        status = "Pendente";
                                        color = "text-warning"
                                        background = 'warning';
                                    } else if (checklist.status === 3) {
                                        status = "Em Andamento";
                                        color = "text-danger";
                                        background = 'danger';
                                    }

                                    var total = Number(checklist.aprovado) + Number(checklist.reprovado) + Number(checklist.nao_aplica);
                                    var aprovado = Math.round((100 * Number(checklist.aprovado)) / total);
                                    var reprovado = Math.round((100 * Number(checklist.reprovado)) / total);
                                    var nao_aplica = Math.round((100 * Number(checklist.nao_aplica)) / total);
                                    if (isNaN(aprovado)) aprovado = 0;
                                    if (isNaN(reprovado)) reprovado = 0;
                                    if (isNaN(nao_aplica)) nao_aplica = 0;

                                    var resposta_1_aux;
                                    if (checklist.resposta1 === 0) {
                                        resposta_1_aux = "Não respondido";
                                    } else if (checklist.resposta1 === 1) {
                                        resposta_1_aux = "Aprovado";
                                    } else if (checklist.resposta1 === 2) {
                                        resposta_1_aux = "Reprovado";
                                    } else if (checklist.resposta1 === 3) {
                                        resposta_1_aux = "Não se aplica";
                                    } else {
                                        resposta_1_aux = '';
                                    }

                                    var resposta_2_aux;
                                    if (checklist.resposta2 === 0) {
                                        resposta_2_aux = "Não respondido";
                                    } else if (checklist.resposta2 === 1) {
                                        resposta_2_aux = "Aprovado";
                                    } else if (checklist.resposta2 === 2) {
                                        resposta_2_aux = "Reprovado";
                                    } else if (checklist.resposta2 === 3) {
                                        resposta_1_aux = "Não se aplica";
                                    } else {
                                        resposta_2_aux = '';
                                    }

                                    var resposta_3_aux;
                                    if (checklist.resposta3 === 0) {
                                        resposta_3_aux = "Não respondido";
                                    } else if (checklist.resposta3 === 1) {
                                        resposta_3_aux = "Aprovado";
                                    } else if (checklist.resposta3 === 2) {
                                        resposta_3_aux = "Reprovado";
                                    } else if (checklist.resposta3 === 3) {
                                        resposta_1_aux = "Não se aplica";
                                    } else {
                                        resposta_3_aux = '';
                                    }



                                    return (
                                        <Tr
                                            key={`${checklist.checklists_relatorio_id} - ${i} `}
                                        >
                                            <Td
                                                className={color}
                                                cursor="pointer"
                                                boxed={{
                                                    background: background
                                                }}
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {status}
                                            </Td>

                                            {(window.rs_id_grupo ?
                                                <Td
                                                    cursor="pointer"
                                                    onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                >
                                                    {checklist.empreendimento}
                                                </Td>
                                                : '')}

                                            <Td
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {checklist.loja}
                                            </Td>

                                            <Td
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {checklist.sistema}
                                            </Td>

                                            <Td
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {checklist.checklist}
                                            </Td>



                                            {/* aqui vai um array de respostas */}

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.pergunta1}
                                            >
                                                {checklist.pergunta1}

                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {
                                                    checklist.resposta1 === 1 ?
                                                        <Icon type="check" title="Aprovada" className="text-success" />
                                                        :
                                                        checklist.resposta1 === 2 ?
                                                            <Icon type="reprovar2" title="Reprovado" className="text-danger" />
                                                            :
                                                            checklist.resposta1 === 3 ?
                                                                <Icon type="exclamation" title="Não se apica" className="text-danger" />
                                                                :
                                                                checklist.resposta1 === 0 ?
                                                                    <></>
                                                                    : <></>
                                                }
                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.observacao1}
                                            >
                                                {checklist.observacao1}
                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.pergunta2}
                                            >
                                                {checklist.pergunta2}

                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {
                                                    checklist.resposta2 === 1 ?
                                                        <Icon type="check" title="Aprovada" className="text-success" />
                                                        :
                                                        checklist.resposta2 === 2 ?
                                                            <Icon type="reprovar2" title="Reprovado" className="text-danger" />
                                                            :
                                                            checklist.resposta2 === 3 ?
                                                                <Icon type="exclamation" title="Não se apica" className="text-danger" />
                                                                :
                                                                checklist.resposta2 === 0 ?
                                                                    <></>
                                                                    : <></>
                                                }
                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.observacao2}
                                            >
                                                {checklist.observacao2}
                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.pergunta3}
                                            >
                                                {checklist.pergunta3}

                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {
                                                    checklist.resposta3 === 1 ?
                                                        <Icon type="check" title="Aprovada" className="text-success" />
                                                        :
                                                        checklist.resposta3 === 2 ?
                                                            <Icon type="reprovar2" title="Reprovado" className="text-danger" />
                                                            :
                                                            checklist.resposta3 === 3 ?
                                                                <Icon type="exclamation" title="Não se apica" className="text-danger" />
                                                                :
                                                                checklist.resposta3 === 0 ?
                                                                    <></>
                                                                    : <></>
                                                }
                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.observacao3}
                                            >
                                                {checklist.observacao3}
                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.pergunta4}
                                            >
                                                {checklist.pergunta4}

                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {
                                                    checklist.resposta4 === 1 ?
                                                        <Icon type="check" title="Aprovada" className="text-success" />
                                                        :
                                                        checklist.resposta4 === 2 ?
                                                            <Icon type="reprovar2" title="Reprovado" className="text-danger" />
                                                            :
                                                            checklist.resposta4 === 3 ?
                                                                <Icon type="exclamation" title="Não se apica" className="text-danger" />
                                                                :
                                                                checklist.resposta4 === 0 ?
                                                                    <></>
                                                                    : <></>
                                                }
                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.observacao4}
                                            >
                                                {checklist.observacao4}
                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.pergunta5}
                                            >
                                                {checklist.pergunta5}

                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {
                                                    checklist.resposta5 === 1 ?
                                                        <Icon type="check" title="Aprovada" className="text-success" />
                                                        :
                                                        checklist.resposta5 === 2 ?
                                                            <Icon type="reprovar2" title="Reprovado" className="text-danger" />
                                                            :
                                                            checklist.resposta5 === 3 ?
                                                                <Icon type="exclamation" title="Não se apica" className="text-danger" />
                                                                :
                                                                checklist.resposta5 === 0 ?
                                                                    <></>
                                                                    : <></>
                                                }
                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.observacao5}
                                            >
                                                {checklist.observacao5}
                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.pergunta6}
                                            >
                                                {checklist.pergunta6}

                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {
                                                    checklist.resposta6 === 1 ?
                                                        <Icon type="check" title="Aprovada" className="text-success" />
                                                        :
                                                        checklist.resposta6 === 2 ?
                                                            <Icon type="reprovar2" title="Reprovado" className="text-danger" />
                                                            :
                                                            checklist.resposta6 === 3 ?
                                                                <Icon type="exclamation" title="Não se apica" className="text-danger" />
                                                                :
                                                                checklist.resposta6 === 0 ?
                                                                    <></>
                                                                    : <></>
                                                }
                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.observacao6}
                                            >
                                                {checklist.observacao6}
                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.pergunta7}
                                            >
                                                {checklist.pergunta7}

                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {
                                                    checklist.resposta7 === 1 ?
                                                        <Icon type="check" title="Aprovada" className="text-success" />
                                                        :
                                                        checklist.resposta7 === 2 ?
                                                            <Icon type="reprovar2" title="Reprovado" className="text-danger" />
                                                            :
                                                            checklist.resposta7 === 3 ?
                                                                <Icon type="exclamation" title="Não se apica" className="text-danger" />
                                                                :
                                                                checklist.resposta7 === 0 ?
                                                                    <></>
                                                                    : <></>
                                                }
                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.observacao7}
                                            >
                                                {checklist.observacao7}
                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.pergunta8}
                                            >
                                                {checklist.pergunta8}

                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {
                                                    checklist.resposta8 === 1 ?
                                                        <Icon type="check" title="Aprovada" className="text-success" />
                                                        :
                                                        checklist.resposta8 === 2 ?
                                                            <Icon type="reprovar2" title="Reprovado" className="text-danger" />
                                                            :
                                                            checklist.resposta8 === 3 ?
                                                                <Icon type="exclamation" title="Não se apica" className="text-danger" />
                                                                :
                                                                checklist.resposta8 === 0 ?
                                                                    <></>
                                                                    : <></>
                                                }
                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.observacao8}
                                            >
                                                {checklist.observacao8}
                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.pergunta9}
                                            >
                                                {checklist.pergunta9}

                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {
                                                    checklist.resposta9 === 1 ?
                                                        <Icon type="check" title="Aprovada" className="text-success" />
                                                        :
                                                        checklist.resposta9 === 2 ?
                                                            <Icon type="reprovar2" title="Reprovado" className="text-danger" />
                                                            :
                                                            checklist.resposta9 === 3 ?
                                                                <Icon type="exclamation" title="Não se apica" className="text-danger" />
                                                                :
                                                                checklist.resposta9 === 0 ?
                                                                    <></>
                                                                    : <></>
                                                }
                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.observacao9}
                                            >
                                                {checklist.observacao9}
                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.pergunta10}
                                            >
                                                {checklist.pergunta10}

                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {
                                                    checklist.resposta10 === 1 ?
                                                        <Icon type="check" title="Aprovada" className="text-success" />
                                                        :
                                                        checklist.resposta10 === 2 ?
                                                            <Icon type="reprovar2" title="Reprovado" className="text-danger" />
                                                            :
                                                            checklist.resposta10 === 3 ?
                                                                <Icon type="exclamation" title="Não se apica" className="text-danger" />
                                                                :
                                                                checklist.resposta10 === 0 ?
                                                                    <></>
                                                                    : <></>
                                                }
                                            </Td>

                                            <Td
                                                hide={hide}
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                                title={checklist.observacao10}
                                            >
                                                {checklist.observacao10}
                                            </Td>



                                            {/*  */}
                                            <Td
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {!checklist.data ? '' : cdh(checklist.data)}
                                            </Td>

                                            <Td
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {!checklist.data_finalizacao ? '' : cdh(checklist.data_finalizacao)}
                                            </Td>

                                            <Td
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {checklist.respondido_por}
                                            </Td>

                                            <Td
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {checklist.departamento}
                                            </Td>

                                            <Td
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {checklist.categoria}
                                            </Td>

                                            <Td
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {checklist.subcategoria}
                                            </Td>

                                            <Td
                                                hide={hide}
                                                align="center"
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {(checklist.quantidade ? checklist.quantidade : '-')}
                                            </Td>

                                            <Td
                                                hide={hide}
                                                align="center"
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {checklist.classificacao_urgente}
                                            </Td>

                                            <Td
                                                hide={hide}
                                                align="center"
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {checklist.classificacao_medio}
                                            </Td>

                                            <Td
                                                hide={hide}
                                                align="center"
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {checklist.classificacao_melhoria}
                                            </Td>

                                            <Td
                                                hide={hide}
                                                align="center"
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {checklist.classificacao_outro}
                                            </Td>

                                            <Td
                                                align="center"
                                                cursor="pointer"
                                                onClick={() => handleShowRespostas(checklist.checklists_relatorio_id, checklist.checklist_id, checklist.status, '', checklist.loja_id, checklist.checklist)}
                                            >
                                                {checklist.pontos}
                                            </Td>

                                            {(window.rs_permission_apl > 2 ?
                                                <Td align="center">
                                                    <Icon
                                                        type="user-check"
                                                        animated
                                                        title={(checklist.reprovado > 0 ? 'Criar plano de ação' : 'Plano de ação disponível apenas para checklists com ao menos 1 resposta negativa')}
                                                        disabled={(checklist.reprovado > 0 ? false : true)}
                                                        onClick={() => handelSetPlano(checklist.checklist, checklist.loja_id)}
                                                    />
                                                </Td>
                                                : '')}
                                        </Tr>
                                    )
                                })
                                :
                                <></>
                            )}
                        </Tbody>
                    </Table>
                </Col>
            </Row>

            {/* MODAL PLANO DE AÇÃO (COMPONENTE DO JOBS) */}
            {(plano ?
                <Editar
                    modalTitle={'Plano de Ação'}
                    icon={false}
                    show={plano}
                    plano={true}
                    onClose={(e) => setTimeout(() => { setPlano(false) }, 500)}
                    frequency={143}
                    dateStart={new Date()}
                    job={tituloPlano}
                    category={2426}
                    subcategory={4291}
                    description={`Checklist reprovado: ` + tituloPlano + `\nReprovado em: ` + cd(window.currentDate) + ` às ` + window.currentHour + `:` + window.currentMinutes + `\nReprovado por: ` + window.rs_name_usr}
                    id_lja={lojaPlano}
                    id_usr={usuarioPlano}
                />
                : '')}
        </Container>
    );
}